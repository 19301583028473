import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { history } from "store";

import { Button, GlobalErrorMessage } from "shared/components";
import DocumentsList from "./DocumentsList";
import EditDocumentAdding from "./EditDocumentAdding";
import { DocumentFolder, enum_id_docs, } from "shared/enums/DocumentsTypes";
import { WrapMainForm } from "Funnel/Components/WrapPage";
import "./documents.scss";
import { useParams } from "react-router-dom";

const BeneficiariesDocs = ({ match }) => {
  const { providerSlug, action } = useParams();
  const isUpdate = action === "update";
  const { docs, moreUploadNeeded } = useSelector(
    (store) => store.dashboard.documents.beneficiaries
  );
  const beneficiaries = useSelector(
    (store) => store.dashboard.benificiaries.list
  );

  const [edited_id, setEditedId] = useState(null);
  const [notCompleted, setNotCompleted] = useState(false);

  useEffect(() => setNotCompleted(false), [moreUploadNeeded]);

  const handleValidate = async () => {
    if (!moreUploadNeeded) {
      if (isUpdate) {
        history.goBack();
      } else {
        if (providerSlug) {
          history.push("/settings-confirm");
        } else {
          history.push("/accounts");
        }
      }
    } else {
      setNotCompleted(true);
    }
  };

  const zoneTitle = (person) => {
    const civilityStr = person.civility === "mr" ? "M" : "Mme";
    return `Pièce d’identité ${civilityStr}. ${person.last_name}`;
  };

  return (
    <div className="funnelPgW">
      <div className={`funnelPgContent ${edited_id ? "rightFormEdited" : ""}`}>
        <div className="funnelPgL">
          <WrapMainForm>
            {!isUpdate && !providerSlug && <div className="sectionName">4/4 — Documents</div>}
            <div className="formW">
              <div className="formH">
                <div className="goBack" onClick={() => history.goBack()}>
                  <i className="icon-arrow-back" />
                </div>
                <div className="formTitle">
                  {DocumentFolder.beneficiaries.label}
                </div>
              </div>
              <div>
                {beneficiaries.map((x, i) => (
                  <div key={i} className="docZone">
                    <div className="docTitle">{zoneTitle(x)}</div>
                    <DocumentsList
                      folder={DocumentFolder.beneficiaries.key}
                      owner_id={x.id}
                      list={docs[x.id] ? docs[x.id] : []}
                      onAddingClick={() => {
                        setEditedId(x.id);
                      }}
                      active={edited_id === x.id}
                      errorClass={notCompleted && docs[x.id].length === 0}
                    />
                  </div>
                ))}

                {notCompleted && (
                  <div className="docZone">
                    <GlobalErrorMessage>
                      Vous devez ajouter au moins un fichier par bénéficiaire
                      effectif.
                    </GlobalErrorMessage>
                  </div>
                )}

                <Button onClick={handleValidate} variant="contained" primary>
                  {isUpdate ? "Enregistrer" : "Suivant"}
                </Button>
              </div>
            </div>
          </WrapMainForm>
        </div>
        <div className="funnelPgR">
          {edited_id !== null && (
            <EditDocumentAdding
              title={zoneTitle(beneficiaries.find((x) => x.id === edited_id))}
              folder={DocumentFolder.beneficiaries.key}
              owner_id={edited_id}
              selectEnum={enum_id_docs}
              close={() => setEditedId(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BeneficiariesDocs;
