import React from "react";
import { InterlocutorPositions, RepresentativePositions } from "shared/services/enums";
import PersonCivilitySelect from "shared/components/PersonCivilityInput";
import { errorMessage } from "shared/utilities";
//
import {
  BirthdayInput,
  CitizenshipInput,
  CountryInput,
  DepartmentInput,
  GooglePlacesAutocomplete,
  HalfLineField,
  PhoneNumber,
  SelectInput,
  TextInput,
} from "shared/components";
import { useSelector } from "react-redux";

function InterlocutorBody({
  badField,
  interlocutorType,
  error422,
  body,
  onBodyChange,
}) {
  const [state, setState] = React.useState(body);
  const dashboard = useSelector((store) => store.dashboard);

  const isIndividualCompany =
    dashboard.company.identification.val.legal_form === "individual_enterprise";

  return (
    <React.Fragment>
      <PersonCivilitySelect
        civility={state.civility}
        name="civility"
        onChecked={(v) => {
          const newSt = { ...state, civility: v };
          setState(newSt);
          onBodyChange(newSt);
        }}
        errorMessage={errorMessage("civility", badField, error422)}
      />

      <TextInput
        label="Nom"
        name="last_name"
        type="text"
        value={state.last_name}
        onChange={(e) => {
          const newSt = { ...state, last_name: e.target.value };
          setState(newSt);
          onBodyChange(newSt);
        }}
        errorMessage={errorMessage("last_name", badField, error422)}
      />
      {state.civility === "ms" && (
        <TextInput
          label="Nom de naissance"
          name="birth_name"
          type="text"
          value={state.birth_name}
          onChange={(e) => {
            const newSt = { ...state, birth_name: e.target.value };
            setState(newSt);
            onBodyChange(newSt);
          }}
        />
      )}

      <TextInput
        label="Prénom"
        name="first_name"
        type="text"
        value={state.first_name}
        onChange={(e) => {
          const newSt = { ...state, first_name: e.target.value };
          setState(newSt);
          onBodyChange(newSt);
        }}
        errorMessage={errorMessage("first_name", badField, error422)}
      />
      <TextInput
        label="Adresse email"
        name="email"
        type="email"
        value={state.email}
        onChange={(e) => {
          const newSt = { ...state, email: e.target.value };
          setState(newSt);
          onBodyChange(newSt);
        }}
        errorMessage={errorMessage(
          "email",
          badField,
          error422,
          state.email && state.email.length
            ? "Cela ne ressemble pas à un email valide" //
            : null
        )}
      />

      {(!isIndividualCompany)  && (
        <GooglePlacesAutocomplete
          label="Adresse de résidence"
          type="json_address"
          name="address"
          value={state.address}
          onChange={(v) => {
            const newSt = { ...state, address: v };
            setState(newSt);
            onBodyChange(newSt);
          }}
          errorMessage={errorMessage("address", badField, error422)}
        />
      )}

      {interlocutorType === "representative" && (
        <SelectInput
        label="Fonction dans l’entreprise"
        name="position"
        value={state.position}
        onChange={(v) => {
          const newSt = { ...state, position: v };
          setState(newSt);
          onBodyChange(newSt);
        }}
        lst={RepresentativePositions}
        errorMessage={errorMessage("position", badField, error422)}
        />
      )}
      {interlocutorType === "interlocutor" && (
        <SelectInput
          label="Fonction dans l’entreprise"
          name="position"
          value={state.position}
          onChange={(v) => {
            const newSt = { ...state, position: v };
            setState(newSt);
            onBodyChange(newSt);
          }}
          lst={InterlocutorPositions}
          errorMessage={errorMessage("position", badField, error422)}
        />
      )}

      <PhoneNumber
        label="Téléphone mobile"
        name="mobile_phone"
        value={state.mobile_phone || ''}
        onChange={(v) => {
          const newSt = { ...state, mobile_phone: v };
          setState(newSt);
          onBodyChange(newSt);
        }}
        isValid={() => {
          //TODO
        }}
        errorMessage={errorMessage(
          "mobile_phone",
          badField,
          error422,
          "Cela ne ressemble pas à un numéro de téléphone mobile valide"
        )}
      />

      <CitizenshipInput
        value={state.citizenship}
        name="citizenship"
        onChange={(v) => {
          const newSt = { ...state, citizenship: v };
          setState(newSt);
          onBodyChange(newSt);
        }}
        errorMessage={errorMessage("citizenship", badField, error422)}
      />

      <HalfLineField label="Né(e) le" leftPercent={25}>
        <BirthdayInput
          //   label="Né(e) le"
          value={state.birth_date}
          name="birth_date"
          onChange={(v) => {
            const newSt = { ...state, birth_date: v };
            setState(newSt);
            onBodyChange(newSt);
          }}
          errorMessage={errorMessage(
            "birth_date",
            badField,
            error422,
            badField && badField.message ? badField.message : null //take the message if given
          )}
        />
      </HalfLineField>

      <div className="birthLocationW">
        <HalfLineField label="Né(e) à" leftPercent={25}>
          <TextInput
            label="Ville"
            name="birth_place"
            type="text"
            value={state.birth_place}
            onChange={(e) => {
              const newSt = { ...state, birth_place: e.target.value };
              setState(newSt);
              onBodyChange(newSt);
            }}
            errorMessage={errorMessage("birth_place", badField, error422)}
          />
        </HalfLineField>

        <HalfLineField label="→ Département" leftPercent={44}>
          <DepartmentInput
            name="birth_department"
            placeholder=" _ "
            noUpLabel
            value={state.birth_department}
            onChange={(v) => {
              const newSt = { ...state, birth_department: v };
              setState(newSt);
              onBodyChange(newSt);
            }}
            errorMessage={errorMessage("birth_department", badField, error422)}
          />
        </HalfLineField>

        {interlocutorType === "representative" && (
          <HalfLineField label="→ Pays" leftPercent={25}>
            <CountryInput
              label="Pays"
              name="birth_country"
              value={state.birth_country}
              onChange={(v) => {
                const newSt = { ...state, birth_country: v };
                setState(newSt);
                onBodyChange(newSt);
              }}
              errorMessage={errorMessage("birth_country", badField, error422)}
            />
          </HalfLineField>
        )}
      </div>
    </React.Fragment>
  );
}

export default InterlocutorBody;
