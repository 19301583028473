import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "procedure",
  initialState: {
    totalFunds: 3600000,
    totalYeld: 3230.19, //rendement
    totalNumber: 6,
    containsEmptyFields:false,
  },
  reducers: {
    ibanUpdated: (state, action) => {
        Object.assign(state, action.payload.state);
    },
    emptyFieldFound: (state, action) => {
        state.containsEmptyFields = true;
    },
  },
  
});

export const { ibanUpdated, emptyFieldFound } = slice.actions;
export default slice.reducer;
