import React from "react";
import { Birthday, Percentage } from "shared/components";
import { Country } from "shared/services/enums";

import "./bShortButton.scss";

const BeneficiaryShortBtn = ({ binificiary, active, onEdit }) => {
  return (
    <div className={`bShortBt ${active ? "active" : ""}`} variant="outlined" onClick={() => onEdit(binificiary)}>
      <div className="bNameW">
        <div className="bName">{`${binificiary.first_name} ${binificiary.last_name}`}</div>

        <i className="editIcon icon-edit"  />
      </div>

      <div className="bDsc">
        <span className="bDate">
          <Birthday value={binificiary.birth_date} />
          {` — ${Country[binificiary.citizenship]}`}
        </span>
      </div>
      <div className="bPercent">
        Détient <Percentage value={binificiary.share_percent} />
      </div>
    </div>
  );
};

export default BeneficiaryShortBtn;
