import React from "react";
import { Country } from "shared/services/enums";
import { SelectInput } from "shared/components";

function CitizenshipInput({ label, value, onChange, noUpLabel, errorMessage }) {
  const _label = label ? label : "Nationalité";
  return (
    <SelectInput
      label={_label}
      value={value}
      onChange={onChange}
      lst={Country}
      noUpLabel={noUpLabel}
      errorMessage={errorMessage}
    />
  );
}

export default CitizenshipInput;
