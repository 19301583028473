import { createSlice } from "@reduxjs/toolkit";
import { userLoggedOut } from "store/reducers/auth";
import { apiCall } from "store/api";

const emptyAdmin = {
  civility: "",
  first_name: "",
  last_name: "",
  birth_name: "",
  birth_date: "",
  birth_place: "",
  citizenship: "FR",
  mobile_phone: "",
  landline_phone: "",

  email: "",
  position: "", //fonction dans l'entreprise
  birth_department: "",
  address: {},
};
const emptyRepresentantLegal = {
  ...emptyAdmin,
  birth_country: "FR",
  tax_residences: [
    {
      country: "FR",
    },
  ],
  us_person: false,
};

const slice = createSlice({
  name: "representants",
  initialState: {
    legal: {
      body: { ...emptyRepresentantLegal },
      neverUploaded: false,
    },
    administrator: {
      body: { ...emptyAdmin },
      neverUploaded: false,
    },
    apiPostErrors: null, //ui
  },
  reducers: {
    deletedAdmin: (state, action) => {
      state.administrator = {
        body: { ...emptyAdmin },
        neverUploaded: true,
      };
    },
    updatedRepresentant: (state, { payload }) => {
      state.legal.body = payload;
      state.legal.neverUploaded = false;
    },
    updatedAdministrator: (state, { payload }) => {
      state.administrator.body = payload;
      state.administrator.neverUploaded = false;
    },

    apiUploadAdministratorFailed: (state, { payload }) => {
      state.administrator.error422 = payload.error422;
      state.administrator.apiPostErrors = payload.message;
    },
    apiUploadRepresentantFailed: (state, { payload }) => {
      state.legal.error422 = payload.error422;
      state.legal.apiPostErrors = payload.message;
    },
    clear: (state, { payload }) => {
      state.legal.error422 = null;
      state.legal.apiPostErrors = null;
    },
  },
  extraReducers: {
    initDashboard: (state, { payload }) => {
      //legal
      state.legal.body = payload.representative ?? {
        ...emptyRepresentantLegal,
      };
      state.legal.neverUploaded = !payload.representative;

      //admin
      state.administrator.body = payload.interlocutor ?? { ...emptyAdmin };
      state.administrator.neverUploaded = !payload.interlocutor;
    },
    [userLoggedOut.type]: (state, { payload }) => {
      state = null;
    },
  },
});
export const apiDeleteAdmin = ({ successLink }) =>
  apiCall({
    url: "/interlocutor",
    method: "delete",
    successLink,
    onSuccess: slice.actions.deletedAdmin.type,
    // onError: slice.actions.apiFailed.type,
  });
export const apiSaveAdmin = ({ data, neverUploaded, successLink }) => {
  return apiCall({
    url: "/interlocutor",
    method: neverUploaded ? "post" : "put",
    data,
    localData: neverUploaded ? null : data,
    successLink,
    onSuccess: slice.actions.updatedAdministrator.type,
    onError: slice.actions.apiUploadAdministratorFailed.type,
  });
};
export const apiSaveRepresentant = ({ data, neverUploaded, successLink }) => {
  return apiCall({
    url: "/representative",
    method: neverUploaded ? "post" : "put",
    data,
    localData: neverUploaded ? null : data,
    successLink,
    onSuccess: slice.actions.updatedRepresentant.type,
    onError: slice.actions.apiUploadRepresentantFailed.type,
  });
};

export const { legalUpdated, updatedAdministrator, clear } = slice.actions;
export default slice.reducer;
