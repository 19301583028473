import React from "react";
import { useSelector } from "react-redux";
import { history } from "store";
import SubPart from "./SubPart";

function EditCompanyRepresentants({ pathName }) {
  const { representants, company } = useSelector((store) => store.dashboard);
  const ui = useSelector((store) => store.ui.company.representants);
  const editable = useSelector((store) => store.dashboard.editable);
  const isIndividualCompany = company.identification.val.legal_form === "individual_enterprise";
  const uboNotNeeded = isIndividualCompany || company.identification.val.active_company

  return (
    <React.Fragment>
      <SubPart
        title="Représentant légal"
        state={representants.legal.body}
        fieldNames={ui.representativeFieldNames}
        uboNotNeeded={uboNotNeeded}
        editable={editable}
        onEdit={() =>
          history.push(`/legal-representative/update?h=${pathName}`)
        }
      />
      {!representants.administrator.neverUploaded && (
        <SubPart
          title="Administrateur du compte"
          state={representants.administrator.body}
          editable={editable}
          fieldNames={ui.interlocutorFieldNames}
          onEdit={() => history.push(`/admin-edit/update?h=${pathName}`)}
        />
      )}
    </React.Fragment>
  );
}

export default EditCompanyRepresentants;
