import { CFCAL_SLUG, MMB_SLUG, WORMSER_SLUG } from "../config.json";

export const computeTotalFunds = (accounts) => {
  const sum = accounts.reduce((a, b) => {
    return a + b.amount * (b.number ? b.number : 1);
  }, 0);
  return sum;
};

export const computeTotalFundsPerProviders = (accounts) => {
  return accounts.reduce((a, b) => {
    return { ...a, [b.provider.slug]: a[b.provider.slug] + b.amount * (b.number ? b.number : 1)};
  },  { [WORMSER_SLUG]: 0, [MMB_SLUG]: 0, [CFCAL_SLUG]: 0 });
};
export const computeTotalRendement = (rateGrids, accounts) => {
  const sum = accounts.reduce(
    (a, b) =>
      a +
      rendementActuarial({
        rateGrids,
        amount: b.amount,
        maturity: b.maturity,
        number: b.number,
      }),
    0
  );
  return sum;
};

// export apiError422(errors, fieldName){
//   return ...
// }
export const interest_rate = (maturity, rateGrids) => {
  const r = rateGrids.find((x) => maturityAsInt(x.nominal_maturity) === maturity);
  return r ? parseFloat(r.interest_rate) : undefined;
};
export const rendementActuarial = ({ rateGrids, amount, maturity, number }) => {
  const m = parseInt(maturity);
  const amount_f = parseFloat(amount);
  const r = interest_rate(m, rateGrids);
  if (!r) return undefined;
  //else,
  const v = (amount_f * (1 + (r / 100)) ** (m / 12)) - amount_f
  return number ? v * number : v;
};

export const maturityAsInt = (m) => {
  const m_str = m.slice(-1) === "M" ? m.substring(0, m.length - 1) : m;
  return parseInt(m_str);
};

export const date_ui2iso = (date) => {
  if (date) {
    let lst = date.split("/");
    lst = lst.reverse();
    const _date = lst.join("-"); //pour test
    return _date;
  }
  return date;
};
export const date_iso2ui = (date) => {
  if (date) {
    let lst = date.split("-");
    lst = lst.reverse();
    const _date = lst.join("/");
    return _date;
  }
  return date;
};
export const datetime2ui = (date) => {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }
  return date;
};

//The state on server of company is one object
//On ui it'is 3 objects
export const getCompanyData = (uiCompany) => {
  // the mail address could be with empty field if not given => api won't accept that
  let _mail_address = uiCompany.addresses.set.mailing;
  const mailAddress = _mail_address.neverUploaded
    ? null
    : _mail_address.address;

  return {
    ...uiCompany.identification.val,
    ...uiCompany.iban.payload,
    head_office_address: uiCompany.addresses.set.siege.address,
    mail_address: mailAddress, //Could be empty
  };
};

/////
const REQUIRED_INFO_ERR = "Ce champ doit être renseigné";
export const errorMessage = (
  fieldName,
  joiBadField,
  error422,
  joiBadFieldMessage //can be undefined
) => {
  if (joiBadField && joiBadField.name === fieldName)
    return joiBadFieldMessage ?? REQUIRED_INFO_ERR;
  if (error422 && error422[fieldName]) {
    return error422[fieldName][0];
  }
  return null;
};
export const subErrorMessage = (
  fieldName,
  index, //index de l'objet dans la liste
  joiSubBadField, //sous la forme {index,name} car appliqué à une liste
  error422,
  joiBadFieldMessage //can be undefined
) => {
  if (
    joiSubBadField &&
    joiSubBadField.name === fieldName &&
    index === joiSubBadField.index
  )
    return joiBadFieldMessage ?? REQUIRED_INFO_ERR;
  if (error422 && error422[fieldName]) return error422[fieldName][0];
  return null;
};
