import { createAction, createSlice } from "@reduxjs/toolkit";
import { apiCall } from "store/api";

const slice = createSlice({
  name: "user",
  initialState: {
    uiLoaded: false,
    jwt: null,
    password: {},
    //  {
    //   token: null,
    //   tt: 0, //time to leave
    //   issued_at: null,
    //   email_verified: false,
    //   partner_slug
    // }
    ui: {
      //registerApiError,
      //loginApiError
      //...
    },
    partner: null,
    informLogout: false, //for the dialog box to inform of logging out in xx Seconds
  },
  reducers: {
    forgotPasswordInit: (state, action) => {
      state.password.reset_sent = true
      state.ui.forgotInitError422 = null
      state.ui.forgotInitApiError = null
    },
    forgotPasswordClear: (state, action) => {
      state.password.reset_sent = false
      state.ui.forgotInitError422 = null
      state.ui.forgotInitApiError = null
    },
    forgotPasswordFailed: (state, { payload }) => {
      state.ui.forgotInitError422 = payload.error422;
      state.ui.forgotInitApiError = payload.message;
    },
    resetPassword: (state, action ) => {
      state.ui.relogin = true
    },
    resetPasswordFailed: (state, { payload }) => {
      state.ui.resetInitError422 = payload.error422;
      state.ui.resetInitApiError = payload.message;
    },
    userRegistred: (state, action) => {
      state.jwt = action.payload;
    },
    userRegisterFailed: (state, { payload }) => {
      state.ui.registerError422 = payload.error422;
      state.ui.registerApiError = payload.message;
    },
    userLoggedIn: (state, { payload }) => {
      state.jwt = payload;
    },
    userLoggedInFailed: (state, { payload }) => {
      state.ui.loginError422 = payload.error422;
      state.ui.loginApiError = payload.message;
    },
    userLoggedOut: (state, action) => {
      state.informLogout = false;
      state.jwt = null;
    },
    //it will supervise the time progress
    localSessionOpened: (state, action) => {
      state.jwt = action.payload.jwt;
    },
    //
    notifiedOfLogout: (state) => {
      state.informLogout = false;
    },
    notifyOfLogout: (state) => {
      state.informLogout = true;
    },
    ///email/verifications/put
    emailValidated: (state, action) => {
      state.jwt = { ...state.jwt, email_verified: true };
    },
    emailValidationFailed: (state, { payload }) => {
      state.ui.apiEmailVerifError422 = payload.error422;
      state.ui.apiEmailVerifError = payload.message;
    },
    ///email/verifications/post
    validationEmailSent: (state, action) => {
      state.ui.emailSent = true;
    },
    validationEmailSentFailed: (state, { payload }) => {
      state.ui.emailSent = false;
      state.ui.emailNotSentReason422 = payload.error422;
      state.ui.emailNotSentReason = payload.message;
    },
    ///
    apiCalled: (state, action) => {
      if (state.jwt) {
        const sTime = Math.floor(action.payload.calledAt / 1000);
        state.jwt.issued_at = sTime;
      }
    },
    setPartener: (state, { payload }) => {
      state.partner = payload.partner;
    },
  },
});

export const apiRegister = ({
                              invitation_token,
                              partner_slug,
                              email,
                              password,
                            }) =>
  apiCall({
    url: "/register",
    method: "post",
    data: { invitation_token, partner_slug, email, password },
    localData: { email },
    onSuccess: slice.actions.userRegistred.type,
    onError: slice.actions.userRegisterFailed,
  });

export const apiForgotPassword = ({
                                    email
                                  }) =>
  apiCall({
    url: "/forgot_password",
    method: "post",
    data: { email },
    localData: { email },
    onSuccess: slice.actions.forgotPasswordInit.type,
    onError: slice.actions.forgotPasswordFailed,
  });

export const apiResetPassword = ({ password, code }) =>
  apiCall({
    url: "/forgot_password",
    method: "put",
    data: { password, verification_code: code },
    localData: { password, code },
    onSuccess: slice.actions.resetPassword.type,
    onError: slice.actions.resetPasswordFailed,
  });


export const apiLogin = (user) =>
  apiCall({
    url: "/sessions",
    method: "post",
    data: user,
    localData: { email: user.email },
    onSuccess: slice.actions.userLoggedIn.type,
    onError: slice.actions.userLoggedInFailed,
  });
export const keepSession = () =>
  apiCall({
    url: "/keepSession",
    method: "post",
    data: {},
    // onSuccess: slice.actions.userLoggedIn.type,
    // onError: slice.actions.userLoggedInFailed,
  });

export const validateEmail = (code) =>
  apiCall({
    url: "/email/verifications",
    method: "put",
    data: { verification_code: code },
    onSuccess: slice.actions.emailValidated.type,
    onError: slice.actions.emailValidationFailed.type,
  });

export const resendValidationEmail = () =>
  apiCall({
    url: "/email/verifications",
    method: "post",
    data: {},
    onSuccess: slice.actions.validationEmailSent.type,
    onError: slice.actions.validationEmailSentFailed.type,
  });

export const logout = createAction("auth/logout");

export const {
  localSessionOpened,
  notifiedOfLogout,
  notifyOfLogout,
  forgotPasswordClear,
  resetPassword,
  //
  userRegistred,
  userLoggedIn,
  userLoggedOut,
  emailValidated,
  apiCalled,
  //
  setPartener,
} = slice.actions;
export default slice.reducer;
