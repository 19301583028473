import Joi from "joi";
import { MIN_BENEFICIARY_PERCENTAGE } from "config";
import TAX_RESIDENCES_VALIDATOR from "Validators/TaxResidences";

export default async function validateForm(obj) {
  const minPercentageValue = MIN_BENEFICIARY_PERCENTAGE ?? 25;

  const schema = Joi.object({
    id: Joi.number().allow("", null),
    civility: Joi.string().valid("ms", "mr"),
    first_name: Joi.string().required(),
    last_name: Joi.string().required(),
    citizenship: Joi.string().required(),

    address: Joi.object({
      street: Joi.string().required(),
      second_line: Joi.string().allow(null, ""),
      postcode: Joi.string().required(),
      city: Joi.string().required(),
      country: Joi.string().required(),
      territory: Joi.string().allow(null, ""),
    }),

    birth_date: Joi.date().required(),
    birth_place: Joi.string().required(),
    birth_country: Joi.string().required(),
    birth_department: Joi.string().required(),
    share_percent: Joi.number().min(minPercentageValue).required(),
    voting_percent: Joi.number().min(minPercentageValue).required(),
    us_person: Joi.boolean().falsy(null),
    tax_residences: TAX_RESIDENCES_VALIDATOR
  });
  const { error } = schema.validate(obj);
  return error
    ? {
      name: error.details[0].path[0],
      sub: {
        //usefull for nif and country
        index: error.details[0].path[1],
        name: error.details[0].path[2],
      },
      type: error.details[0].type,
      message:
        error.details[0].type === "number.min"
          ? `Cette valeur doit dépasser ${minPercentageValue}%.`
          : null,
    }
    : null;
}
