import { createSlice, current } from "@reduxjs/toolkit";
import { ID_DOC_TYPE } from "shared/enums/DocumentsTypes";

const moreUploadNeeded = (docs) => {
  return docs.id_docs.length === 0 || docs.power_copy === null;
};

const slice = createSlice({
  name: "interlocutor",
  initialState: {
    docs: {
      id_docs: [],
      power_copy: null,
    },
    moreUploadNeeded: false,
  },
  reducers: {},
  extraReducers: {
    initDashboard: (state, { payload }) => {
      if (payload.documents) {
        payload.documents.forEach((doc) => {
          if (doc.owner_type.toLowerCase() === "interlocutor") {
            if (ID_DOC_TYPE.includes(doc.document_type)) {
              //TODO: ce n'est pas bon nous allons avoir les id des deux type d'interlocuteurs!
              state.docs.id_docs.push(doc);
            } else if (doc.document_type === "power_copy") {
              state.docs.power_copy = doc;
            }
          }
        });
      }
      state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
    },
    documentUploaded: (state, { payload }) => {
      if (payload.folder === "interlocutor") {
        if (payload.subFolder === "id_docs") {
          state.docs.id_docs.push(payload);
        } else if (payload.document_type === "power_copy") {
          state.docs.power_copy = payload;
        }
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    documentDeleted: (state, { payload }) => {
      if (payload.folder === "interlocutor") {
        if (payload.subFolder === "id_docs") {
          state.docs.id_docs = state.docs.id_docs.filter(
            (x) => x.id !== payload.id
          );
        } else if (
          state.docs.power_copy &&
          state.docs.power_copy.id === payload.id
        ) {
          state.docs.power_copy = null;
        }
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
  },
});

export const { documentUploaded } = slice.actions;
export default slice.reducer;
