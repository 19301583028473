import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "store";
import { correspondanceUpdated } from "store/reducers/dashboard/company/addresses";
import { ConfirmDialog } from "shared/components/Dialog";
import AddressBody from "./AddressBody";
//
import { Button } from "shared/components";
import validateForm from "./validateForm";
import { getCompanyData } from "shared/utilities";
import { apiCompanyPost } from "store/reducers/dashboard/company/iban";
import queryString from "query-string";
import DeleteErrorMessage from "./DeleteErrorMessage";
import WrapPage from "Funnel/Components/WrapPage";

const CorrespondenceAdress = ({ match, location }) => {
  const dispatch = useDispatch();
  const company = useSelector((store) => store.dashboard.company);
  const error422 = company.iban.error422;
  const mailing = company.addresses.set.mailing;
  const init = mailing.address;

  const [state, setState] = useState(init);
  const [badField, setBadField] = useState(null);
  const [showConfirm, setShowConfirm] = useState();

  const isUpdate = match.params.action === "update";
  const { h: gobackPath } = queryString.parse(location.search);

  //REMOVING
  const handleRemove = async (e) => {
    const data = { ...getCompanyData(company), mail_address: null };
    dispatch(
      apiCompanyPost({ data, neverUploaded: false, successLink: gobackPath })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = await validateForm(state);
    setBadField(error);

    if (!error) {
      if (isUpdate) {
        const successLink = gobackPath;
        const data = {
          ...getCompanyData(company),
          mail_address: state,
        };
        dispatch(
          apiCompanyPost({
            data,
            successLink,
            neverUploaded: company.identification.neverUploaded,
          })
        );
      } else {
        dispatch(correspondanceUpdated({ state }));
        history.push("/iban");
      }
    }
  };

  return (
    <WrapPage>
      {!isUpdate && <div className="sectionName">1/4 — Entreprise</div>}
      <div className="formW">
        <div className="formH">
          <div className="goBack" onClick={() => history.goBack()}>
            <i className="icon-arrow-back" />
          </div>
          <div className="formTitle">Adresse de correspondance</div>
        </div>

        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <AddressBody
            badField={badField}
            error422={error422}
            address={state}
            onAddressChange={(address) => setState(address)}
          />

          {!isUpdate && (
            <Button type="submit" variant="contained" primary>
              Suivant
            </Button>
          )}
          {isUpdate && mailing.neverUploaded && (
            <Button type="submit" variant="contained" primary>
              Enregistrer
            </Button>
          )}
          {isUpdate && !mailing.neverUploaded && (
            <div className="buttonsLst">
              <Button variant="outlined" onClick={() => setShowConfirm(true)}>
                Supprimer
              </Button>
              <Button
                type="submit"
                variant="contained"
                primary
                disabled={state === init}
              >
                Enregistrer
              </Button>
            </div>
          )}
        </form>
      </div>

      {/* IF REMOVING CLICK */}
      {showConfirm && (
        <ConfirmDialog
          open={showConfirm}
          title="Attention"
          message={<DeleteErrorMessage />}
          onOk={() => {
            handleRemove();
            setShowConfirm(false);
          }}
          onCancel={() => setShowConfirm(false)}
        ></ConfirmDialog>
      )}
    </WrapPage>
  );
};
export default CorrespondenceAdress;
