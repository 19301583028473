import {
  emailValidated,
  logout,
  resetPassword,
  userLoggedIn,
  userLoggedOut,
  userRegistred,
} from "store/reducers/auth";
import { history } from "store";

export const getLocalStorageJwt = () => {
  ///***********************************
  const clearJwtIfExpired = (localJwt) => {
    const currTime = new Date().getTime();
    const remainingTime =
      localJwt.ttl * 1000 + localJwt.issued_at * 1000 - currTime;
    if (remainingTime < 0) {
      clearLocalStorageJwt();
      return null;
    } else return localJwt;
  };
  ///***********************************

  let localJwt = {
    token: localStorage.getItem("token"),
    ttl: localStorage.getItem("ttl"),
    issued_at: localStorage.getItem("issued_at"),
    email: localStorage.getItem("email"),
    email_verified: localStorage.getItem("email_verified"),
    partner_slug: localStorage.getItem("partner_slug"),
  };

  return localJwt ? clearJwtIfExpired(localJwt) : null; //will return to null if expired
};

const setLocalStorageJwt = (jwt, dispatch) => {
  localStorage.setItem("token", jwt.token);
  localStorage.setItem("ttl", jwt.ttl); //time to leave
  localStorage.setItem("issued_at", jwt.issued_at);
  localStorage.setItem("email", jwt.email);
  localStorage.setItem("email_verified", jwt.email_verified);
  localStorage.setItem("partner_slug", jwt.partner_slug);

  //   const sDeadline = jwt.issued_at + jwt.ttl;
  //   superviseSessionTime(sDeadline, dispatch);
};
const clearLocalStorageJwt = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("ttl"); //time to leave
  localStorage.removeItem("issued_at");
  localStorage.removeItem("email");
  localStorage.removeItem("email_verified");
  localStorage.removeItem("partner_slug");
};

////
const authMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (
      action.type === userRegistred.type ||
      action.type === userLoggedIn.type
    ) {
      setLocalStorageJwt(action.payload, dispatch);
    }
    if (action.type === userLoggedOut.type) {
      clearLocalStorageJwt();
    }
    if (action.type === logout.type) {
      dispatch(userLoggedOut());
      history.push("/login");
    }
    if (action.type === emailValidated.type) {
      localStorage.email_verified = true;
    }
    if (action.type === resetPassword.type) {
      history.replace("/login");
    }
    return next(action);
  };

export default authMiddleware;
