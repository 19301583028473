import React, { useState } from "react";
import { CFCAL_SLUG, WORMSER_SLUG } from "../../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "shared/components";
import { history } from "store";
import { loadProcedure } from "store/reducers/dashboard/procedure";
import StaticLink from "shared/services/StaticLink";
import "../signature.scss";
import { useParams } from "react-router-dom";
import WaitingSignature from "./WaitingSignature";
import Signed from "./Signed";
import Transfers from "./Transfers";

const SignatureInformation = ({ onClose }) => {
  return (
    <div className="editFW signtureInfo">
      <div className="eFH">
        <div className="eFHTitle">Des questions ?</div>
        <div className="eFHCmd" onClick={onClose}>
          <i className="icon-close" />
        </div>
      </div>
      <div className="eFBdy">
        <div>
          <StaticLink to="contactez_nous">
            Contactez-nous
          </StaticLink>{" "}
          ou consultez notre{" "}
          <StaticLink to="centre_aide">
            centre d’aide
          </StaticLink>
          .
        </div>
        <div className="subBody">
          Cashbee est agréé par l’ACPR Banque de France en tant qu’établissement
          de paiement et inscrit à l’ORIAS en tant que courtier et intermédiaire
          en opérations de banque.
        </div>
      </div>
    </div>
  );
};

function ProcedureSignature() {
  const [showInfo, setShowInfo] = useState(true);
  const { providerSlug } = useParams();
  // Does not work during signature
  const proc = useSelector((s) => s.dashboard.providers.find((e) => e.slug === providerSlug).pending_procedure);

  //--- Matching status -------
  const status = proc.status;
  const IS_WAITING_SIGNATURE = ["ready", "pending_signature"].includes(status);
  const IS_SIGNED = ["signed", "submitted", "rejected", "invalid_documents", "ready_to_send"].includes(status);
  const IS_ACCEPTED = "accepted" === status;
  //---------------------------

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (proc && proc.id) {
      dispatch(loadProcedure({ id: proc.id })); //load rate_grids and other needed data
    }
  }, [dispatch, proc]);

  //TODO after api stabilisation
  const email = proc.receiver ? proc.receiver.email : "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    history.replace("/accounts");
  };

  const leftColomnClass = `dshMain minHeight grey signWrap ${
    showInfo ? "rightFormEdited" : ""
  }`;

  return (
    <div className="bigImgWrapper">
      <div className="deskWrapper">
        <div className="pageContent">
          <div className={leftColomnClass}>
            <div className="signHeaderW">
              <div className="signHeader">
                <div
                  className="closeBt"
                  onClick={() => history.replace("/accounts")}
                >
                  <i className="icon-close" />
                </div>
                <div className="formTitle">
                  {IS_WAITING_SIGNATURE && (<>En attente de signature</>)}
                  {IS_SIGNED && (<>Merci</>)}
                  {IS_ACCEPTED && (<> {[WORMSER_SLUG, CFCAL_SLUG].includes(proc.provider.slug)? 'Virement' : 'IBANs'} {proc.provider.long_name}</>)}
                </div>
              </div>
              <div className="getInfo">
                <i
                  className="icon-info-circle"
                  onClick={() => setShowInfo(!showInfo)}
                />
              </div>
            </div>

            <div className="dMBdyW">
              <div className="dMBdy">
                <div className="signSection">
                  {IS_WAITING_SIGNATURE && (
                    <WaitingSignature  email={email} />
                  )}
                  {IS_SIGNED && (
                    <Signed delayInformation={proc.provider.delay_information} />
                  )}
                  {IS_ACCEPTED && (<Transfers procedure={proc} />)}
                </div>
              </div>
              <div className="dMSubmitBt">
                <Button onClick={handleSubmit} primary>
                  Terminer
                </Button>
              </div>
            </div>
          </div>
          <div className="dshSub">
            {showInfo && (
              <SignatureInformation onClose={() => setShowInfo(false)} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcedureSignature;
