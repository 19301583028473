import { createSlice, current } from "@reduxjs/toolkit";
import { userLoggedOut } from "store/reducers/auth";
import { ID_DOC_TYPE } from "shared/enums/DocumentsTypes";

const moreUploadNeeded = (docs) => {
  return docs.id_docs.length === 0;
};

const slice = createSlice({
  name: "representative",
  initialState: {
    docs: {
      id_docs: [],
    },
    moreUploadNeeded: false,
  },
  reducers: {},
  extraReducers: {
    initDashboard: (state, { payload }) => {
      if (payload.documents) {
        payload.documents.forEach((doc) => {
          if (
            doc.owner_type.toLowerCase() === "representative" &&
            ID_DOC_TYPE.includes(doc.document_type)
          ) {
            //TODO: ce n'est pas bon nous allons avoir les id des deux type d'interlocuteurs!
            state.docs.id_docs.push(doc);
          }
        });
      }

      state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
    },
    documentUploaded: (state, { payload }) => {
      if (payload.folder === "representative" && payload.subFolder === "id_docs") {
          state.docs.id_docs.push(payload);
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    documentDeleted: (state, { payload }) => {
      if (payload.folder === "representative") {
        state.docs.id_docs = state.docs.id_docs.filter((x) => x.id !== payload.id);
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    [userLoggedOut.type]: (state, { payload }) => {
      state = null;
    },
  },
});

export const { documentUploaded } = slice.actions;
export default slice.reducer;
