import { combineReducers } from "redux";
import company from "./company";
import benificiaries from "./benificiaries";
import documents from "./documents";
import representants from "./representants";
import procedure from "./procedure";
import accounts from "./accounts";
import receiver from "./receiver";
import editable from "./editable";
import signed_procedures from "./signed_procedures";
import data from "./data";
import providers from "./providers";
import { apiCall } from "store/api";

const reducer = combineReducers({
  company,
  providers,
  benificiaries,
  documents,
  representants,
  editable,
  procedure,
  accounts,
  receiver,
  data,
  signed_procedures,
});
export default reducer;

export const loadDashboard = () =>
  apiCall({
    url: "/dashboard",
    onSuccess: "initDashboard",
  });
