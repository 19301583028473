import Joi from "joi";

const TAX_RESIDENCES_VALIDATOR = Joi.array()
  .min(1)
  .items(
    Joi.object({
      country: Joi.string().required(),
      nif_number: Joi.when(
        'country',
        {
          is: Joi.string().valid('FR'),
          then: Joi.string().allow(null, ""),
          otherwise: Joi.string().required(),
        }
      )
    })
  )


export default TAX_RESIDENCES_VALIDATOR;
