import React from "react";
import { Country } from "shared/services/enums";

export default function AddressString({ value }) {
  return !value || !value.street || !value.city || !value.country ? null : (
    <div>
      <div>
        {`${value.street}`}
        <br />
        {`${value.city}, ${Country[value.country]}`}
      </div>
    </div>
  );
}
