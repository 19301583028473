import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "identification",
  initialState: {
    fieldNames: {
      name: { label: "Dénomination sociale", type: "text" },
      legal_form: {
        label: "Forme juridique",
        type: "legal_form",
        other: "legal_form_other",
      },
      // legal_form_other: { label: "Forme juridique", type: "legal_form" },
      share_capital: { label: "Capital Social", type: "price" },
      category: {
        label: "Catégorie",
        type: "category",
        other: "category_other",
      },
      // category_other: { label: "Catégorie", type: "category" },
      activity_description: { label: "Activité de l’entreprise" },
      ape_code: { label: "Code APE" },
      siren: { label: "SIREN", type: "siren" },
      registered_on: { label: "Date de création", type: "birthday" },
      active_company: {
        label: "Entreprise passive",
        type: "boolean",
      },
      us_company: {
        label:
          "Mon entreprise est américaine au sens de la réglementation FATCA",
        type: "boolean",
      },
    },
  },
  reducers: {},
});

// export const {} = slice.actions;
export default slice.reducer;
