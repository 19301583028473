import React from "react";
//
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import makeStyles from '@mui/styles/makeStyles';
import Select from "@mui/material/Select";

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));
const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

function SelectInput({
  label,
  placeholder,
  value,
  style,
  onChange,
  lst,
  noUpLabel,
  errorMessage,
  disabled,
}) {
  return noUpLabel ? (
    <FormControl
      variant="outlined"
      error={!!errorMessage}
      fullWidth
      disabled={!!disabled}
    >
      <Select
        placeholder={label}
        fullWidth
        style={style}
        displayEmpty
        value={value}
        onChange={(e) => onChange(e.target.value)}
        inputProps={noUpLabel && { "aria-label": "Without label" }}
        renderValue={
          value !== ""
            ? undefined
            : () => <Placeholder>{placeholder ?? label}</Placeholder>
        }
      >
        <MenuItem key={0} value="" disabled>
          {placeholder ?? label}
        </MenuItem>
        {lst &&
          Object.keys(lst).map((k, i) => {
            if (k !== "undefined") {
              return (
                <MenuItem key={i + 1} value={k} disabled={!!disabled}>
                  {lst[k]}
                </MenuItem>
              );
            } else { return null }
          })}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  ) : (
    <FormControl
      variant="outlined"
      error={!!errorMessage}
      fullWidth
      disabled={!!disabled}
    >
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        style={style}
        label={noUpLabel ? null : label}
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {lst &&
          Object.keys(lst).map((k, i) => (
            <MenuItem key={i} value={k}>
              {lst[k]}
            </MenuItem>
          ))}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export default SelectInput;
