import { createSlice } from "@reduxjs/toolkit";
import { procedurePosted } from './procedure'
import { apiCall } from "store/api";

const slice = createSlice({
  name: "providers",
  initialState: [],
  reducers: {
    initProvidersFromServer: (state, { payload }) => {
      state.splice(0, payload.providers.length, ...payload.providers)
    },
  },
  extraReducers: {
    [procedurePosted.type]: (state, { payload }) => {
      state.find((p) => p.slug === payload.provider.slug).pending_procedure = payload;
      // Not perfect it do not display the message it needs to be changed
      state.find((p) => p.slug === payload.provider.slug).offer.blocked = true;
    }
  },
});
export const loadProviders = () =>
  apiCall({
    url: "/providers",
    onSuccess: slice.actions.initProvidersFromServer.type,
  });
export default slice.reducer;
