import { createSlice } from "@reduxjs/toolkit";
import { apiCall } from "store/api";
import { userLoggedOut } from "store/reducers/auth";

const slice = createSlice({
  name: "benificiaries",
  initialState: {
    list: [],
    neverUploaded: false,
  },

  reducers: {
    loadDashboard: (state, { payload }) => {
      state.uploaded = payload.ultimate_beneficiary_owners !== null;
      state.list = payload.ultimate_beneficiary_owners ?? [];
    },
    beneficiaryAdded: (state, { payload }) => {      
      state.list.push(payload); //this is adding not updating
    },
    beneficiaryRemoved: (state, { payload }) => {
      state.list = state.list.filter((x) => payload.id !== x.id);
    },
    beneficiaryUpdated: (state, { payload }) => {
      state.list = state.list.map((x) => (x.id !== payload.id ? x : payload));
    },
    apiCallFailed: (state, { payload }) => {
      //Handled on ui store part
    },
    disableNeverUploadedFlag: (state, action) => {
      state.neverUploaded = false;
    },
  },
  extraReducers: {
    initDashboard: (state, { payload }) => {
      state.list = payload.ultimate_beneficiary_owners;
      state.neverUploaded = !state.list || state.list.length === 0;
    },
    [userLoggedOut.type]: (state, { payload }) => {
      state = null;
    },
  },
});

export const apiSaveNewBeneficiary = ({ data, successLink }) =>
  apiCall({
    url: "/ultimate_beneficiary_owners",
    method: "post",
    data,
    successLink,
    onSuccess: slice.actions.beneficiaryAdded.type,
    onError: slice.actions.apiCallFailed.type,
  });

export const apiUpdateBeneficiary = ({ data, successLink }) =>
  apiCall({
    url: `/ultimate_beneficiary_owners/${data.id}`,
    method: "put",
    data,
    successLink,
    onSuccess: slice.actions.beneficiaryUpdated.type,
    onError: slice.actions.apiCallFailed.type,
  });
export const apiDeleteBeneficiary = ({ id, successLink }) =>
  apiCall({
    url: `/ultimate_beneficiary_owners/${id}`,
    method: "delete",
    data: { id },
    localData: { id },
    successLink,
    onSuccess: slice.actions.beneficiaryRemoved.type,
    onError: slice.actions.apiCallFailed.type,
  });

export const {
  beneficiaryAdded,
  beneficiaryRemoved,
  beneficiaryUpdated,
  disableNeverUploadedFlag,
  apiCallFailed,
} = slice.actions;
export default slice.reducer;
