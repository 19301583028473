import React from "react";

import { ConnectedRouter } from "connected-react-router";
import ConditionalRouter from "./Routers";
import { useDispatch, useSelector } from "react-redux";
import { keepSession, localSessionOpened, notifiedOfLogout, } from "store/reducers/auth";
//
import Dialog, { DialogActions, DialogContent } from "shared/components/Dialog";
import { Button } from "shared/components";
import { getLocalStorageJwt } from "store/middleware/auth";
import "./App.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: '#373843',
      main: '#222226',
      dark: '#373843',
    },
    cashbee: {
      main: '#FED400'
    },
    wormser: {
      contrastText: '#fff',
      light: '#63820D',
      main: '#3C5200',
      dark: '#63820D',
    },
    mmb: {
      contrastText: '#fff',
      light: '#0056D6',
      main: '#003585',
      dark: '#0056D6',
    },
    cfcal: {
      contrastText: '#fff',
      light: '#CD5600',
      main: '#932D00',
      dark: '#CD5600',
    },
  },
});

const LogoutAlertDialog = () => {
  const dispatch = useDispatch();
  const { informLogout } = useSelector((store) => store.auth);
  const handleClose = () => dispatch(notifiedOfLogout());
  const handleKeepSession = () => dispatch(keepSession());

  return (
    <Dialog onClose={handleClose} open={informLogout} closeIcon={true}>
      <DialogContent>
        Fermeture de session dans 30 secondes! Souhaitez-vous garder la section
        ouverte pendant encore 15 minutes?
      </DialogContent>
      <DialogActions>
        <Button fullWidth={false} onClick={handleClose}>
          J'ai compris
        </Button>
        <Button onClick={handleKeepSession}>Garder la session</Button>
      </DialogActions>
    </Dialog>
  );
};

function App({ history }) {
  const dispatch = useDispatch();

  //on refresh check localstorage to update token
  const { jwt, partner } = useSelector((store) => store.auth);
  const currPartner = jwt ? jwt.partner_slug : partner;
  let localJwt = jwt;
  if (!jwt) {
    localJwt = getLocalStorageJwt();
    if (localJwt) {
      dispatch(localSessionOpened({ jwt: localJwt }));
    }
  }
  //IMPORTANT: jwt has async value. But will end up having the same value as localJwt.
  //So we should use here localJwt, unless the routing part won't be good!
  return (
    <ThemeProvider theme={theme}>
      <div
        className={`pgW ${
          currPartner ? `with_partner ${currPartner}_as_partner` : ""
        }`}
      >
        <LogoutAlertDialog />

        <ConnectedRouter history={history}>
          {/* <PublicRouter /> */}
          <ConditionalRouter
            authentified={localJwt}
            emailVerified={localJwt && localJwt.email_verified}
          />
        </ConnectedRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
