import React from "react";
import AccordionCustom from "@mui/material/Accordion";
import AccordionSummaryCustom from "@mui/material/AccordionSummary";
import AccordionDetailsCustom from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./accordion.scss";

export const Accordion = ({ children, expanded, onChange }) => {
  return (
    <AccordionCustom expanded={expanded} onChange={onChange}>
      {children}
    </AccordionCustom>
  );
};

export const AccordionSummary = ({ children }) => {
  return (
    <AccordionSummaryCustom expandIcon={<ExpandMoreIcon />}>
      {children}
    </AccordionSummaryCustom>
  );
};

export const AccordionDetails = ({ children }) => {
  return (
    <AccordionDetailsCustom>
      <div>{children}</div>
    </AccordionDetailsCustom>
  );
};
