import React from "react";
import TextField from "@mui/material/TextField";

const date_ui2iso = (date) => {
  if (date) {
    let lst = date.split("/");
    lst = lst.reverse();
    const _date = lst.join("-"); //pour test
    return _date;
  }
  return date;
};
const date_iso2ui = (date) => {
  if (date) {
    let lst = date.split("-");
    lst = lst.reverse();
    const _date = lst.join("/");
    return _date;
  }
  return date;
};

function DateInput({ label, value, onChange }) {
  return (
    <div className="dateW">
      <div className="dateLable">{label}</div>
      <div className="dateVal">
        <TextField
          // label={label}
          variant="outlined"
          type="date"
          // defaultValue="2017-05-24"
          InputLabelProps={{
            shrink: true,
          }}
          value={date_iso2ui(value)}
          onValueChange={(values) => {
            const { formattedValue } = values;
            onChange(date_ui2iso(formattedValue));
          }}
        />
      </div>
    </div>
  );
}

export default DateInput;
