import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "store";
import { CompanyCategory, CompanyLegalForm } from "shared/services/enums";
import { errorMessage, getCompanyData } from "shared/utilities";

import WrapPage from "Funnel/Components/WrapPage";
import Joi from "joi";
import TextField from '@mui/material/TextField';
import { identificationUpdated } from "store/reducers/dashboard/company/identification";
import { apiCompanyPost } from "store/reducers/dashboard/company/iban";
import { findApeItem } from "shared/services/apeCodes";
import {
  BirthdayInput,
  Button,
  CheckBox,
  GlobalErrorMessage,
  HalfLineField,
  PriceInput,
  SelectInput,
  SirenInput,
  TextInput,
} from "shared/components";
import { loadProviders } from "../../store/reducers/dashboard/providers";

export const isValidApe = (value, helpers) => {
  const apeItem = findApeItem(value);
  if (apeItem === undefined) {
    return helpers.error("any.ape_not_included");
  } else if (
    ['64', '65', '66'].includes(value.substring(0, 2))
  ) {
    return helpers.error("any.ape_financial_company");
  }
  return value;
};

const validateSiren = (siren) => {
  if (siren.length !== 9) return false;
  let bal = 0;
  let total = 0;
  for (let i = 8; i >= 0; i--) {
    let step = (siren.charCodeAt(i) - 48) * (bal + 1);
    total += (step > 9) ? step - 9 : step;
    bal = 1 - bal;
  }
  return (total % 10 === 0);
}
const isValidSiren = (value, helpers) => {
  if (!validateSiren(value)) {
    return helpers.error("siren.invalid");
  }
  return value;
}

const Identification = ({ match }) => {
  const dispatch = useDispatch();

  const company = useSelector((store) => store.dashboard.company);
  const error422 = company.iban.error422;
  const [state, setState] = useState(company.identification.val);
  const [badField, setBadField] = useState(null);
  const isUpdate = match.params.action === "update";

  async function validateForm(obj) {
    const schema = Joi.object({
      name: Joi.string().required(),

      legal_form: Joi.string().valid(
        "SA",
        "SAS",
        "SARL",
        "SASU",
        "individual_enterprise",
        "other"
      ),
      // legal_form_other: Joi.string().allow(null, ""),
      legal_form_other: Joi.when("legal_form", {
        is: "other",
        then: Joi.string().required(),
      }),

      category: Joi.string().valid(
        "public_company",
        "governmental_company",
        "private_company",
        "other"
      ), //ENUM
      // category_other: Joi.string().allow(null, ""),//not required
      category_other: Joi.when("category", {
        is: "other",
        then: Joi.string().required(),
      }),
      share_capital: Joi.number().required(),

      ape_code: Joi.string().required().custom(isValidApe),
      activity_description: Joi.string().required(),

      siren: Joi.string().required().custom(isValidSiren),
      registered_on: Joi.date().required().max(Date.now()),
      holding: Joi.boolean(),
      fr_company: Joi.boolean().valid(true),
      us_company: Joi.boolean().valid(false),
      active_company: Joi.boolean()
      //
    });
    const { error } = schema.validate(obj);
    return error
      ? {
        name: error.details[0].path[0],
        type: error.details[0].type,
        message:
          error.details[0].type === "date.max"
            ? "Cela ne ressemble pas à une date valide"
            : error.details[0].type === "any.ape_not_included"
              ? "Cela ne ressemble pas à un code ape valide."
              : error.details[0].type === "any.ape_financial_company"
                ? "Nous n’acceptons pour l’instant pas les entreprises financières."
                : error.details[0].type === "siren.invalid"
                  ? "Cela ne ressemble pas à un SIREN valide."
                  :null,
      }
      : null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = await validateForm(state);
    setBadField(error);

    //WE WILL UPLOAD DATA AFTER COLLECTING THE NEXT FORMS
    if (!error) {
      if (isUpdate) {
        const successLink = "previous";
        const data = { ...getCompanyData(company), ...state };
        await dispatch(apiCompanyPost({ data, successLink, neverUploaded: false }));
        await dispatch(loadProviders());
      } else {
        dispatch(identificationUpdated(state));
        history.push("/main-address"); //When edited for the first time
      }
    }
  };

  return (
    <WrapPage>
      {!isUpdate && <div className="sectionName">1/4 — Entreprise</div>}
      <div className="formW">
        <div className="formH">
          <div className="goBack" onClick={() => history.goBack()}>
            <i className="icon-arrow-back" />
          </div>
          <div className="formTitle">Identification</div>
        </div>

        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <TextInput
            label="Dénomination Sociale"
            name="name"
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
            errorMessage={errorMessage("name", badField, error422)}
          />

          <SelectInput
            label="Forme juridique"
            name="legal_form"
            value={state.legal_form}
            onChange={(v) => setState({ ...state, legal_form: v })}
            lst={CompanyLegalForm}
            errorMessage={errorMessage("legal_form", badField, error422)}
          />

          {state.legal_form === "other" && (
            <HalfLineField label="Préciser">
              <TextInput
                label="Forme juridique"
                name="legal_form_other"
                type="text"
                value={state.legal_form_other}
                onChange={(e) =>
                  setState({ ...state, legal_form_other: e.target.value })
                }
                errorMessage={errorMessage(
                  "legal_form_other",
                  badField,
                  error422
                )}
              />
            </HalfLineField>
          )}

          <SelectInput
            label="Catégorie"
            name="category"
            value={state.category}
            onChange={(v) => setState({ ...state, category: v })}
            lst={CompanyCategory}
            errorMessage={errorMessage("category", badField, error422)}
          />

          {state.category === "other" && (
            <HalfLineField label="Préciser">
              <TextInput
                label="Autre catégorie"
                name="category_other"
                type="text"
                value={state.category_other}
                onChange={(e) =>
                  setState({ ...state, category_other: e.target.value })
                }
                errorMessage={errorMessage(
                  "category_other",
                  badField,
                  error422
                )}
              />
            </HalfLineField>
          )}

          <TextField
            label="Activité de l’entreprise"
            name="activity_description"
            multiline
            fullWidth
            error={errorMessage("activity_description", badField, error422) !== null}
            helperText={errorMessage("activity_description", badField, error422)}
            inputProps={{
              value: state.activity_description,
              onChange: (e) => setState({ ...state, activity_description: e.target.value })
            }}
            rows={2}
          />

          <PriceInput
            label="Capital Social"
            name="share_capital"
            value={state.share_capital}
            onChange={(v) => setState({ ...state, share_capital: v })}
            helperText={errorMessage("share_capital", badField, error422)}
            error={!!errorMessage("share_capital", badField, error422)}
          />

          <TextInput
            label="Code APE"
            name="ape_code"
            variant="outlined"
            size="small"
            fullWidth
            value={state.ape_code}
            onChange={(e) => setState({ ...state, ape_code: e.target.value })}
            errorMessage={errorMessage(
              "ape_code",
              badField,
              error422,
              badField && badField.message ? badField.message : null
            )}
          />
          <SirenInput
            label="SIREN"
            name="siren"
            value={state.siren}
            onChange={(v) => setState({ ...state, siren: v })}
            errorMessage={errorMessage("siren", badField, error422, badField && badField.message ? badField.message : null)}
          />

          <HalfLineField label="Créée le" leftPercent={32}>
            <BirthdayInput
              value={state.registered_on}
              name="registered_on"
              onChange={(v) => setState({ ...state, registered_on: v })}
              errorMessage={errorMessage(
                "registered_on",
                badField,
                error422,
                badField && badField.message ? badField.message : null
              )}
            />
          </HalfLineField>

          <CheckBox
            checked={!state.active_company}
            onChange={(v) => setState({ ...state, active_company: !v })}
            name="active_company"
            label={<>Mon entreprise est considérée comme passive (<a
              href="https://help.cashbee.fr/article/209-entreprise-active" target="_blank" rel="noreferrer">?</a>)</>}
            errorMessage={
              errorMessage(
                "active_company",
                badField,
                error422,
                badField && badField.message ? badField.message : null
              )
            }
          />

          <CheckBox
            checked={state.fr_company}
            onChange={(v) => setState({ ...state, fr_company: v })}
            name="fr_company"
            label="La France est la seule résidence fiscale de mon entreprise"
            errorMessage={
              badField && badField.name === "fr_company"
                ? "Nous n’acceptons pour l’instant que les entreprises basées en France"
                : null
            }
          />

          <CheckBox
            checked={state.us_company}
            onChange={(v) => setState({ ...state, us_company: v })}
            name="us_company"
            label={
              <span>
                Mon entreprise est américaine au sens de{" "}
                <span>la réglementation FATCA</span>
              </span>
            }
            errorMessage={
              badField && badField.name === "us_company"
                ? "Nous n’acceptons pour l’instant pas les entreprises américaines"
                : null
            }
          />

          {company.identification.apiUplaodError && (
            <GlobalErrorMessage>
              {company.identification.apiUplaodError}
            </GlobalErrorMessage>
          )}

          <Button type="submit" variant="contained" primary>
            {isUpdate ? "Enregistrer" : "Suivant"}
          </Button>
        </form>
      </div>
    </WrapPage>
  );
};

export default Identification;
