import React from "react";

const BlankLink = ({ to, name }) => {
  return (
    <a href={to} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
      {name}
    </a>
  );
};

export default function StaticLink({ to, children }) {
  switch (to) {
    case "conditions_generales_utilisation":
      return <BlankLink to="https://www.cashbee.fr/legal/cgu-cashbee-pro" name={children} />;
    case "dashboard_help":
    case "contactez_nous":
      return <BlankLink to="https://help.cashbee.fr/collection/141-cashbee-pro" name={children} />;
    case "centre_aide":
      return <BlankLink to="https://www.cashbee.fr/cashbee-pro" name={children}/>;
    default:
      return children;
  }
}
