import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { history } from "store";

import { Button } from "shared/components";
import DocumentUploader from "./DocumentUploader";
import { DocumentFolder, DocumentSubFolder, DocumentType, enum_moral_company_id } from "shared/enums/DocumentsTypes";
import { WrapMainForm } from "Funnel/Components/WrapPage";
import "./documents.scss";
import EditDocumentAdding from "./EditDocumentAdding";
import DocumentsList from "./DocumentsList";

const CompanyMoraleDocs = ({ isUpdate }) => {
  const { docs, moreUploadNeeded } = useSelector(
    (store) => store.dashboard.documents.company_moral
  );

  const [notCompleted, setNotCompleted] = useState(false);
  const [edited, setEdited] = useState(false);
  useEffect(() => setNotCompleted(false), [moreUploadNeeded]);

  const handleValidate = async () => {
    if (!moreUploadNeeded) {
      if (isUpdate) {
        history.goBack();
      } else {
        history.push("/representative-documents");
      }
    } else {
      setNotCompleted(true);
    }
  };

  return (
    <div className="funnelPgW">
      <div className={`funnelPgContent ${edited ? "rightFormEdited" : ""}`}>
        <div className="funnelPgL">
          <WrapMainForm>
            {!isUpdate && <div className="sectionName">4/4 — Documents</div>}
            <div className="formW">
              <div className="formH">
                <div className="goBack" onClick={() => history.goBack()}>
                  <i className="icon-arrow-back" />
                </div>
                <div className="formTitle">
                  {DocumentFolder.company_moral.label}
                </div>
              </div>

              <div className="requiredFields">
                <p>*Champs obligatoires</p>
              </div>
              <div>
                <div className="docZone">
                  <div className="docTitle">Immatriculation de l’entreprise*</div>
                  <div className="docDescription">
                    Il s’agit, au choix, d’un extrait KBIS ou d’un extrait des inscriptions au Registre National des Entreprises.
                  </div>
                  <DocumentsList
                    folder={DocumentFolder.company_moral.key}
                    subFolder={DocumentSubFolder.company_id.key}
                    list={docs.company_id}
                    onAddingClick={() => {
                      setEdited(true);
                    }}
                    active={edited}
                    errorClass={notCompleted && docs.company_id.length === 0}
                  />
                </div>
                <div className="docZone">
                  <div className="docTitle">
                    Dernière liasse fiscale complète*
                  </div>
                  <div className="docDescription">
                    Si votre premier exercice social n’est pas encore terminé, vous devez déposer un relevé de compte de moins de 3 mois.
                  </div>
                  <div className="docDescription">
                    <a href="https://help.cashbee.fr/article/172-ou-trouver-les-documents-demandes" target="_blank"
                       rel="noreferrer">
                      Où trouver le document demandé ?
                    </a>
                  </div>
                  <DocumentUploader
                    folder={DocumentFolder.company_moral.key}
                    documentType={DocumentType.tax_return.key}
                    currentItem={docs.tax_return}
                    errorClass={notCompleted && !docs.tax_return}
                  />
                </div>

                <div className="docZone">
                  <div className="docTitle">Derniers statuts à jour*</div>
                  <div className="docDescription">
                    Attention : ils doivent être certifiés conformes par le mandataire social.
                  </div>
                  <DocumentUploader
                    folder={DocumentFolder.company_moral.key}
                    documentType={DocumentType.status.key}
                    currentItem={docs.status}
                    errorClass={notCompleted && !docs.status}
                  />
                </div>

                <div className="docZone">
                  <div className="docTitle">RIB*</div>
                  <DocumentUploader
                    folder={DocumentFolder.company_moral.key}
                    documentType={DocumentType.rib.key}
                    currentItem={docs.rib}
                    errorClass={notCompleted && !docs.rib}
                  />
                </div>

                <div className="docZone">
                  <div className="docTitle">Origine des fonds</div>
                  <div className="docDescription">
                    Pour tout virement de 200 000€ ou plus, vous devez fournir un relevé de compte ou une attestation
                    de solde.
                  </div>
                  <DocumentUploader
                    folder={DocumentFolder.company_moral.key}
                    documentType={DocumentType.funds_origin.key}
                    currentItem={docs.funds_origin}
                  />
                </div>

                <Button onClick={handleValidate} variant="contained" primary>
                  {isUpdate ? "Enregistrer" : "Suivant"}
                </Button>
              </div>
            </div>
          </WrapMainForm>
        </div>
        <div className="funnelPgR">
          {edited &&
            <EditDocumentAdding
            title={DocumentSubFolder.info_greffe.label}
            folder={DocumentFolder.company_moral.key}
            subFolder={DocumentSubFolder.company_id.key}
            selectEnum={enum_moral_company_id}
            close={() => setEdited(false)}
          />
          }
        </div>
      </div>
    </div>
  );
};

export default CompanyMoraleDocs;
