import React from "react";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';

const StyledButton =  styled(Button)({
  backgroundColor: '#FED400',
  borderColor: '#FED400',
  borderRadius: '6px',
  padding: '12px 16px',
  '&:hover': {
    backgroundColor: '#FFE97A',
    borderColor: '#FFE97A',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#FFE97A',
    borderColor: '#FFE97A',
  },
});

const YellowButton = ({ onClick, disabled, title }) => {
  return (
    <React.Fragment>
      <StyledButton
        size="large"
        fullWidth
        className="btn40"
        onClick={onClick}
        disabled={disabled}
      >
        <div className="adding-btn-content">
          {title}
          <i className="icon-add-circle" />
        </div>
      </StyledButton>

    </React.Fragment>
  );
};

export { YellowButton };
