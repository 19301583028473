import { useDispatch, useSelector } from "react-redux";
import { loadProviders } from "../store/reducers/dashboard/providers";
import React from "react";

export default function useProvider() {
  const dispatch = useDispatch();
  const providerLength = useSelector((store) => store.dashboard.providers.length);

  React.useEffect(() => {
    if (providerLength === 0) {
      dispatch(loadProviders());
    }
  })
}
