import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "confirm",
  initialState: {
    open: false,
  },
  reducers: {
    open: (state, action) => {
      state.open = true;
    },
    close: (state, action) => {
      state.open = false;
    },
  },
});

export const { open, close } = slice.actions;
export default slice.reducer;
