export const INDIVIDUAL_INFO_GREFFE_TYPE = [
  "k_bis",
  "artisan_registry",
  "siren",
  "other_individuelle_info_greffe_doc",
];

export const INDIVIDUAL_TAX_RETURN_TYPE = [
  "tax_return",
  "declaration_2035",
  "other_individuelle_tax_returns_doc",
];

export const ID_DOC_TYPE = [
  "passport",
  "identity_card",
  "resident_card",
];

export const DocumentFolder = {
  company_individual: { key: "company_individual", label: "Entreprise" },
  company_moral: { key: "company_moral", label: "Entreprise" },
  representative: { key: "representative", label: "Représentant légal" },
  interlocutor: { key: "interlocutor", label: "Administrateur du compte" },
  beneficiaries: {
    key: "ultimate_beneficiary_owners",
    label: "Bénéficiaires effectifs",
  },
};
export const DocumentSubFolder = {
  id_docs: { key: "id_docs", label: "Pièce d’identité" },
  info_greffe: { key: "info_greffe", label: "Immatriculation de l’entreprise" },
  tax_returns: { key: "tax_returns", label: "Déclarations fiscales" },
  company_id: { key: "company_id", label: "Immatriculation de l'entreprise" },
};

export const DocumentType = {
  rib: { key: "rib", label: "RIB" },
  tax_return: { key: "tax_return", label: "Dernière liasse fiscale" },
  k_bis: { key: "k_bis", label: "Extrait K-bis" },
  declaration_2035: { key: "declaration_2035", label: "Déclaration 2035" },
  siren: { key: "siren", label: "Avis de situation au répertoire SIREN" },
  artisan_registry: {
    key: "artisan_registry",
    label: "Extrait d'inscription au répertoire des métiers",
  },
  ubo_identity_paper: { key: 'ubo_identity_paper', label: "Pièce d'identités des bénéficiaires effectifs" },
  passport: { key: "passport", label: "Passeport" },
  identity_card: { key: "identity_card", label: "Carte d'identité" },
  resident_card: { key: "resident_card", label: "Carte de séjour" },
  driver_license: { key: "driver_license", label: "Permis de conduire" },
  status: { key: "status", label: "Copie des statuts" },
  power_copy: { key: "power_copy", label: "Délégation de pouvoir" },
  funds_origin: { key: "funds_origin", label: "Origine des fonds" },
};

export const enum_individual_info_greffe = {
  k_bis: "Extrait K-bis",
  artisan_registry: "Extrait d'inscription au répertoire des métiers",
  siren: "Avis de situation au répertoire SIREN",
};

export const enum_moral_company_id = {
  k_bis: "Extrait K-bis",
  company_registry: "Extrait des inscriptions au Registre National des Entreprises",
};

export const enum_individual_tax_returns = {
  tax_return: "Dernière liasse fiscale",
  declaration_2035: "Déclaration 2035",
};

export const enum_id_docs = {
  passport: "Passeport",
  identity_card: "Carte d'identité",
  resident_card: "Carte de séjour",
};
