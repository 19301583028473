import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startAdding } from "store/reducers/dashboard/procedure";

import AddingButton, { ActiveAddingButton, } from "Accounts/Components/AddingButton";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";

const CommandButtons = ({ provider, maxAccountsNumber }) => {
  const dispatch = useDispatch();
  const dashboard = useSelector((store) => store.dashboard);

  const activeAccountsNumber = dashboard.accounts.totalNumbers[provider.slug];
  const totalNumber = activeAccountsNumber + dashboard.procedure.totalNumber;
  const maxNbr = maxAccountsNumber ?? 10; //If not given in config so 10
  const handleStartAdding = () => {
    dispatch(startAdding());
  };

  const procAccounts = useSelector((store) => store.dashboard.procedure);

  const activeAdding =
    procAccounts.edited && procAccounts.edited.currValue.id === null;

  if (activeAdding) {
    return <ActiveAddingButton />;
  }
  return (
    <React.Fragment>
      <AddingButton disabled={totalNumber === maxNbr} onClick={handleStartAdding} />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
      <Link href={provider.offer.grid_link} target="_blank" color="inherit" underline="always">
        Voir la grille des taux
      </Link>
      </Box>
    </React.Fragment>
  );
};

export default CommandButtons;
