import { createSlice } from "@reduxjs/toolkit";
import { userLoggedOut } from "store/reducers/auth";
import { apiCall } from "../../../api";

const emptyAddress = {
  street: "",
  second_line: "", //TODO
  postcode: "",
  city: "",
  territory: "", //TODO
  country: "FR",
};

const initSet = (company) => {
  const head_office_address = company ? company.head_office_address : null;
  const mail_address = company ? company.mail_address : null;
  return {
    siege: {
      address: head_office_address ?? { ...emptyAddress },
      neverUploaded: !head_office_address,
    },
    mailing: {
      address: mail_address ?? { ...emptyAddress },
      neverUploaded: !mail_address,
    },
  };
};

const slice = createSlice({
  name: "addresses",
  initialState: {
    set: {
      siege: {
        address: { ...emptyAddress },
        neverUploaded: false,
      },
      mailing: {
        address: { ...emptyAddress },
        neverUploaded: false,
      },
    },
  },
  reducers: {
    siegeUpdated: (state, { payload }) => {
      state.set.siege.address = payload.state.address;
      state.set.siege.neverUploaded = false;
    },
    correspondanceUpdated: (state, { payload }) => {
      state.set.mailing.address = payload.state;
      state.set.mailing.neverUploaded = false;
    },
    companyPutFailed: (state, { payload }) => {
      state.error422 = payload.error422;
      state.apiUploadError = payload.message;
    },
  },
  extraReducers: {
    initDashboard: (state, { payload }) => {
      state.set = initSet(payload.company);
    },
    updatedCompany: (state, { payload }) => {
      state.set = initSet(payload);
    },
    [userLoggedOut.type]: (state, { payload }) => {
      state = null;
    },
  },
});

export const apiCompanyPut = ({ data, successLink }) =>
  apiCall({
    url: "/company",
    method: "put",
    data,
    successLink,
    onSuccess: "updatedCompany",
    onError: slice.actions.companyPutFailed.type,
  });

export const { siegeUpdated, correspondanceUpdated } = slice.actions;
export default slice.reducer;
