import { createSlice, current } from "@reduxjs/toolkit";
import { userLoggedOut } from "store/reducers/auth";
import { DocumentFolder, INDIVIDUAL_INFO_GREFFE_TYPE, INDIVIDUAL_TAX_RETURN_TYPE, } from "shared/enums/DocumentsTypes";

const moreUploadNeeded = (docs) => {
  return (
    docs.info_greffe.length === 0 || docs.tax_returns.length === 0 || !docs.rib
  );
};

const slice = createSlice({
  name: "company_individual",
  initialState: {
    docs: {
      info_greffe: [],
      tax_returns: [],
      status: null,
      rib: null,
    },
    moreUploadNeeded: false,
  },
  reducers: {},
  extraReducers: {
    initDashboard: (state, { payload }) => {
      if (
        !payload.company ||
        !payload.company.legal_form ||
        !payload.documents
      ) {
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
        return;
      }
      //else
      if (payload.company.legal_form === "individual_enterprise") {
        payload.documents.forEach((doc) => {
          if (doc.owner_type.toLowerCase() === "company") {
            if (INDIVIDUAL_INFO_GREFFE_TYPE.includes(doc.document_type)) {
              state.docs.info_greffe.push(doc);
            } else if (INDIVIDUAL_TAX_RETURN_TYPE.includes(doc.document_type)) {
              state.docs.tax_returns.push(doc);
            } else if (doc.document_type === "status") {
              state.docs.status = doc;
            } else if (doc.document_type === "funds_origin") {
              state.docs.funds_origin = doc;
            } else if (doc.document_type === "rib") {
              state.docs.rib = doc;
            }
          }
        });
      }
      state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
    },
    documentUploaded: (state, { payload }) => {
      if (payload.folder === DocumentFolder.company_individual.key) {
        switch (payload.subFolder) {
          case "info_greffe":
            state.docs.info_greffe.push(payload);
            break;
          case "tax_returns":
            state.docs.tax_returns.push(payload);
            break;
          case "funds_origin":
            state.docs.funds_origin = payload;
            break;
          default:
            if (payload.document_type === "status")
              state.docs.status = payload;
            else if (payload.document_type === "rib") state.docs.rib = payload;
            break;
        }
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    documentDeleted: (state, { payload }) => {
      if (payload.folder === DocumentFolder.company_individual.key) {
        switch (payload.subFolder) {
          case "info_greffe":
            state.docs.info_greffe = state.docs.info_greffe.filter(
              (x) => x.id !== payload.id
            );
            break;
          case "tax_returns":
            state.docs.tax_returns = state.docs.tax_returns.filter(
              (x) => x.id !== payload.id
            );
            break;
          case "funds_origin":
            if (state.docs.funds_origin.id === payload.id) {
              state.docs.funds_origin = null;
            }
            break;
          default:
            if (state.docs.status && state.docs.status.id === payload.id) {
              state.docs.status = null;
            } else if (state.docs.rib && state.docs.rib.id === payload.id) {
              state.docs.rib = null;
            }
            break;
        }
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    [userLoggedOut.type]: (state, { payload }) => {
      state = null;
    },
  },
});

export const { documentUploaded } = slice.actions;
export default slice.reducer;
