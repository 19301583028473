import React from "react";
import "../signature.scss";

function WaitingSignature({ email }) {
  return (
    <>
      <h3 className="signSecH">Votre dossier est complet !</h3>
      <div className="signSecBdy">
        Votre contrat vous a été envoyé par mail à l’adresse <b>{email}</b><br /><br />
        Nous attendons votre signature afin de soumettre votre demande d’ouverture de compte à terme à nos équipes et à
        notre partenaire bancaire.
      </div>
    </>
  );
}

export default WaitingSignature;
