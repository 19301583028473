import Lock from './lock.svg'
import Info from './info.svg'

export const LockIcon = (props) => {
  return (
    <img alt='lockIcon' src={Lock} {...props} />
  )
}

export const InfoIcon = (props) => {
  return (
    <img alt='infoIcon' src={Info} {...props} />
  )
}
