import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiDeleteBeneficiary,
  apiSaveNewBeneficiary,
  apiUpdateBeneficiary,
  disableNeverUploadedFlag,
} from "store/reducers/dashboard/benificiaries";
import {
  acceptConfirmation,
  askForConfirmation,
  denyConfirmation,
  startAdding,
  startEditing,
  stateChanging,
  stopEditing,
} from "store/reducers/ui/company/benificiaries";
import { WrapMainForm } from "Funnel/Components/WrapPage";

import { history } from "store";

import { Button, DataCollapsible, GlobalErrorMessage } from "shared/components";
import BeneficiaryAdd from "./BeneficiaryAdd";
import BeneficiaryEditBody from "./BeneficiaryEditBody";
import BeneficiariesLst from "./BeneficiariesLst";
import { ConfirmDialog } from "shared/components/Dialog";
import validateForm from "./validate";
import { useParams } from "react-router-dom";

const Benificiaries = () => {
  const { providerSlug } = useParams();
  const dispatch = useDispatch();
  const benificiaries = useSelector((store) => store.dashboard.benificiaries);
  const state = benificiaries.list;

  const percentageSum = state.reduce(
    (a, b) => a + parseFloat(b.share_percent),
    0
  );

  const ui = useSelector((store) => store.ui.company.benificiaries);
  const [badField, setBadField] = useState(null);

  const handleStartAdding = () => {
    if (!ui.initValue || ui.initValue === ui.currValue) {
      dispatch(startAdding());
    } else {
      dispatch(
        askForConfirmation({
          title: "Attention",
          message:
            "Vous quitterez sans enregistrer vos modifications! Voulez-vous continuer?",
          todo: () => dispatch(startAdding()),
        })
      );
    }
  };
  const handleStartEditing = ({ item, index }) => {
    if (!ui.initValue || ui.initValue === ui.currValue) {
      //there no item edited
      dispatch(startEditing({ item, index }));
    } else if (
      ui.initValue.id !== item.id && //not the same item edited
      ui.initValue !== ui.currValue
    ) {
      //there modif
      dispatch(
        askForConfirmation({
          title: "Attention",
          message:
            "Vous quitterez sans enregistrer vos modifications! Voulez-vous continuer?",
          todo: () => dispatch(startEditing({ item, index })),
        })
      );
    } else {
      dispatch(startEditing({ item, index }));
    }
  };
  const handleStopEditing = ({ force }) => {
    if (ui.initValue) {
      if (!force && ui.initValue !== ui.currValue) {
        dispatch(
          askForConfirmation({
            title: "Attention",
            message:
              "Vous quitterez sans enregistrer vos modifications! Voulez-vous continuer?",
            todo: () => dispatch(stopEditing()),
          })
        );
      } else {
        dispatch(stopEditing());
      }
    }
  };
  const handleGoToNextPage = async () => {
    dispatch(disableNeverUploadedFlag());
    const hasBeneficiaries = state && state.length > 0;
    if (providerSlug) {
      history.push(hasBeneficiaries ? `/${providerSlug}/beneficiaries-documents` : "/settings-confirm")
    } else {
      history.push("/company-documents");
    }
  };
  //SAVING NEW BENEFICIARY
  const handleNewSave = async (e) => {
    e.preventDefault();
    const error = await validateForm(ui.currValue);

    setBadField(error);

    if (!error) {
      dispatch(apiSaveNewBeneficiary({ data: ui.currValue }));
    }
  };
  //REMOVE ITEM HANDLING
  const handleRemove = () => {
    if (ui.addingNewOne) {
      handleStopEditing({ force: true });
    } else {
      dispatch(
        askForConfirmation({
          title: "Attention",
          message:
            "Vous allez supprimer un bénéficiaire! Voulez-vous continuer?",
          todo: () => {
            //if id not null should delete from server/store
            dispatch(apiDeleteBeneficiary({ id: ui.currValue.id }));
            // handleStopEditing({ currState: ui.currValue });
          },
        })
      );
    }
  };
  //UPDATING
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (ui.currValue === ui.initValue) {
      handleStopEditing({ force: true }); //nothing to save
    } else {
      const error = await validateForm(ui.currValue);
      setBadField(error);

      if (!error) {
        dispatch(apiUpdateBeneficiary({ data: ui.currValue }));
      }
    }
  };

  const withRightFormEdited = `${
    ui.initValue || ui.needConfirm ? "rightFormEdited" : ""
  }`;

  return (
    <div className="funnelPgW">
      <div className={`funnelPgContent ${withRightFormEdited}`}>
        <div className="funnelPgL">
          <WrapMainForm>
            {!providerSlug && <div className="sectionName">3/4 — Contrôle de la société</div>}
            <div className="formW">
              <div className="formH">
                <div className="goBack" onClick={() => history.goBack()}>
                  <i className="icon-arrow-back" />
                </div>
                <div className="formTitle">Bénéficiaires effectifs</div>
              </div>

              <form noValidate autoComplete="off">
                <div className="formDsc">
                  <p>Il s'agit de toutes les personnes qui soit :</p>
                  <ul>
                    <li>
                      Détiennent directement ou indirectement plus de 25% des parts de l'entreprise
                    </li>
                    <li>
                      Disposent d'un pouvoir de contrôle sur l'entreprise par tout autre moyen
                    </li>
                  </ul>
                  <p>S'il n’y en a aucune, cliquez simplement sur Suivant. </p>
                  <p>Pour plus d’informations, retrouvez notre <a
                    href="https://help.cashbee.fr/article/184-les-beneficiaires-effectifs"
                    target="_blank"
                    rel="noreferrer">article</a> sur le centre
                    d’aide à ce sujet.</p>
                </div>

                {/* SHOW LIST OF CARDS RELATED TO LIST OF BENIFICIARIES */}
                <BeneficiariesLst
                  list={state}
                  updatedIndex={ui.updatedIndex}
                  onItemEdit={({ item, index }) =>
                    handleStartEditing({ item, index })
                  }
                />

                {percentageSum > 100 && (
                  <GlobalErrorMessage>
                    Le total des parts de vos bénéficiaires effectifs ne peut
                    pas excéder 100%.
                  </GlobalErrorMessage>
                )}

                {percentageSum <= 100 && (
                  <React.Fragment>
                    <BeneficiaryAdd
                      number={state.length}
                      active={ui.addingNewOne}
                      maxNumber={ui.maxNumber}
                      onClick={handleStartAdding}
                    />

                    <Button
                      onClick={handleGoToNextPage}
                      variant="contained"
                      primary
                    >
                      Suivant
                    </Button>
                  </React.Fragment>
                )}
                <DataCollapsible />
              </form>
            </div>
          </WrapMainForm>
        </div>
        <div className="funnelPgR">
          {ui.needConfirm && (
            <ConfirmDialog
              open={ui.needConfirm !== null}
              title={ui.needConfirm.title}
              message={ui.needConfirm.message}
              onOk={() => {
                dispatch(acceptConfirmation());
                ui.needConfirm.todo();
              }}
              onCancel={() => dispatch(denyConfirmation())}
            ></ConfirmDialog>
          )}

          {ui.initValue && (
            <div className="funnelSubForm">
              <div className="formW">
                <div className="formH">
                  <div className="formTitle">
                    Bénéficiaire {state.length + 1}
                  </div>
                  <div onClick={handleStopEditing}>
                    <i className="icon-close" />
                  </div>
                </div>

                <form noValidate autoComplete="off">
                  <BeneficiaryEditBody
                    badField={badField}
                    beneficiary={ui.currValue}
                    onStateChange={(item) => dispatch(stateChanging({ item }))}
                  />

                  {/* IF MODIFICATION */}
                  {ui.currValue.id && (
                    <div className="buttonsLst">
                      <Button variant="outlined" onClick={handleRemove}>
                        Supprimer
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        primary
                        onClick={handleUpdate}
                      >
                        Enregistrer
                      </Button>
                    </div>
                  )}
                  {/* IF ADDING */}
                  {!ui.currValue.id && (
                    <div className="buttonsLst">
                      <Button variant="outlined" onClick={handleStopEditing}>
                        Annuler
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        primary
                        onClick={handleNewSave}
                      >
                        Ajouter
                      </Button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Benificiaries;
