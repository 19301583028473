import { createSlice } from "@reduxjs/toolkit";
import {
  IS_ACTIVE_ACTIVE,
  IS_ACTIVE_CLOSING,
  IS_CLOSED_CLOSED,
  IS_CLOSED_CLOSING,
  IS_CLOSING_ACCEPTED,
  IS_FINISHED,
  IS_PENDING,
} from "shared/enums/AccountStatus";
import { computeTotalFunds, computeTotalFundsPerProviders } from "shared/utilities";
import { procedurePosted } from "store/reducers/dashboard/procedure";
import { userLoggedOut } from "store/reducers/auth";
import { apiCall } from "store/api";
import { CFCAL_SLUG, MMB_SLUG, WORMSER_SLUG } from "../../../config.json";

const sumRendements = (accounts) => {
  return accounts.reduce((a, b) => a + parseFloat(b.interest), 0);
};

const DEFAULT_STATE = {
  [WORMSER_SLUG]: 0,
  [MMB_SLUG]: 0,
  [CFCAL_SLUG]: 0,
}

const slice = createSlice({
  name: "accounts",
  initialState: {
    loadedUi: false,
    noAccount: false,
    totalNumber: 0,
    totalNumbers: { ...DEFAULT_STATE },
    totalActiveNumbers: { ...DEFAULT_STATE },
    totalActiveAmount: 0,
    totalActiveAmounts: { ...DEFAULT_STATE },
    totalRendement: 0,

    pendingList: [],
    providerWithPending: [],
    pending: null,
    activeList: [],
    closedList: [],
  },
  reducers: {
    askedForClosure: (state, action) => {
      state.activeList = state.activeList.map((x) =>
        x.id === action.payload.id
          ? { ...x, closure: { ...x.closure, status: "pending" } }
          : x
      );
      state(state);
    },
    accountsLoaded: (state, { payload }) => {
      if (payload.accounts && payload.accounts.length) {
        const pendingList = payload.accounts.filter((x) => IS_PENDING(x));
        state.pendingList = pendingList;
        state.providerWithPending = pendingList.map((p) => p.provider.slug)
          .filter((value, index, array) => array.indexOf(value) === index);
        state.filterableProviders = payload.accounts.map((p) => p.provider.slug)
          .filter((value, index, array) => array.indexOf(value) === index);
        state.activeList = payload.accounts.filter((x) => IS_ACTIVE_CLOSING(x));
        payload.accounts.forEach((x) => {
          if (IS_ACTIVE_ACTIVE(x)) state.activeList.push(x);
        });
        state.closedList = payload.accounts.filter((x) => IS_CLOSED_CLOSING(x));
        payload.accounts.forEach((x) => {
          if (IS_CLOSED_CLOSED(x)) state.closedList.push(x);
          if (IS_CLOSING_ACCEPTED(x)) state.closedList.push(x);
          if (IS_FINISHED(x)) state.closedList.push(x);
        });
      }
      //
      state.loadedUi = true;
      state.noAccount = !payload.accounts || !payload.accounts.length;

      const activeOrPendingAccounts = payload.accounts.filter(
        (x) => x.status === "pending" || x.status === "active"
      );
      state.totalNumber = activeOrPendingAccounts.length;
      state.totalNumbers = activeOrPendingAccounts.reduce((a, b) => {
        return { ...a, [b.provider.slug]: a[b.provider.slug] + 1 };
      }, DEFAULT_STATE);
      state.totalActiveNumbers = state.activeList.reduce((a, b) => {
        return { ...a, [b.provider.slug]: a[b.provider.slug] + 1 };
      }, DEFAULT_STATE);
      state.totalActiveAmount = computeTotalFunds(activeOrPendingAccounts); //Useful for UI
      state.totalActiveAmounts = computeTotalFundsPerProviders(activeOrPendingAccounts); //Useful for UI
      state.totalRendement = sumRendements(activeOrPendingAccounts);
    },
    closingAsked: (state, { payload }) => {
      state.activeList = state.activeList.map((x) =>
        x.id !== payload.id ? x : { ...x, closure: payload }
      );
    },
    closingStoped: (state, { payload }) => {
      state.activeList = state.activeList.map((x) =>
        x.id !== payload.id ? x : { ...x, closure: null }
      );
    },
    closingFailed: (state, { payload }) => {
    },
    stopClosingFailed: (state, { payload }) => {
    },
  },
  extraReducers: {
    initDashboard: (state, action) =>
      slice.caseReducers.accountsLoaded(state, action),

    [procedurePosted.type]: (state, { payload }) => {
      state.pending = payload.accounts;
    },
    [userLoggedOut.type]: (state, { payload }) => {
      state = null;
    },
  },
});

export const loadAccounts = () =>
  apiCall({
    url: "/accounts",
    onSuccess: slice.actions.accountsLoaded.type,
  });
export const apiAsk4ClosingAccount = ({ id }) =>
  apiCall({
    url: `/accounts/${id}/closure`,
    method: "post",
    onSuccess: slice.actions.closingAsked.type,
    onError: slice.actions.closingFailed.type,
    localData: { id },
  });

export const apiStopClosingAccount = ({ id }) =>
  apiCall({
    url: `/accounts/${id}/closure`,
    method: "delete",
    onSuccess: slice.actions.closingStoped.type,
    onError: slice.actions.stopClosingFailed.type,
    localData: { id },
  });

export const {
  askedForClosure,
  closingAsked,
  closingStoped,
  closingFailed,
  stopClosingFailed,
} = slice.actions;
export default slice.reducer;
