import React from "react";
import AccountsList from "./AccountsList";
import * as AccountStatus from "shared/enums/AccountStatus";
import "./accountsList.scss";

const TypeLabel = {
  [AccountStatus.PENDING]: "En cours d’ouverture",
  [AccountStatus.ACTIVE]: "Actif",
  [AccountStatus.CLOSED]: "Clôturé",
};

const AccountsListHeader = ({ label }) => {
  return (
    <div className="accountsListHW">
      <div className="accountsListH">{label}</div>
    </div>
  );
};

function TypedAccountsList({ type, accounts }) {
  return (
    <div className="accountsCardsW">
      <AccountsListHeader label={TypeLabel[type]} />
      <AccountsList accounts={accounts} />
    </div>
  );
}

export default TypedAccountsList;
