import { createSlice } from "@reduxjs/toolkit";
import { closingAsked, closingFailed, closingStoped, stopClosingFailed, } from "store/reducers/dashboard/accounts";

const slice = createSlice({
  name: "accounts",
  initialState: {
    showAccountInfo: {
      account: null,
      confirm: null, //if confirm null we show only the info of the account depending on its status
      //if transition not null => user is asking for a transition (like closing an account)
    },
    procedure: null,
    closingFailedMessage: null,
    stopClosingFailedMessage: null,
  },
  reducers: {
    showAccountInfo: (state, action) => {
      state.showAccountInfo.account = action.payload.account;
      state.showAccountInfo.confirm = null;
    },
    showConfirmClosure: (state, action) => {
      state.showAccountInfo.confirm = "confirm_closure";
    },
    hideAccountInfo: (state, action) => {
      state.showAccountInfo.account = null;
      state.showAccountInfo.confirm = null;
    },
    hideConfirm: (state, action) => {
      state.showAccountInfo.confirm = null;
    },

    //handling api results
  },
  extraReducers: {
    [closingAsked.type]: (state, { payload }) => {
      state.showAccountInfo.account = null;
      state.showAccountInfo.confirm = null;
    },
    [closingStoped.type]: (state, { payload }) => {
      state.showAccountInfo.account = null;
      state.showAccountInfo.confirm = null;
    },
    [closingFailed.type]: (state, { payload }) => {
      state.error422 = payload.error422;
      state.closingFailedMessage = payload.message;
    },
    [stopClosingFailed.type]: (state, { payload }) => {
      state.error422 = payload.error422;
      state.stopClosingFailedMessage = payload.message;
    },
  },
});


export const {
  showAccountInfo,
  hideAccountInfo,
  showConfirmClosure,
  hideConfirm,
} = slice.actions;
export default slice.reducer;
