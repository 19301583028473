import React from "react";
import { Button } from "shared/components";
import "../beneficiary.scss";

const ActiveButton = ({ number }) => {
  return (
    <div className="inactiveAddingButton">
      <span>Bénéficiaire {number+1}</span>
      <span className="icon-chevron-right" />
    </div>
  );
};

const ClickableButton = ({ number, maxNumber, onClick }) => {
  const enabled = number < maxNumber;

  return (
    <React.Fragment>
      <Button variant="outlined" disabled={!enabled} onClick={onClick}>
        <span>
          Ajouter bénéficiaire
        </span>
        <i className="icon-add-circle" />
      </Button>
      {!enabled && (
        <div className="nbrExceededComment">
          Il ne peut pas y avoir plus de {maxNumber} bénéficiaires effectifs.
        </div>
      )}
    </React.Fragment>
  );
};

const BeneficiaryAdd = ({ number, maxNumber, active, onClick }) => {
  return active ? (
    <ActiveButton number={number} />
  ) : (
    <ClickableButton number={number} maxNumber={maxNumber} onClick={onClick} />
  );
};

export default BeneficiaryAdd;
