import Joi from "joi";
import * as interlocutorType from "./interlocutorType";

import { isValidPhone } from "shared/components/PhoneNumber";

const MATURITY_AGE = 18;

async function validateForm(obj, personType, uboNotNeeded) {
  const maturityCutoffDate =
    Date.now() - 1000 * 60 * 60 * 24 * MATURITY_AGE * (365 + 1 / 4); // go back by 18 years

  const AdminSpec = {
    civility: Joi.string().valid("ms", "mr"),
    last_name: Joi.string().required(),
    first_name: Joi.string().required(),
    birth_name: Joi.string().allow(null, ""), //not required
    email: Joi.string()
      .required()
      .email({
        tlds: { allow: false },
      }),
    position: Joi.string().required().valid(
        "president",
        "director",
        "manager",
        "vice_president",
        "cfo",
        "liberal_profession",
        "retailer",
        "artisan",
        "finance",
        "treasurer",
        "accountant",
    ),
    address: Joi.object().allow({}, null).optional(),
    mobile_phone: Joi.string()
      .required()
      .custom(isValidPhone, "Téléphone mobile invalide"),
    landline_phone: Joi.string().allow(null, ""),
    citizenship: Joi.string().required(),
    birth_date: Joi.date().required(),
    birth_place: Joi.string().required(),
    birth_department: Joi.string().required(),
  };
  let LegalRepresentativeSpec = {
    ...AdminSpec,
    birth_country: Joi.required(),
    position: Joi.string()
      .required()
      .valid(
        "president",
        "director",
        "manager",
        "vice_president",
        "cfo",
        "liberal_profession",
        "retailer",
        "artisan",
      ),
    birth_date: Joi.date().required().max(maturityCutoffDate),
    us_person: Joi.boolean().falsy(null),
    position_other: Joi.string().allow(null, "").optional(),
    tax_residences: Joi.array().allow(null).optional()
  };

  const schema =
    personType === interlocutorType.ADMIN
      ? Joi.object(AdminSpec)
      : personType === interlocutorType.LEGAL_REPRESENTATIVE
      ? Joi.object(LegalRepresentativeSpec)
      : null;

  const { error } = schema.validate(obj);
  return error
    ? {
      name: error.details[0].path[0],
      sub: {
        //usefull for nif and country
        index: error.details[0].path[1],
        name: error.details[0].path[2],
      },
      type: error.details[0].type,
      message:
        error.details[0].type === "date.max"
          ? `Vous devez être majeur(e) pour souscrire à Cashbee Pro`
          : error.details[0].type === "date.base"
          ? "Cela ne ressemble pas à une date valide"
          : null,
    }
    : null;
}

export default validateForm;
