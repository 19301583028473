import React from "react";
import DocumentUploaded from "../DocumentUploaded";
import DocumentUploaderZone from "../DocumentUploaderZone";

const DocumentUploader = ({
  folder,
  subFolder,
  owner_id,
  documentType,
  currentItem,
  errorClass,
}) => {
  return currentItem ? (
    <DocumentUploaded
      folder={folder}
      subFolder={subFolder}
      owner_id={owner_id}
      documentType={documentType}
      currentItem={currentItem}
    />
  ) : (
    <DocumentUploaderZone
      folder={folder}
      subFolder={subFolder}
      owner_id={owner_id}
      documentType={documentType}
      errorClass={errorClass}
    />
  );
};

export default DocumentUploader;
