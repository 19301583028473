import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";
import { date_iso2ui, date_ui2iso } from "shared/utilities";

const BirthdayInput = ({ value, onChange, disabled, errorMessage }) => {
  return (
    <NumberFormat
      customInput={TextField}
      format="##/##/####"
      placeholder="JJ/MM/AAAA"
      mask={["J", "J", "M", "M", "A", "A", "A", "A"]}
      //
      value={date_iso2ui(value)}
      //type={type}
      // onChange={onChange}
      onValueChange={(values) => {
        const { formattedValue } = values;
        onChange(date_ui2iso(formattedValue));
      }}
      disabled={disabled}
      //
      variant="outlined"
      size="small"
      fullWidth
      helperText={errorMessage}
      error={!!errorMessage}
    />
  );
};
const Birthday = ({ value }) => {
  return (
    <NumberFormat
      format="##/##/####"
      displayType={"text"}
      value={date_iso2ui(value)}
    />
  );
};
export { Birthday, BirthdayInput };
