import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "addresses",
  initialState: {
    fieldNames: {
      street: { label: "Adresse" },
      second_line: { label: "Complément d’adresse", optional: true },
      postcode: { label: "Code postal" },
      city: { label: "Ville" },
      country: { label: "Pays", type: "country" },
      // territory: { label: "Département" },//TODO
      // mobile_phone: { label: "Téléphone" },
    },
  },
  reducers: {},
});

// export const {} = slice.actions;
export default slice.reducer;
