import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { history } from "store";

import { Button, GlobalErrorMessage } from "shared/components";
import DocumentUploader from "./DocumentUploader";
import EditDocumentAdding from "./EditDocumentAdding";
import {
  DocumentFolder,
  DocumentSubFolder,
  DocumentType,
  enum_individual_info_greffe,
  enum_individual_tax_returns,
} from "shared/enums/DocumentsTypes";
import DocumentsList from "./DocumentsList";
import { WrapMainForm } from "Funnel/Components/WrapPage";

import "./documents.scss";

const CompanyIndividualDocs = ({ isUpdate }) => {
  const { docs, moreUploadNeeded } = useSelector(
    (store) => store.dashboard.documents.company_individual
  );
  const [edited, setEdited] = useState(null);
  const [notCompleted, setNotCompleted] = useState(false);
  useEffect(() => setNotCompleted(false), [moreUploadNeeded]);

  const handleValidate = async () => {
    if (!moreUploadNeeded) {
      if (isUpdate) {
        history.goBack();
      } else {
        history.push("/representative-documents");
      }
    } else {
      setNotCompleted(true);
    }
  };

  return (
    <div className="funnelPgW">
      <div className={`funnelPgContent ${edited ? "rightFormEdited" : ""}`}>
        <div className="funnelPgL">
          <WrapMainForm>
            {!isUpdate && <div className="sectionName">4/4 — Documents</div>}
            <div className="formW">
              <div className="formH">
                <div className="goBack" onClick={() => history.goBack()}>
                  <i className="icon-arrow-back" />
                </div>
                <div className="formTitle">
                  {DocumentFolder.company_individual.label}
                </div>
              </div>

              <div>
                <div className="docZone">
                  <div className="docTitle">
                    {DocumentSubFolder.info_greffe.label}
                  </div>
                  <DocumentsList
                    folder={DocumentFolder.company_individual.key}
                    subFolder={DocumentSubFolder.info_greffe.key}
                    list={docs.info_greffe}
                    onAddingClick={() => {
                      setEdited(DocumentSubFolder.info_greffe.key);
                    }}
                    active={edited === DocumentSubFolder.info_greffe.key}
                    errorClass={notCompleted && docs.info_greffe.length === 0}
                  />
                </div>

                <div className="docZone">
                  <div className="docTitle">
                    {DocumentSubFolder.tax_returns.label}
                  </div>
                  <DocumentsList
                    folder={DocumentFolder.company_individual.key}
                    subFolder={DocumentSubFolder.tax_returns.key}
                    list={docs.tax_returns}
                    onAddingClick={() => {
                      setEdited(DocumentSubFolder.tax_returns.key);
                    }}
                    active={edited === DocumentSubFolder.tax_returns.key}
                    errorClass={notCompleted && docs.tax_returns.length === 0}
                  />
                </div>

                <div className="docZone">
                  <div className="docTitle">RIB</div>
                  <DocumentUploader
                    folder={DocumentFolder.company_individual.key}
                    documentType={DocumentType.rib.key}
                    currentItem={docs.rib}
                    errorClass={notCompleted && !docs.rib}
                  />
                </div>

                <div className="docZone">
                  <div className="docTitle">Origine des fonds</div>
                  <div className="docDescription">
                    Pour tout virement de 200 000€ ou plus, vous devez fournir un relevé de compte ou une attestation
                    de solde.
                  </div>
                  <DocumentUploader
                    folder={DocumentFolder.company_individual.key}
                    documentType={DocumentType.funds_origin.key}
                    currentItem={docs.funds_origin}
                  />
                </div>

                {notCompleted && (
                  <div className="docZone">
                    <GlobalErrorMessage>
                      Vous devez ajouter au moins un fichier par document
                      demandé.
                    </GlobalErrorMessage>
                  </div>
                )}

                <Button onClick={handleValidate} variant="contained" primary>
                  {isUpdate ? "Enregistrer" : "Suivant"}
                </Button>
              </div>
            </div>
          </WrapMainForm>
        </div>
        <div className="funnelPgR">
          {edited === DocumentSubFolder.info_greffe.key && (
            <EditDocumentAdding
              title={DocumentSubFolder.info_greffe.label}
              folder={DocumentFolder.company_individual.key}
              subFolder={DocumentSubFolder.info_greffe.key}
              selectEnum={enum_individual_info_greffe}
              close={() => setEdited(null)}
            />
          )}
          {edited === DocumentSubFolder.tax_returns.key && (
            <EditDocumentAdding
              title={DocumentSubFolder.tax_returns.label}
              folder={DocumentFolder.company_individual.key}
              subFolder={DocumentSubFolder.tax_returns.key}
              selectEnum={enum_individual_tax_returns}
              close={() => setEdited(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyIndividualDocs;
