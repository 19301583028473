import React from 'react';
import apiClient from "shared/API/apiClient";
import Button from "@mui/material/Button";
import { CFCAL_SLUG, MMB_SLUG, WORMSER_SLUG } from '../../../config.json'
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const openFile = async (provideSlug, jwt) => {
  const { data } = await apiClient.get(`documents/${provideSlug}/deposit_certificate`, { token: jwt.token });

  setTimeout(() => {
    window.open(data.url, '_blank').focus();
  })
}

const DownloadButton = styled(Button)((props) => ({
  backgroundColor: props.variant === 'outlined' ? '#fff' : '#222226',
  borderColor: '#222226',
  textTransform: 'none',
  fontFamily: [
    "sans-serif",
    "Arial",
    "Helvetica",
    "Roboto",
    'GT Zirkon',
  ],
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: props.variant === 'outlined' ? '#EBEBEB' : '#373843',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: props.variant === 'outlined' ? '#EBEBEB' : '#373843',
  },
}));

const CERTIFICATES = [
  {
    label: 'Attestation de placement MyMoneyBank',
    slug: MMB_SLUG,
  },
  {
    label: 'Attestation de placement Banque Wormser Frères',
    slug: WORMSER_SLUG
  },
  {
    label: 'Attestation de placement CFCAL',
    slug: CFCAL_SLUG
  }
]

const ContractsDocuments = ({ jwt }) => {
  const { totalActiveNumbers } = useSelector((store) => store.dashboard.accounts)
  const certificateToDisplay = CERTIFICATES.filter((e) => totalActiveNumbers[e.slug] > 0)
  if (certificateToDisplay.length === 0) {
    return false;
  }
  return (
    <div className="cPartW">
      <div className="cPartH">
        <div className="cPartTitle">Documents liés à vos contrats</div>
      </div>
      <div className="cPartW">
        <div className="cPartBdy">
          {certificateToDisplay.map((certificate) => (
            <div className="cPartI">
              <div className="cPartILabel">
                {certificate.label}
              </div>
              <div>
                <DownloadButton
                  size="small"
                  variant="contained"
                  onClick={() => openFile(certificate.slug, jwt)}
                >
                  Télécharger
                </DownloadButton>
              </div>
            </div>)
          )}
        </div>
      </div>
    </div>
  );
};

export default ContractsDocuments;
