import { combineReducers } from "redux";
import description from "./description";
import representants from "./representants";
import benificiaries from "./benificiaries";
import documents from "./documents";

export default combineReducers({
  description,
  representants,
  benificiaries,
  documents,
});
