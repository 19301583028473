import { createSlice } from "@reduxjs/toolkit";
import { computeTotalFunds } from "shared/utilities";
import { userLoggedOut } from "store/reducers/auth";
import { apiCall } from "store/api";

const emptyAccountInProcedure = {
  id: null,
  amount: "",
  maturity: "",
  status: "pending",
  number: 1,
};

const initialState = {
  fromServer: null,
  apiPostErrors: null,

  existingAccountNumber: 0,
  totalNumber: 0,
  totalAmount: 0, //Useful for UI

  //Formalities
  bankingSecret: false, //CheckBox
  provider_slug: null,
  onlyFrance: false, //CheckBox
  notFinancialCompany: false, //CheckBox
  fund_origin: "",
  fund_origin_other: "",
  url: null,

  //
  list: [],

  //Handling the content when editing an account to add
  edited: null,
  // edited : is equal to when editing new item
  // {
  //   initValue: null,
  //   currValue: null,
  // },
  //
  companyValidation: null,
  // {
  //   completed: boolean,
  //   valid: boolean,
  //   message: errorMessage,
  // }
}

const slice = createSlice({
  name: "procedure",
  initialState,
  reducers: {
    startAdding: (state, action) => {
      const currValue = { ...emptyAccountInProcedure };
      state.edited = {
        initValue: currValue,
        currValue, //the same reference at the begining
      };
    },
    startEditing: (state, action) => {
      const currValue = action.payload.account;
      state.edited = {
        initValue: currValue,
        currValue, //the same reference at the begining
      };
    },
    closeEditing: (state, action) => {
      state.edited = null;
    },
    updateEdited: (state, action) => {
      state.edited.currValue = action.payload.account;
    },
    saveAccount: (state, action) => {
      const curr = state.edited.currValue;

      if (curr.id === null) {
        //adding new one
        const id = state.list.length + 1;
        state.list.push({ ...curr, id });
        //Update GUI
        state.totalNumber += 1;
      } else {
        //editing existing one
        state.list = state.list.map((x) => (x.id === curr.id ? curr : x));
      }
      //UPDATE GUI
      state.totalAmount = computeTotalFunds(state.list);
      state.edited = null;
    },
    removeAccount: (state, action) => {
      const curr = state.edited.currValue;
      if (curr.id !== null) {
        const nbAccounts = state.list.reduce((a, b) => {
          if (b.id === curr.id) return a + b.number
          return a
        }, 0)
        state.list = state.list.filter((x) => x.id !== curr.id);
        //Update GUI
        state.totalNumber -= nbAccounts;
      }
      //UPDATE GUI
      state.totalAmount = computeTotalFunds(state.list);
      state.edited = null;
    },
    incrementInstance: (state, action) => {
      const id = action.payload.id;

      state.list = state.list.map((x) => {
        if (x.id !== id) return x;
        else {
          //Update GUI
          state.totalNumber += 1;
          //
          return { ...x, number: x.number + 1 };
        }
      });

      //UPDATE GUI
      state.totalAmount = computeTotalFunds(state.list);
    },
    decrementInstance: (state, action) => {
      const id = action.payload.id;

      state.list = state.list.map((x) => {
        if (x.id === id) {
          //Update GUI
          state.totalNumber -= 1;
          //
          return { ...x, number: x.number - 1 };
        } else return x;
      }).filter((x) => {
        return !(x.id === id && x.number === 0);
      });

      //UPDATE GUI
      state.totalAmount = computeTotalFunds(state.list);
    },
    setFormalities: (state, action) => {
      const {
        bankingSecret,
        onlyFrance,
        notFinancialCompany,
        fund_origin,
        fund_origin_other,
      } = action.payload;
      state.onlyFrance = onlyFrance;
      state.notFinancialCompany = notFinancialCompany;
      state.bankingSecret = bankingSecret;
      state.fund_origin = fund_origin;
      state.fund_origin_other = fund_origin_other;
    },
    setProvider: (state, action) => {
      const { providerSlug } = action.payload;
      state.providerSlug = providerSlug;
    },
    procedurePosted: (state, { payload }) => {
      state.fromServer = {
        id: payload.id,
        amount: payload.amount,
        status: payload.status,
        receiver: payload.receiver,
        transfer: payload.transfer,
      };
    },
    procedurePostFailed: (state, { payload }) => {
      state.error422 = payload.error422;
      state.apiPostErrors = payload.message;
    },
    cleanApiErrors: (state, action) => {
      state.apiPostErrors = null;
      state.companyValidation = null;
    },

    companyValidated: (state, action) => {
      state.companyValidation = { completed: true, valid: true, message: null };
    },
    companyValidationFailed: (state, { payload }) => {
      state.companyValidation = {
        completed: true,
        valid: false,
        message: payload.message,
      };
    },

    apiProcedureLoaded: (state, { payload }) => {
      state.fromServer = payload;
    },

    signatureLink: (state, { payload }) => {
      state.url = payload.url;
    },
    resetLink: (state, action) => {
      state.url = null;
    },

    clearProcedure: (state, action) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers: {
    initDashboard: (state, { payload }) => {
      state.fromServer = payload.procedure; // {id: 11, amount: '130000.0', status: 'pending_signature'}
    },
  },
  [userLoggedOut.type]: (state, { payload }) => {
    state = null;
  },
});

export const loadProcedure = ({ id }) =>
  apiCall({
    url: `/procedures/${id}`,
    method: "get",
    onSuccess: slice.actions.apiProcedureLoaded.type,
    // onError: slice.actions.procedureLoadingFailed.type,
  });

export const procedureSignature = ({ providerSlug }) =>
  apiCall({
    url: `/procedures/signatures`,
    method: "post",
    data: { provider_slug: providerSlug },
    onSuccess: slice.actions.signatureLink.type,
  });

export const apiProcedurePost = ({ data, successLink }) =>
  apiCall({
    url: "/procedures",
    method: "post",
    data,
    successLink,
    onSuccess: slice.actions.procedurePosted.type,
    onError: slice.actions.procedurePostFailed.type,
  });

export const apiCompanyValidation = ({ successLink }) =>
  apiCall({
    url: "/company/validate",
    method: "PUT",
    successLink,
    onSuccess: slice.actions.companyValidated.type,
    onError: slice.actions.companyValidationFailed.type,
  });

export const {
  startAdding,
  startEditing,
  closeEditing,
  updateEdited,
  saveAccount,
  removeAccount,
  incrementInstance,
  decrementInstance,
  setFormalities,
  procedurePosted,
  cleanApiErrors,
  setProvider,
  resetLink,
  clearProcedure
} = slice.actions;
export default slice.reducer;
