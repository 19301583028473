import React from "react";
import { useSelector } from "react-redux";
import { errorMessage } from "shared/utilities";
import TaxResidencesList from "shared/components/TaxResidencesList";
import {
  BirthdayInput,
  CheckBox,
  CitizenshipInput,
  CountryInput,
  DepartmentInput,
  GlobalErrorMessage,
  GooglePlacesAutocomplete,
  HalfLineField,
  PercentageInput,
  PersonCivilityInput,
  TextInput,
} from "shared/components";

const BeneficiaryEditBody = ({
  badField, //if not filled well : we show errors one by one
  beneficiary,
  onStateChange,
  //noInputsUpLabel,// true if we want to remove labels on a gray background for example
}) => {
  const [state, setState] = React.useState(beneficiary);
  const { apiErrorMessage, error422 } = useSelector(
    (store) => store.ui.company.benificiaries
  );

  React.useEffect(() => setState(beneficiary), [beneficiary]);

  return (
    <React.Fragment>
      <PersonCivilityInput
        civility={state.civility}
        onChecked={(v) => {
          const newSt = { ...state, civility: v };
          setState(newSt);
          onStateChange(newSt);
        }}
        errorMessage={errorMessage("civility", badField, error422)}
      />
      <TextInput
        label="Nom"
        type="text"
        name="last_name"
        value={state.last_name}
        onChange={(e) => {
          const newSt = { ...state, last_name: e.target.value };
          setState(newSt);
          onStateChange(newSt);
        }}
        errorMessage={errorMessage("last_name", badField, error422)}
      />
      <TextInput
        label="Prénom"
        type="text"
        name="first_name"
        value={state.first_name}
        onChange={(e) => {
          const newSt = { ...state, first_name: e.target.value };
          setState(newSt);
          onStateChange(newSt);
        }}
        errorMessage={errorMessage("first_name", badField, error422)}
      />
      <CitizenshipInput
        label="Nationalité"
        name="citizenship"
        value={state.citizenship}
        onChange={(v) => {
          const newSt = { ...state, citizenship: v };
          setState(newSt);
          onStateChange(newSt);
        }}
        errorMessage={errorMessage("citizenship", badField, error422)}
      />

      <GooglePlacesAutocomplete
        label="Adresse de résidence"
        type="json_address"
        name="address"
        value={state.address}
        onChange={(v) => {
          const newSt = { ...state, address: v };
          setState(newSt);
          onStateChange(newSt);
        }}
        errorMessage={errorMessage("address", badField, error422)}
      />

      <HalfLineField label="Né(e) le">
        <BirthdayInput
          name="birth_date"
          value={state.birth_date}
          onChange={(v) => {
            const newSt = { ...state, birth_date: v };
            setState(newSt);
            onStateChange(newSt);
          }}
          errorMessage={errorMessage("birth_date", badField, error422)}
        />
      </HalfLineField>
      <HalfLineField label="Né(e) à">
        <TextInput
          label="Ville"
          name="birth_place"
          type="text"
          value={state.birth_place}
          onChange={(e) => {
              const newSt = { ...state, birth_place: e.target.value };
              setState(newSt);
              onStateChange(newSt);
          }}
          errorMessage={errorMessage("birth_place", badField, error422)}
        />
      </HalfLineField>
      <HalfLineField label="→ Département" leftPercent={44}>
          <DepartmentInput
            label="Département"
            name="birth_department"
            value={state.birth_department}
            onChange={(v) => {
              const newSt = { ...state, birth_department: v };
              setState(newSt);
              onStateChange(newSt);
            }}
            errorMessage={errorMessage("birth_department", badField, error422)}
          />
      </HalfLineField>
      <HalfLineField label="→ Pays" leftPercent={25}>
          <CountryInput
              label="Pays"
              name="birth_country"
              value={state.birth_country}
              onChange={(v) => {
                  const newSt = { ...state, birth_country: v };
                  setState(newSt);
                  onStateChange(newSt);
              }}
              errorMessage={errorMessage("birth_country", badField, error422)}
          />
      </HalfLineField>

      <PercentageInput
        label="Pourcentage de détention"
        type="text"
        name="share_percent"
        value={state.share_percent}
        onChange={(v) => {
          const newSt = { ...state, share_percent: v };
          setState(newSt);
          onStateChange(newSt);
        }}
        errorMessage={errorMessage(
          "share_percent",
          badField,
          error422,
          badField && badField.message ? badField.message : null
        )}
      />

      <PercentageInput
        label="Pourcentage de droits de vote"
        type="text"
        name="voting_percent"
        value={state.voting_percent}
        onChange={(v) => {
          const newSt = { ...state, voting_percent: v };
          setState(newSt);
          onStateChange(newSt);
        }}
        errorMessage={errorMessage(
          "voting_percent",
          badField,
          error422,
          badField && badField.message ? badField.message : null
        )}
      />

      <TaxResidencesList
        list={state.tax_residences}
        onChange={(v) => {
          const newSt = { ...state, tax_residences: v };
          setState(newSt);
          onStateChange(newSt);
        }}
        badField={badField}
        error422={error422}
      />

      <CheckBox
        checked={state.us_person}
        onChange={(v) => {
          const newSt = { ...state, us_person: v };
          setState(newSt);
          onStateChange(newSt);
        }}
        label="Le bénéficiaire effectif est une personne américaine au sens de la réglementation FATCA"
      />

      {apiErrorMessage && (
        <GlobalErrorMessage>{apiErrorMessage}</GlobalErrorMessage>
      )}
    </React.Fragment>
  );
};

export default BeneficiaryEditBody;
