import React from "react";
import "./tax-residences.scss";
import { CountryInput, HalfLineField, TextInput } from "shared/components";
import { subErrorMessage } from "shared/utilities";

function TaxResidences({
                         item,
                         index,
                         onChange,
                         onRemove,
                         badField,
                         error422,
                       }) {
  return (
    <div className="txResidenceItem">
      <div className="txResidenceBdy">
        <CountryInput
          label="Pays"
          name="country"
          //noUpLabel
          value={item.country}
          onChange={(v) => onChange({ ...item, country: v })}
          errorMessage={subErrorMessage("country", index, badField, error422)}
        />
        {(item.country && item.country !== "FR") &&
        <HalfLineField
          label={
            <span>
              <span>→ Code NIF</span>(
              <a href="https://www.economie.gouv.fr/cedef/nif" target="_blank" rel="noreferrer">
                ?
              </a>
              )
            </span>
          }
          leftPercent={38}
        >
          <TextInput
            label="_"
            name="nif_number"
            type="text"
            noUpLabel={true}
            value={item.nif_number}
            onChange={(e) => onChange({ ...item, nif_number: e.target.value })}
            errorMessage={subErrorMessage(
              "nif_number",
              index,
              badField,
              error422
            )}
          />
        </HalfLineField>}
      </div>

      <div className="txResidenceClose" onClick={onRemove}>
        <i className="icon-delete-circle"/>
      </div>
    </div>
  );
}

export default TaxResidences;
