import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledButton =  styled(Button)((props) => ({
  backgroundColor: props.variant === 'outlined' ? '#fff' : '#222226' ,
  borderColor: '#222226',
  '&:hover': {
    backgroundColor: props.variant === 'outlined' ? '#EBEBEB' : '#373843' ,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: props.variant === 'outlined' ? '#EBEBEB' : '#373843' ,
  },
}));

function Btn({
  children,
  type,
  className,
  onClick,
  to,
  primary,
  disabled,
  autoFocus,
  fullWidth,
  href,
  target,
  style,
  color,
}) {
  return to ? (
    <Link to={to} disabled={disabled}>
      <Button
        className={className}
        type={type}
        disableElevation
        disabled={disabled}
        autoFocus={autoFocus}
        color={primary ? "primary" : "default"}
        variant={primary ? "contained" : "outlined"}
        size="large"
        fullWidth={fullWidth !== false}
        href={href}
        target={target}
      >
        {children}
      </Button>
    </Link>
  ) : (
    <StyledButton
      style={style}
      className={`btn40 ${className}`}
      disableElevation
      type={type}
      onClick={onClick}
      disabled={disabled}
      autoFocus={autoFocus}
      color={color || "primary"}
      variant={primary ? "contained" : "outlined"}
      size="large"
      fullWidth={fullWidth !== false}
      href={href}
      target={target}
    >
      {children}
    </StyledButton>
  );
}

export default Btn;
