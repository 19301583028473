import React from "react";
import { useSelector } from "react-redux";
import './filter.scss'
import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledChip =  styled(Chip)((props) => ({
  backgroundColor: props.active === 'true' ? '#222226' : '#DFDFE2',
  borderColor: '#DFDFE2',
  color: props.active === 'true' ? '#FFFFFF' : '#5B5C67',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  height: '40px',
  padding: '12px',
  '&:hover': {
    color: '#FFFFFF',
    backgroundColor: '#222226',
    borderColor: '#222226',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    color: '#FFFFFF',
    backgroundColor: '#222226',
    borderColor: '#222226',
  },
}));
function Filters({filter, onClick}) {
  const totalNumbers = useSelector((store) => store.dashboard.accounts.totalNumbers);
  const providers = useSelector((store) => store.dashboard.providers);
  const providerFilterOptions = providers.filter((p) => totalNumbers[p.slug] !== 0)

  if (providerFilterOptions.length < 2) { return null }

  return (
    <div className="filters">
      <StyledChip label="Tous mes comptes" active={(filter === null).toString()} onClick={() => onClick(null)} />
      {providerFilterOptions.map((p) => (<StyledChip active={(filter === p.slug).toString()} key={p.slug} label={p.short_name} onClick={() => onClick(p.slug)} />))}
    </div>
  );
}

export { Filters };
