import { createSlice } from "@reduxjs/toolkit";

const commonFieldNames = {
  civility: { label: "", hiddenOnSettingBoard: "true" },
  first_name: { label: "Prénom" },
  last_name: { label: "Nom" },
  email: { label: "Email", type: "email" },
  address: { label: "Adresse de résidence", type: "address", requireUbo: true },
  mobile_phone: { label: "Numéro de téléphone", type: "phone" },
  citizenship: { label: "Nationalité", type: "country" },
  birth_date: { label: "Date de naissance", type: "birthday" },
  birth_place: { label: "Ville de naissance" },
  birth_department: {
    label: "Département de naissance",
    type: "departmentSelect",
  },
};

const slice = createSlice({
  name: "representants",
  initialState: {
    interlocutorFieldNames: {
      ...commonFieldNames,
      position: {
        label: "Fonction dans l’entreprise",
        type: "text",
      },
    },
    representativeFieldNames: {
      ...commonFieldNames,
      birth_country: {
        label: "Pays de naissance",
        type: "country",
      },
      position: {
        label: "Fonction dans l’entreprise",
        type: "position",
        other: "position_other",
      },
    },
  },
  reducers: {},
});

// export const {  } = slice.actions;
export default slice.reducer;
