import React from "react";
import { Button } from "shared/components";
import "shared/styles/css/adding-button.scss";

//This button is used when currently adding account : when the adding form is edited
const ActiveAddingButton = () => {
  return (
    <div className="currently-adding">
      <span>Nouveau compte à terme</span>
      <i className="icon-chevron-right-s" />
    </div>
  );
};

//When we are not editing a form to add a new account
const AddingButton = ({ onClick, disabled }) => {
  return (
    <Button
      className="btn40"
      style={{ marginBottom: '10px' }}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="adding-btn-content">
        {!disabled && <>
          <span>Ajouter un compte à terme</span>
          <i className="icon-add-circle" />
        </>
        }
        {disabled &&
          <span>Nombre limite de comptes à terme atteint</span>
        }
      </div>
    </Button>
  );
};

export default AddingButton;
export { ActiveAddingButton };
