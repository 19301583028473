import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "../signature.scss";
import { Box, Collapse, IconButton } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { iban2format, Price, TextInput } from "../../../shared/components";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { CFCAL_SLUG, MMB_SLUG, WORMSER_SLUG } from "../../../config.json";

const CopyIcon = ({ value }) => {
  const notify = () =>
    toast.dark("Copié dans le presse-papiers", {
      toastId: 'copy-notif',
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <React.Fragment>
      <span
        className="icon-copy icon-dornment"
        onClick={() => {
          navigator.clipboard.writeText(value);
          notify();
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        transition={Zoom}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
    </React.Fragment>
  );
};

function SingleTransfer({ procedure }) {
  const account = procedure.accounts[0];
  const amount = procedure.accounts.reduce((acc, account) => acc + +account.amount, 0)

  return (<>
    <h3 className="signSecH">Vos comptes sont maintenant ouverts</h3>
    <div className="signSecBdy">
      Procédez au virement directement depuis votre compte bancaire vers l’IBAN spécifié ci-dessous.
    </div>
    <div style={{ gap: '24px', display: 'grid', marginTop: '24px' }}>
      <div className="account" key={account.id}>
        <div className={`amount ${procedure.provider.slug} oneAccount`}>
          <p>Montant</p>
          <h3 className="pAccAmount">
            <Price value={amount} />
          </h3>
        </div>


        <div>
          <div>
            <p style={{ marginBottom: '4px' }}>IBAN</p>
            <TextInput
              value={iban2format(account.iban)}
              endAdornment={<CopyIcon value={account.iban} />}
            />
          </div>
          <div className="onSameLine">
            <div>
              <p style={{ marginBottom: '4px' }}>Banque</p>
              <TextInput
                value={account.bank}
                endAdornment={<CopyIcon value={account.bank} />}
              />
            </div>
            <div>
              <p style={{ marginBottom: '4px' }}>BIC</p>
              <TextInput
                value={account.bic}
                endAdornment={<CopyIcon value={account.bic} />}
              />
            </div>
          </div>
        </div>
        <div style={{ opacity: '0.6', marginTop: '16px' }}>
          *La somme exacte doit être envoyée pour accepter le virement.
        </div>
      </div>
    </div>
    <div className="alert alert-warning alert-icon-i" style={{ marginTop: '24px' }}>
      Votre banque peut vous demander un justificatif pour initier le virement, dans ce cas, vous pouvez leur
      transmettre une copie de votre contrat reçu par mail.
    </div>
  </>);
}


function MultipleTransfers({ procedure }) {
  const [collapse, setCollapse] = React.useState(() => procedure.accounts.reduce((a, v) => ({
    ...a,
    [v.id]: false
  }), {}));
  const transfersNumber = procedure.accounts.length;
  return (
    <>
      <h3 className="signSecH">Vos comptes sont maintenant ouverts</h3>
      <div className="signSecBdy">
        Procédez aux virements directement depuis votre compte bancaire vers les IBANs spécifiés ci-dessous.
      </div>
      <div className="alert alert-warning alert-icon-i" style={{ marginTop: '24px' }}>
        Chaque compte à terme ouvert chez MyMoneyBank dispose de son propre IBAN et nécessite un virement individuel.
      </div>
      <div style={{ gap: '24px', display: 'grid' }}>
        {procedure.accounts.map((account, index) => (
          <Box className="account" key={account.id}>
            <div className="collapseHeader"
                 style={{ textAlign: 'center', alignItems: 'center', display: 'flex', gap: '16px' }}>
              <h4>Virement {index + 1} / {transfersNumber}</h4>

              <div style={{ marginLeft: 'auto' }}>
                <IconButton aria-label="expend"
                            onClick={() => setCollapse({ ...collapse, [account.id]: !collapse[account.id] })}>
                  <KeyboardArrowUpIcon size="small" sx={{
                    rotate: `${!collapse[account.id] ? '0' : '180deg'}`,
                    transition: 'rotate 500ms'
                  }} />
                </IconButton>
              </div>
            </div>
            <Collapse in={!collapse[account.id]}>

              <div className="collapseContent">
                <div className={`amount ${procedure.provider.slug}`}>
                  <p>Montant</p>
                  <h3 className="pAccAmount">
                    <Price value={account.amount} />
                  </h3>
                </div>


                <div>
                  <div>
                    <p style={{ marginBottom: '4px' }}>IBAN</p>
                    <TextInput
                      value={iban2format(account.iban)}
                      endAdornment={<CopyIcon value={account.iban} />}
                    />
                  </div>
                  <div className="onSameLine">
                    <div>
                      <p style={{ marginBottom: '4px' }}>Banque</p>
                      <TextInput
                        value={account.bank}
                        endAdornment={<CopyIcon value={account.bank} />}
                      />
                    </div>
                    <div>
                      <p style={{ marginBottom: '4px' }}>BIC</p>
                      <TextInput
                        value={account.bic}
                        endAdornment={<CopyIcon value={account.bic} />}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ opacity: '0.6' }}>
                  *La somme exacte doit être envoyée pour accepter le virement.
                </div>
              </div>
            </Collapse>
          </Box>
        ))}
      </div>
      <div className="alert alert-warning alert-icon-i" style={{ marginTop: '24px' }}>
        Votre banque peut vous demander un justificatif pour initier le virement, dans ce cas, vous pouvez leur
        transmettre une copie de votre contrat reçu par mail.
      </div>
    </>);
}

function Transfers({ procedure }) {
  const provider = procedure.provider;

  if (provider.slug === MMB_SLUG) {
    return <MultipleTransfers procedure={procedure} />
  } else if (provider.slug === WORMSER_SLUG) {
    return <SingleTransfer procedure={procedure} />
  } else if (provider.slug === CFCAL_SLUG) {
    return <SingleTransfer procedure={procedure} />
  }
}

export default Transfers;
