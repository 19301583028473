import { createSlice } from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";

const slice = createSlice({
  name: "navigation",
  initialState: {},
  extraReducers: {
    [LOCATION_CHANGE]: (state, { payload }) => {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: document.location.href,
          path: payload.location.pathname
        }
      });
    },
  },
});

export default slice.reducer;
