import React, { useCallback, useMemo } from "react";
import { apiBaseURL, MAX_FILE_SIZE } from "config";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import CryptoJS from "crypto-js";
import { DocumentFolder } from "shared/enums/DocumentsTypes";
import { Loading } from "shared/components";
import StaticLink from "shared/services/StaticLink";

const baseStyle = {
  border: "1px dashed #000",
  padding: "1rem", //25px;
  cursor: "pointer",
};

const activeStyle = {
  // borderColor: "#999",
  backgroundColor: "#fcfcfc",
  color: "#bdbdbd",
};
const errorStyle = {
  borderColor: "#A30000",
  color: "#A30000"
};

const remoteFolder = ({ folder, owner_id }) => {
  switch (folder) {
    case DocumentFolder.representative.key:
    case DocumentFolder.interlocutor.key:
      return `/${folder}/documents`;

    case DocumentFolder.beneficiaries.key:
      return `/${folder}/${owner_id}/documents`;

    case DocumentFolder.company_individual.key:
    case DocumentFolder.company_moral.key:
    default:
      return "/documents";
  }
};

async function calculateMd5(blob) {
  return new Promise((ok, ko) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      const hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(e.target.result));
      ok(hash.toString(CryptoJS.enc.Base64));
    };
    reader.readAsBinaryString(blob);
  });
}

async function uploadFile({ file, token, folder, documentType, owner_id }) {
  const { name, size, type } = file;

  const hash = await calculateMd5(file);

  const data1 = {
    filename: name,
    content_type: type,
    byte_size: size,
    checksum: hash,
  };

  const maxFileSize = MAX_FILE_SIZE ?? 25; //25Mo
  const mo = data1.byte_size / 1048576; //1024*1024
  if (mo > maxFileSize) {
    throw new RangeError(
      `Ce fichier est trop lourd. Maximum ${maxFileSize}Mo.`
    );
  }

  // APPEL 1 : Generates a direct upload URL

  const result1 = await axios({
    baseURL: apiBaseURL,
    headers: { Authorization: `Bearer ${token}` },
    method: "post",
    url: "/documents/direct_upload",
    data: data1,
  });

  const { signed_id, url: signedUrl } = result1.data;

  // APPEL 2 :

  try {
    await axios({
      headers: {
        "Content-Type": type,
        "Content-MD5": hash,
      },
      method: "PUT",
      url: signedUrl,
      data: file,
    });
  } catch (error) {
    throw error;
  }

  //APPEL 3 :
  let result3 = null;

  try {
    const data3 = {
      document_type: documentType,
      name: name,
      file: signed_id,
    };

    const url = remoteFolder({ folder, owner_id });

    result3 = await axios({
      baseURL: apiBaseURL,
      headers: { Authorization: `Bearer ${token}` },
      method: "post",
      url,
      data: data3,
    });
  } catch (error) {
    throw error;
  }

  return result3;
}

const DocumentUploaderZone = ({
  folder,
  owner_id,
  subFolder,
  documentType,
  onUploaded,
  errorClass,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(null);
  const dispatch = useDispatch();
  const { token } = useSelector((store) => store.auth.jwt);
  const onDrop = useCallback(async (acceptedFiles) => {
    let file = acceptedFiles[0];

    try {
      setLoading(true);
      const result = await uploadFile({
        file,
        token,
        folder,
        documentType,
        owner_id,
      });
      const name = result.data.name ?? result.data.filename;
      const payload = { ...result.data, folder, subFolder, name, owner_id };
      dispatch({
        type: "documentUploaded",
        payload,
      });
      if (onUploaded) onUploaded(payload);
    } catch (error) {
      if (error instanceof RangeError) {
        setLoadingError(error.message);
      } else if (error.response) {
        setLoadingError(
          error.response.data?.errors ?? error.response.statusText
        );
      } else {
        setLoadingError(
          <span>
            Oups, quelque chose n’a pas fonctionné lors de l’envoi des fichiers.
            Réessayez ou{" "}
            <StaticLink to="contactez_nous">
              contactez-nous
            </StaticLink>
            .
          </span>
        );
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, documentType, folder, onUploaded, owner_id, subFolder, token]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(errorClass ? errorStyle : {}),
    }),
    [isDragActive, errorClass]
  );

  return (
    <React.Fragment>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {loading && <Loading />}
          Déposer ici votre document ou{" "}
          <span style={{ textDecoration: "underline" }}>
            parcourir vos fichiers
          </span>
        </div>
      </div>
      {errorClass && (
        <div className="global-error-message">Vous devez ajouter ce document</div>
      )}
      {loadingError && (
        <div className="global-error-message">{loadingError}</div>
      )}
    </React.Fragment>
  );
};

export default DocumentUploaderZone;
