import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi";
import { Button, CheckBox, GlobalErrorMessage, TextInput } from "shared/components";
import WrapConnexionPage from "Funnel/Components/WrapConnexionPage";
import WrapForm from "./WrapForm";
import { errorMessage } from "shared/utilities";
import { apiRegister, setPartener } from "store/reducers/auth";
import StaticLink from "shared/services/StaticLink";

function getQueryVariables() {
  let invitation_token = null;
  let invitation_email = null;
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === "token") {
      invitation_token = decodeURIComponent(pair[1]);
    } else if (decodeURIComponent(pair[0]) === "email") {
      invitation_email = decodeURIComponent(pair[1]);
    }
  }
  return { invitation_token, invitation_email };
}

const Signup = ({ match }) => {
  const dispatch = useDispatch();
  const { ui } = useSelector((store) => store.auth);

  const partner_slug = match.params.partner_slug;
  const { invitation_token, invitation_email } = getQueryVariables();

  if (partner_slug && partner_slug.length > 0) {
    dispatch(setPartener({ partner: partner_slug }));
  }

  const [state, setState] = useState(
    invitation_email
      ? {
          password: "",
          okConditions: false,
        }
      : {
          email: "",
          password: "",
          okConditions: false,
        }
  );
  const [badField, setBadField] = useState(null);

  async function validateForm() {
    let joiObj = {
      password: Joi.string().required().min(8),
      okConditions: Joi.boolean().valid(true),
    };
    if (!invitation_email)
      joiObj = {
        ...joiObj,
        email: Joi.string()
          .required()
          .email({
            tlds: { allow: false },
          }),
      };
    const schema = Joi.object(joiObj);
    const { error } = schema.validate(state);
    return error
      ? { name: error.details[0].path[0], type: error.details[0].type }
      : null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = await validateForm();
    setBadField(error);

    if (!error) {
      dispatch(
        apiRegister({
          invitation_token,
          partner_slug,
          email: invitation_email ?? state.email,
          password: state.password,
        })
      );
    }
  };

  return (
    <WrapConnexionPage>
      <WrapForm title="Bienvenue sur Cashbee Pro">
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="formDsc">
            Entrez votre email professionnel et choisissez votre mot de passe.
          </div>

          {invitation_email && <TextInput value={invitation_email} disabled />}
          {!invitation_email && (
            <TextInput
              name="email"
              label="Email"
              type="email"
              value={state.email}
              onChange={(e) => setState({ ...state, email: e.target.value })}
              errorMessage={errorMessage(
                "email",
                badField,
                ui.registerError422,
                "Hmm, ça ne ressemble pas à un email valide"
              )}
            />
          )}

          <TextInput
            name="password"
            label="Mot de passe"
            type="password"
            autoComplete="current-password"
            value={state.password}
            onChange={(e) => setState({ ...state, password: e.target.value })}
            errorMessage={errorMessage(
              "password",
              badField,
              ui.registerError422,
              "8 caractères minimum"
            )}
          />

          {ui.registerApiError && (
            <GlobalErrorMessage>{ui.registerApiError}</GlobalErrorMessage>
          )}

          <Button type="submit" primary={true}>
            S’inscrire
          </Button>

          <CheckBox
            checked={state.okConditions}
            onChange={(v) => setState({ ...state, okConditions: v })}
            name="okConditions"
            label={
              <span>
                J’accepte les{" "}
                <StaticLink to="conditions_generales_utilisation">
                  conditions générales d’utilisation
                </StaticLink>
              </span>
            }
            errorMessage={
              badField && badField.name === "okConditions"
                ? "Vous devez accepter les conditions générales pour continuer"
                : null
            }
          />
        </form>
      </WrapForm>

      <div className="ousideFormLink">
        <Link to={partner_slug ? `/${partner_slug}/login` : "/login"}>
          J’ai déjà un compte
        </Link>
      </div>
    </WrapConnexionPage>
  );
};

export default Signup;
