import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
//
import api from "./middleware/api";
import authMiddleware from "./middleware/auth";

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const composeEnhancer =
    (process.env.NODE_ENV === "development" &&
      typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        api,
        authMiddleware
        // ... other middlewares ...
      )
    )
  );

  return store;
}
