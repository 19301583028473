import React from "react";
import { useSelector } from "react-redux";
import { history } from "store";
import SubPart from "./SubPart";

function EditCompanyDescription({ pathName }) {
  const storeState = useSelector((store) => store.dashboard.company);
  const editable = useSelector((store) => store.dashboard.editable);
  const ui = useSelector((store) => store.ui.company.description);

  return (
    <React.Fragment>
      <SubPart
        title="Identification"
        state={{ ...storeState.identification.val, active_company: !storeState.identification.val.active_company }}
        fieldNames={ui.identification.fieldNames}
        editable={editable}
        onEdit={() => history.push("/identification/update")}
      />

      <SubPart
        title="Adresse du siège"
        state={storeState.addresses.set.siege.address}
        fieldNames={ui.addresses.fieldNames}
        editable={editable}
        onEdit={() => history.push(`/main-address/update?h=${pathName}`)}
      />

      {!storeState.addresses.set.mailing.neverUploaded && (
        <SubPart
          title="Adresse de correspondance"
          state={storeState.addresses.set.mailing.address}
          fieldNames={ui.addresses.fieldNames}
          editable={editable}
          onEdit={() =>
            history.push(`/correspondence-address/update?h=${pathName}`)
          }
        />
      )}

      <SubPart
        title="IBAN"
        state={storeState.iban.payload}
        fieldNames={ui.iban.fieldNames}
        editable={editable}
        onEdit={() => history.push("/iban/update")}
      />
    </React.Fragment>
  );
}

export default EditCompanyDescription;
