import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiDeleteAdmin, apiSaveAdmin, clear, } from "store/reducers/dashboard/representants";
import { history } from "store";
import validateForm from "./validateForm";
import { ADMIN } from "./interlocutorType";
import queryString from "query-string";
import { ConfirmDialog } from "shared/components/Dialog";

import WrapPage from "Funnel/Components/WrapPage";
//
import { Button, DataCollapsible, GlobalErrorMessage } from "shared/components";
//
import InterlocutorBody from "./InterlocutorBody";

function AdminEdit({ match, location }) {
  const dispatch = useDispatch();
  const [badField, setBadField] = useState(null);

  const dashboard = useSelector((store) => store.dashboard);
  const person = dashboard.representants;

  const administrator = person.administrator;
  const { apiPostErrors, error422 } = administrator;
  const { first_name, last_name } = person.legal.body;
  const gerantName = `${first_name} ${last_name}`;

  const [state, setState] = useState(administrator);
  const [showConfirm, setShowConfirm] = useState();
  useEffect(() => dispatch(clear()), [dispatch]);

  const isUpdate = match.params.action === "update";
  const { h: pageBack } = queryString.parse(location.search);

  const handleDelete = () => {
    dispatch(apiDeleteAdmin({ successLink: pageBack }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = await validateForm(state.body, ADMIN,true);
    setBadField(error);
    if (!error) {
      //After submit success go to:
      const successLink = isUpdate
        ? pageBack //If comming from dashboard for updating
        : "/benificiaries"; //Creation and mast complete representative

      dispatch(
        apiSaveAdmin({
          data: state.body,
          successLink,
          neverUploaded: administrator.neverUploaded,
        })
      );
    }
  };

  return (
    <WrapPage>
      {!isUpdate && <div className="sectionName">2/4 — Interlocuteurs</div>}
      <div className="formW">
        <div className="formH">
          <div className="goBack" onClick={() => history.goBack()}>
            <i className="icon-arrow-back" />
          </div>
          <div className="formTitle">Administrateur du compte</div>
        </div>

        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <InterlocutorBody
            badField={badField}
            error422={error422}
            body={state.body}
            interlocutorType="interlocutor"
            onBodyChange={(body) => {
              setState({ ...state, body });
            }}
          />

          {apiPostErrors && (
            <GlobalErrorMessage>{apiPostErrors}</GlobalErrorMessage>
          )}

          {!isUpdate && (
            <Button type="submit" variant="contained" primary>
              Suivant
            </Button>
          )}
          {isUpdate && (
            <div className="buttonsLst">
              <Button variant="outlined" onClick={() => setShowConfirm(true)}>
                Supprimer
              </Button>
              <Button
                type="submit"
                variant="contained"
                primary
                disabled={state === administrator}
              >
                Enregistrer
              </Button>
            </div>
          )}
          {!isUpdate && <DataCollapsible />}
        </form>
      </div>
      {/* IF REMOVING CLICK */}
      {showConfirm && (
        <ConfirmDialog
          open={showConfirm}
          title="Attention"
          message={
            <div>
              Étes vous certain(e) de vouloir supprimer l’administrateur du
              compte ? Le représentant légal <b>{gerantName}</b> deviendra donc
              le seul administrateur du compte.
            </div>
          }
          onOk={() => {
            handleDelete();
            setShowConfirm(false);
          }}
          onCancel={() => setShowConfirm(false)}
        ></ConfirmDialog>
      )}
    </WrapPage>
  );
}

export default AdminEdit;
