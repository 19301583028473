import React from "react";

function Maturity({ months }) {
  const y = Math.floor(months / 12);
  const m = months % 12;
  let s = `${y} an${y > 1 ? "s" : ""}`;
  if (m > 0) {
    s = y > 0 ? `${s} et ${m} mois` : `${m} mois`;
  }
  return <React.Fragment>{s}</React.Fragment>;
}

export default Maturity;
