import React from "react";

//label on the first 25% of line, and component on the 75% of the line
function HalfLineField({ label, children, leftPercent }) {
  const left = leftPercent ?? 25;
  const right = 100 - left;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: `${left}%`,
        }}
      >
        {label}
      </div>
      <div
        style={{
          width: `${right}%`,
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default HalfLineField;
