import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiCompanyPut, siegeUpdated } from "store/reducers/dashboard/company/addresses";
import AddressBody from "./AddressBody";
//
import { Button, CheckBox } from "shared/components";
import { history } from "store";
import validateForm from "./validateForm";
import { getCompanyData } from "shared/utilities";
import { apiCompanyPost } from "store/reducers/dashboard/company/iban";
import { ConfirmDialog } from "shared/components/Dialog";
import DeleteErrorMessage from "./DeleteErrorMessage";

import WrapPage from "Funnel/Components/WrapPage";

const MainAddress = ({ match, location }) => {
  const dispatch = useDispatch();

  const company = useSelector((store) => store.dashboard.company);
  const error422 = company.iban.error422;
  const init = company.addresses.set.siege;
  const [state, setState] = useState(init);
  const [hasEmailingAddress, setHasEmailingAddress] = useState(
    !company.addresses.set.mailing.neverUploaded
  );
  const [badField, setBadField] = useState(null);
  const [showConfirm, setShowConfirm] = useState();

  const isUpdate = match.params.action === "update";

  const handleSubmitWithoutMailingAddress = () => {
    const data = {
      ...getCompanyData(company),
      head_office_address: state.address,
      mail_address: null,
    };
    dispatch(
      apiCompanyPost({
        data,
        successLink: "previous",
        neverUploaded: false,
      })
    );
  };
  const handleSubmitKeepSameMailingAddress = (isUpdate) => {
    const data = {
      ...getCompanyData(company),
      head_office_address: state.address,
    };
    if (isUpdate) {
      dispatch(
        apiCompanyPut({
          data,
          successLink: "previous",
          neverUploaded: company.addresses.set.mailing.neverUploaded,
        })
      );

    } else {
      dispatch(
        apiCompanyPost({
          data,
          successLink: "previous",
          neverUploaded: company.addresses.set.mailing.neverUploaded,
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = await validateForm(state.address);
    setBadField(error);

    if (!error) {
      if (
        !isUpdate ||
        (hasEmailingAddress && company.addresses.set.mailing.neverUploaded)
      ) {
        dispatch(siegeUpdated({ state }));
        history.push(
          isUpdate
            ? `/correspondence-address/update${location.search}`
            : hasEmailingAddress
            ? "/correspondence-address"
            : "/iban"
        );
      } else {
        //isUpdate && (!hasEmailingAdress || neverUploaded)

        if (
          !hasEmailingAddress &&
          !company.addresses.set.mailing.neverUploaded
        ) {
          setShowConfirm(true);
        } else {
          handleSubmitKeepSameMailingAddress(isUpdate);
        }
      }
    }
  };

  return (
    <WrapPage>
      {!isUpdate && <div className="sectionName">1/4 — Entreprise</div>}
      <div className="formW">
        <div className="formH">
          <div className="goBack" onClick={() => history.goBack()}>
            <i className="icon-arrow-back"/>
          </div>
          <div className="formTitle">Adresse du siège</div>
        </div>

        <form onSubmit={handleSubmit} noValidate autoComplete="places">
          <AddressBody
            badField={badField}
            error422={error422}
            address={state.address}
            onAddressChange={(address) => setState({ ...state, address })}
          />

          <Button type="submit" variant="contained" primary>
            {isUpdate ? "Enregistrer" : "Suivant"}
          </Button>

          <CheckBox
            checked={hasEmailingAddress}
            onChange={(v) => setHasEmailingAddress(v)}
            name="checkedB"
            label="Utiliser une adresse différente pour la correspondance avec Cashbee"
          />
        </form>
      </div>

      {/* IF REMOVING CLICK */}
      {showConfirm && (
        <ConfirmDialog
          open={showConfirm}
          title="Attention"
          message={<DeleteErrorMessage/>}
          onOk={() => {
            handleSubmitWithoutMailingAddress();
            setShowConfirm(false);
          }}
          onCancel={() => setShowConfirm(false)}
        ></ConfirmDialog>
      )}
    </WrapPage>
  );
};

export default MainAddress;
