import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi";
import { errorMessage, maturityAsInt } from "shared/utilities";
import { closeEditing, removeAccount, saveAccount, updateEdited, } from "store/reducers/dashboard/procedure";
import { Button, Maturity, Price, PriceInput, Rendement, RendementActuarial, SelectInput, } from "shared/components";

async function validateForm(obj, procedureValidation, maxForThisAccount) {
  const schema = Joi.object({
    amount: Joi.number().required().min(procedureValidation.minimum_per_account).max(maxForThisAccount),
    maturity: Joi.string().required(),
  });
  const { error } = schema.validate(obj);
  return error
    ? {
      name: error.details[0].path[0],
      type: error.details[0].type,
      message:
        error.details[0].type === "number.min" ? (
          <span>
             Minimum <Price value={procedureValidation.minimum_per_account} />
          </span>
        ) : error.details[0].type === "number.max" ? (
          <span>
             Maximum <Price value={procedureValidation.maximum_per_company} />
          </span>
        ) : null,
    }
    : null;
}

const EditControleButtons = ({ account }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeEditing());
  };

  const handleRemoveAndClose = () => {
    dispatch(removeAccount());
  };

  if (account.id === null) {
    return (
      <div className="eFBtsGp">
        <Button variant="outlined" onClick={handleClose}>
          Annuler
        </Button>
        <Button type="submit" variant="contained" primary>
          Ajouter
        </Button>
      </div>
    );
  } else {
    return (
      <div className="eFBtsGp">
        <Button variant="outlined" onClick={handleRemoveAndClose}>
          Supprimer
        </Button>
        <Button type="submit" variant="contained" primary>
          Enregistrer
        </Button>
      </div>
    );
  }
};

const EditAccount = ({ account, rateGrids, provider }) => {
  const dispatch = useDispatch();

  const updateState = (account) => dispatch(updateEdited({ account }));
  const [badField, setBadField] = useState(null);

  const dashboard = useSelector((store) => store.dashboard);
  const maxForThisAccount =
    provider.procedure_validation.maximum_per_company -
    dashboard.accounts.totalActiveAmounts[provider.slug];

  const error422 = null;
  const providerRateGrids = rateGrids.reduce((acc, rates) => {
      acc[rates.nominal_maturity] = (<Maturity months={maturityAsInt(rates.nominal_maturity)} />)
      return acc;
    }, {}
  )
  const amountError = !!errorMessage("amount", badField, error422, badField && badField.message)
  const handleSaveAndClose = async (e) => {
    e.preventDefault();

    const error = await validateForm(
      {
        amount: account.amount,
        maturity: account.maturity,
      },
      provider.procedure_validation,
      maxForThisAccount
    );

    setBadField(error);
    if (!error) {
      dispatch(saveAccount());
    }
  };

  return (
    <div className="editFW">
      <div className="eFH">
        <div className="eFHTitle">Nouveau compte</div>
        <div className="eFHCmd" onClick={() => dispatch(closeEditing())}>
          <i className="icon-close" />
        </div>
      </div>

      <form
        onSubmit={handleSaveAndClose}
        className="eFBdy"
        noValidate
        autoComplete="off"
      >
        <PriceInput
          label="Montant à placer"
          name="amount"
          value={account.amount}
          onChange={(v) => {
            updateState({ ...account, amount: v });
          }}
          error={amountError}
        />
        <p
          style={{
            opacity: amountError ? 1.0 : 0.6,
            marginTop: '4px',
            color: amountError ? '#A30000' : '#222226'
          }}
        >
          { !amountError && <>Minimum <Price value={provider.procedure_validation.minimum_per_account} /></>}
          { amountError && errorMessage("amount", badField, error422, badField && badField.message) }
        </p>

        <SelectInput
          label="Échéance du placement"
          name="maturity"
          value={account.maturity}
          style={{ marginBottom: '24px' }}
          onChange={(v) => updateState({ ...account, maturity: v })}
          lst={providerRateGrids}
          errorMessage={errorMessage("maturity", badField, error422)}
        />

        {account.maturity && account.amount && (
          <div className="rendementW" style={{ marginBottom: '24px' }}>
            <div className="rendement">
              Taux actuariel brut : <Rendement maturity={account.maturity} rateGrids={rateGrids} />
            </div>
            <div>
              Soit un rendement actuariel de{" "}
              <RendementActuarial
                rateGrids={rateGrids}
                amount={account.amount}
                maturity={account.maturity}
              />{" "}
              au terme.
            </div>
          </div>
        )}

        <EditControleButtons account={account} />
      </form>
    </div>
  );
};

export default EditAccount;
