import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { logout } from "store/reducers/auth";

import { apiBaseURL } from "config";
import { Button, Loading, SelectInput } from "shared/components";
import DocumentUploaderZone from "../DocumentUploaderZone";
import DocumentUploaded from "../DocumentUploaded";
import ConfirmFirst from "shared/components/Dialog/ConfirmFirst";

const MoreInformation = ({ folder, subFolder, documentType }) => {
  if (documentType === "k_bis") {
    return (
      <React.Fragment>
        <div className="global-error-message">
          Attention, le KBIS doit dater de moins de trois mois.
        </div>
        <a
          href="https://www.service-public.fr/professionnels-entreprises/vosdroits/F21000"
          target="_blank"
          rel="noreferrer"
          style={{ fontSize: "14px", lineHeight: "16.8px" }}
        >
          Où trouver son extrait KBIS ?
        </a>
      </React.Fragment>
    );
  } else if (documentType === "artisan_registry") {
    return (
      <React.Fragment>
        <a
          href="https://www.service-public.fr/professionnels-entreprises/vosdroits/R57049"
          target="_blank"
          rel="noreferrer"
          style={{ fontSize: "14px", lineHeight: "16.8px" }}
        >
          Où trouver son extrait d’inscription au répertoire des métiers ?
        </a>
      </React.Fragment>
    );
  } else if (documentType === "company_registry") {
    return (
      <React.Fragment>
        <div className="global-error-message">
          Attention, ce document doit dater de moins de 3 mois.
        </div>
        <a
          href="https://entreprendre.service-public.fr/vosdroits/R19859"
          target="_blank"
          rel="noreferrer"
          style={{ fontSize: "14px", lineHeight: "16.8px" }}
        >
          Comment obtenir un extrait du registre national des entreprises?
        </a>
      </React.Fragment>
    );
  } else if (documentType === "siren") {
    return (
      <React.Fragment>
        <div className="global-error-message">
          Attention, ce document doit dater de moins de 3 mois.
        </div>
        <a
          href="https://www.service-public.fr/professionnels-entreprises/vosdroits/R17969"
          target="_blank"
          rel="noreferrer"
          style={{ fontSize: "14px", lineHeight: "16.8px" }}
        >
          Où trouver son avis de situation au répertoire SIREN ?
        </a>
      </React.Fragment>
    );
  } else if (documentType === "declaration_2035") {
    return (
      <React.Fragment>
        <div className="global-error-message">
          Attention, vous devez ajouter vos deux dernières déclarations 2035.
        </div>
        <a href="https://help.cashbee.fr/article/172-ou-trouver-les-documents-demandes"
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: "14px", lineHeight: "16.8px" }}>
          Où trouver sa déclaration 2035 ?
        </a>
      </React.Fragment>
    );
  } else if (documentType === "tax_return") {
    return (
      <React.Fragment>
        <a href="https://help.cashbee.fr/article/172-ou-trouver-les-documents-demandes"
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: "14px", lineHeight: "16.8px" }}>
          Où trouver sa liasse fiscale ?
        </a>
      </React.Fragment>
    );
  } else return null;
};

export default function EditDocumentAdding({
  title,
  folder,
  subFolder,
  owner_id,
  selectEnum,
  close,
}) {
  const [docType, setDocType] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const dispatch = useDispatch();
  const jwt = useSelector((store) => store.auth.jwt);

  const removeAndClose = async () => {
    const { documentType, id } = uploaded;
    if (!jwt || !jwt.token) dispatch(logout());

    try {
      setLoading(true);
      const result = await axios({
        baseURL: apiBaseURL,
        headers: { Authorization: `Bearer ${jwt.token}` },
        method: "DELETE",
        url: `/documents/${uploaded.id}`,
      });
      if (result.status < 300) {
        dispatch({
          type: "documentDeleted",
          payload: { folder, subFolder, owner_id, documentType, id },
        });
      }
    } catch (error) {
      //TODO
      console.log(error);
    } finally {
      setLoading(false);
      close();
    }
  };

  const tryRemoveAndClose = () => {
    if (uploaded) {
      setConfirm({
        title: "Attention",
        message: "Étes vous certain(e) de vouloir supprimer ce document ?",
        onConfirm: () => {
          removeAndClose();
        },
      });
    } else {
      close();
    }
  };

  return (
    <div className="funnelSubForm">
      {loading && <Loading />}
      <div className="formW">
        <div className="formH">
          <div className="formTitle">{title}</div>
          <div onClick={tryRemoveAndClose}>
            <i className="icon-close" />
          </div>
        </div>

        <form noValidate autoComplete="off">
          <SelectInput
            label="Type de document"
            name="document_type"
            value={docType}
            onChange={(v) => setDocType(v)}
            lst={selectEnum}
            disabled={uploaded !== null}
          />

          {docType.length > 0 &&
            (uploaded ? (
              <DocumentUploaded
                folder={folder}
                subFolder={subFolder}
                owner_id={owner_id}
                documentType={docType}
                currentItem={uploaded}
                onDeleted={() => setUploaded(null)}
              />
            ) : (
              <DocumentUploaderZone
                folder={folder}
                subFolder={subFolder}
                owner_id={owner_id}
                documentType={docType}
                onUploaded={(v) => {
                  setUploaded(v);
                }}
              />
            ))}

          <MoreInformation
            folder={folder}
            subFolder={subFolder}
            documentType={docType}
          />

          <div className="buttonsLst">
            {uploaded ? (
              <Button variant="outlined" onClick={tryRemoveAndClose}>
                Supprimer
              </Button>
            ) : (
              <Button variant="outlined" onClick={tryRemoveAndClose}>
                Annuler
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              primary
              onClick={close}
              disabled={!uploaded}
            >
              Ajouter
            </Button>
          </div>
        </form>
        <ConfirmFirst payload={confirm} close={() => setConfirm(null)} />
      </div>
    </div>
  );
}
