export const PENDING = "pending";
export const ACTIVE = "active";
export const CLOSED = "closed";
export const FINISHED = "finished";

// const CLOSING = "closing";
// const CLOSURE_RECIEVED = "closure_recieved";
//const FINISHED = "finished";

////// ALL POSSIBLE STATES ///////
//PENDING
//ACTIVE : can mean active (closing or not closing)
//ACTIVE_ACTIVE
//ACTIVE_CLOSING
//CLOSED : can mean closed (closing but can no more be canceled or totally closed)
//CLOSED_CLOSING
//CLOSED_CLOSED
//
export const IS_PENDING = (account) => {
  return account.status === PENDING;
};
//
export const IS_ACTIVE_ACTIVE = (account) => {
  return (
    account.status === ACTIVE && !(account.closure && account.closure.status)
  );
};
export const IS_ACTIVE_CLOSING = (account) => {
  return (
    account.status === ACTIVE &&
    account.closure &&
    (account.closure.status === "pending" ||
      account.closure.status === "pending_signature")
  );
};

export const IS_CLOSING_ACCEPTED = (account) => {
  return (
    account.status === ACTIVE && account.closure && account.closure.status === "accepted"
  );
};
export const IS_ACTIVE = (account) => {
  return IS_ACTIVE_ACTIVE(account) || IS_ACTIVE_CLOSING(account);
};
//
export const IS_CLOSED_CLOSED = (account) => {
  return (
    account.status === CLOSED
  );
};
export const IS_FINISHED = (account) => {
  return account.status === FINISHED;
};
export const IS_CLOSED_CLOSING = (account) => {
  return (
    account.status === ACTIVE &&
    account.closure &&
    (account.closure.status === "signed" ||
      account.closure.status === "pending_submission" ||
      account.closure.status === "submitted")
  );
};
