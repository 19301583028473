import React from "react";
import LogosZone from "shared/components/LogosZone";

export const WrapMainForm = ({ children }) => {
  return (
    <div className="funnelMainForm">
      <div className="formZone">{children}</div>
      <LogosZone />
    </div>
  );
};

function WrapPage({ children }) {
  return (
    <div className="funnelPgW">
      <div className="funnelPgContent">
        <div className="funnelPgL">
          <WrapMainForm>{children}</WrapMainForm>
        </div>
        <div className="funnelPgR"></div>
      </div>
    </div>
  );
}

export default WrapPage;
