import React from "react";

function WrapForm({ title, children }) {
  return (
    <div className="formW">
      <div className="formH">
        <div className="formTitle">{title}</div>
      </div>
      {children}
    </div>
  );
}

export default WrapForm;
