import React from "react";
import { Price } from "shared/components";
import "./header-summary.scss";

function HeaderSummary({ totalFunds, errors }) {
  if (!totalFunds) return null; //we do not show header if no funds

  return (
    <React.Fragment>
      <p className={`${errors ? '' : 'no-'}errors total-title`}>TOTAL</p>
      <h2 className={`${errors ? '' : 'no-'}errors fundsW`}>
        <Price value={totalFunds} />
      </h2>
    </React.Fragment>
  );
}

export default HeaderSummary;
