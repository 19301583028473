import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearProcedure, setProvider, startAdding } from "store/reducers/dashboard/procedure";
import { history } from "store";

import EditAccount from "./EditAccount";

import HeaderSummary from "Accounts/Components/HeaderSummary";
import AccountsList from "./AccountsList";
import CommandButtons from "./CommandButtons";
import { computeTotalRendement } from "shared/utilities";
import { Button, Price } from "../../../shared/components";
import { useParams } from "react-router-dom";


const AccountsAddingForProvider = ({ provider }) => {
  const dispatch = useDispatch();
  const dashboard = useSelector((store) => store.dashboard);
  const { procedure: { totalAmount, edited, list } } = dashboard;

  const { rate_grids, procedure_validation } = provider;
  const totalRendement = computeTotalRendement(rate_grids, list);

  React.useEffect(() => {
    function handlePopState(e) {
      dispatch(clearProcedure(null))
    }

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(setProvider({ providerSlug: provider.slug }));
    if (!list || !list.length) dispatch(startAdding());
  }, [dispatch, list, provider.slug]);

  const leftColomnClass = `dshMain minHeight grey ${
    edited !== null ? "rightFormEdited" : ""
  }`;

  const [bigActiveFund, setBigActiveFund] = React.useState(false);
  const [minProcedureAmount, setMinProcedureAmount] = React.useState(false);

  const totalProcAmount = dashboard.procedure.totalAmount;
  const totalActiveAmount = dashboard.accounts.totalActiveAmounts[provider.slug];
  const totalActive = totalProcAmount + totalActiveAmount;
  React.useEffect(() => setBigActiveFund(false), [totalActive]);
  React.useEffect(() => setMinProcedureAmount(false), [totalActive]);

  const submitAccounts = () => {
    if (totalActive > procedure_validation.maximum_per_company) setBigActiveFund(true);
    else if (dashboard.procedure.totalAmount < procedure_validation.minimum_per_procedure) setMinProcedureAmount(true)
    else history.push("/formalities");
  };

  return (
    <div className="bigImgWrapper">
      <div className="deskWrapper">
        <div className="pageContent">
          <div className={leftColomnClass}>
            <div className="goBackHeader">
              <div className="goBack" onClick={() => {
                dispatch(clearProcedure(null))
                history.goBack()
              }}>
                <i className="icon-arrow-back" />
              </div>
              <div className="formTitle">Ajout de compte(s) {provider.long_name}</div>
            </div>
            <div className="dMBdyW justifyTop">
              <div className="alert alert-warning alert-icon-i">
                {procedure_validation.information}
              </div>
              <div className="dMBdy">
                <HeaderSummary
                  totalFunds={totalAmount}
                  totalYield={totalRendement}
                  errors={bigActiveFund || minProcedureAmount}
                />
                {bigActiveFund && (
                  <div className="alert alert-danger alert-icon-danger">
                    Le total de vos avoirs ne doit pas excéder{" "}
                    <Price value={procedure_validation.maximum_per_company} />
                  </div>
                )}
                {minProcedureAmount && (
                  <div className="alert alert-danger alert-icon-danger">
                    Le dépôt minimum par demande d’ouverture est de{" "}
                    <Price value={procedure_validation.minimum_per_procedure} />
                  </div>
                )}
                <AccountsList
                  accounts={list}
                  rateGrids={rate_grids}
                  providerSlug={provider.slug}
                  maxAccountsNumber={procedure_validation.maximum_accounts}
                />
                <CommandButtons
                  provider={provider}
                  maxAccountsNumber={procedure_validation.maximum_accounts}
                />
              </div>
            </div>
            {list.length > 0 && (
              <Button className="validateProcedureButton" onClick={submitAccounts} primary
                      disabled={bigActiveFund || minProcedureAmount}>
                Valider mes placements
              </Button>
            )}
          </div>
          <div className="dshSub">
            {edited !== null && <EditAccount account={edited.currValue} rateGrids={rate_grids} provider={provider} />}
          </div>
        </div>
      </div>
    </div>
  );
}

function AccountsAdding() {
  const { providerSlug } = useParams();
  const provider = useSelector((store) => store.dashboard.providers.find((e) => e.slug === providerSlug))

  if (!provider) {
    return null
  }

  return <AccountsAddingForProvider provider={provider} />
}

export default AccountsAdding;
