import React, { useEffect, useState } from "react";
import DocumentUploaded from "../DocumentUploaded";
import DocAddButton from "./DocAddButton";
import "./doc-list.scss";

const DocumentsList = ({
  folder,
  subFolder,
  owner_id,
  list,
  onAddingClick,
  active,
  errorClass,
}) => {
  const [localList, setLocalList] = useState(list);
  useEffect(() => setLocalList(list), [list]); //do not update the list when the adding is active (the right window is visible)

  const handleDeleted = (id) => {
    setLocalList(localList.filter((x) => x.id !== id));
  };
  return (
    <div className="doc-list-w">
      {localList.map((x, i) => (
        <DocumentUploaded
          key={i}
          folder={folder}
          subFolder={subFolder}
          owner_id = {owner_id}
          currentItem={x}
          onDeleted={handleDeleted}
        />
      ))}

      {active ? (
        <div className="inactiveAddingButton">
          <span>Document {localList.length + 1}</span>
          <span className="icon-chevron-right" />
        </div>
      ) : (
        <DocAddButton onClick={onAddingClick} errorClass={errorClass} />
      )}
    </div>
  );
};
export default DocumentsList;
