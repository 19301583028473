import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { history } from "store";
import { Button } from "shared/components";
import SubPart from "./SubPart";

function EditCompanyBenificiaries() {
  const storeState = useSelector((store) => store.dashboard.benificiaries.list);
  const ui = useSelector((store) => store.ui.company.benificiaries);
  const [state, setState] = useState(storeState);

  useEffect(() => {
    setState(storeState);
  }, [storeState]);

  const number = state.length;
  const maxNumber = ui.maxNumber;

  return (
    <React.Fragment>
      {state.map((x, i) => (
        <SubPart
          key={i}
          title={`Bénéficiaire ${i + 1}`}
          state={x}
          fieldNames={ui.fieldNames}
          onEdit={() => history.push(`/beneficiary-edit/${x.id}`)}
        />
      ))}

      {number < maxNumber && (
        <Button
          variant="outlined"
          style={{marginBottom: '10px'}}
          onClick={() => history.push("/beneficiary-edit/add")}
        >
          <span>
            Ajouter un bénéficiaire
          </span>
          {" "}
          <i className="icon-add-circle" style={{ marginLeft: '4px' }} />
        </Button>
      )}
    </React.Fragment>
  );
}

export default EditCompanyBenificiaries;
