import React from "react";
import { Button } from "shared/components";
import Dialog, { DialogActions, DialogContent, DialogTitle } from "./Dialog";
import css from "./dialog.module.scss";

function ConfirmDialog({
  open,
  title,
  message,
  onOk,
  onCancel,
  okLabel, //optional
  cancelLabel, //optional
}) {
  return (
    <Dialog open={open} onClose={onCancel} className={css.confirmation}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelLabel ?? "Annuler"}</Button>
        <Button onClick={onOk} primary>
          {okLabel ?? "Continuer"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
