import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";

function Price({ value, style, className }) {
  return (
    <span style={{ ...style, whiteSpace: "nowrap" }} className={className}>
      <NumberFormat
        value={parseFloat(value)}
        displayType={"text"}
        thousandSeparator={" "}
        decimalSeparator={","}
        decimalScale={2}
        fixedDecimalScale={true}
        suffix={"€"}
      />
    </span>
  );
}

function PriceInput({
  label,
  value,
  onChange,
  noUpLabel,
  disabled,
  error,
  helperText,
}) {
  return (
    <NumberFormat
      customInput={TextField}
      value={value}
      isNumericString={true}
      thousandSeparator={" "}
      decimalSeparator={","}
      decimalScale={2}
      fixedDecimalScale={true}
      suffix={"€"}
      label={noUpLabel ? "" : label}
      placeholder={noUpLabel ? label : ""}
      onValueChange={(values) => {
        const { value: newValue } = values;
        if (newValue !== value) onChange(parseFloat(newValue));
      }}
      disabled={disabled}

      variant="outlined"
      size="small"
      fullWidth
      helperText={helperText}
      error={error}
    />
  );
}

export { Price, PriceInput };
