import { createSlice } from "@reduxjs/toolkit";
import { apiCall } from "store/api";
import { userLoggedOut } from "store/reducers/auth";

// const mock = {
//   iban: "FR1420041010050500013M02606",
//   bic: "CCBPFRPPVER",
// };
const empty = {
  iban: "",
  bic: "",
};
const slice = createSlice({
  name: "iban",
  initialState: {
    payload: { ...empty },
    //neverUploaded: false,
    //apiPostErrors:false
  },
  reducers: {
    loadDashboard: (state, { payload }) => {},
    ibanUpdated: (state, action) => {
      state.payload = action.payload.state;
    },
    companyPostFailed: (state, { payload }) => {
      state.error422 = payload.error422;
      state.apiPostErrors = payload.message;
    },
    clearUi: (state, { payload }) => {
      state.error422 = null;
      state.apiPostErrors = null;
    },
  },
  extraReducers: {
    initDashboard: (state, { payload }) => {
      if (payload.company !== null) {
        state.payload.iban = payload.company.iban;
        state.payload.bic = payload.company.bic;
      }
      state.neverUploaded = !payload.company;
    },
    updatedCompany: (state, { payload }) => {
      state.payload.iban = payload.iban;
      state.payload.bic = payload.bic;
      state.neverUploaded = false;
    },
    [userLoggedOut.type]: (state, { payload }) => {
      state = null;
    },
  },
});

export const apiCompanyPost = ({ data, neverUploaded, successLink }) =>
  apiCall({
    url: "/company",
    method: neverUploaded ? "post" : "put",
    data,
    successLink,
    onSuccess: "updatedCompany",
    onError: slice.actions.companyPostFailed.type,
  });

export const { ibanUpdated, clearUi } = slice.actions;
export default slice.reducer;
