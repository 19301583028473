import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import dashboard from "./dashboard";
import ui from "./ui";
import auth from "./auth";
import navigation from "./navigation";

import { logout } from "store/reducers/auth";

const createRootReducer = (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    dashboard,
    ui,
    navigation,
    auth,
  });

  return (state, action) => {
    if (action.type === logout.type) {
      return appReducer(undefined, action);
    }
    return appReducer(state, action);
  };
};

export default createRootReducer;
