const apeCodes = [
  { code: "0111Z", label: `Cult céréale, légumineuse, graine oléag.` },
  { code: "0112Z", label: `Culture du riz` },
  { code: "0113Z", label: `Cult. légume, melon, racine & tubercule` },
  { code: "0114Z", label: `Culture de la canne à sucre` },
  { code: "0115Z", label: `Culture du tabac` },
  { code: "0116Z", label: `Culture de plantes à fibres` },
  { code: "0119Z", label: `Autres cultures non permanentes` },
  { code: "0121Z", label: `Culture de la vigne` },
  { code: "0122Z", label: `Culture fruits tropicaux et subtropicaux` },
  { code: "0123Z", label: `Culture d'agrumes` },
  { code: "0124Z", label: `Culture de fruits à pépins et à noyau` },
  { code: "0125Z", label: `Cult. d'aut. fruits & de fruits à coque` },
  { code: "0126Z", label: `Culture de fruits oléagineux` },
  { code: "0127Z", label: `Culture de plantes à boissons` },
  { code: "0128Z", label: `Cult. plante aromatiq. médicin. pharma.` },
  { code: "0129Z", label: `Autres cultures permanentes` },
  { code: "0130Z", label: `Reproduction de plantes` },
  { code: "0141Z", label: `Élevage de vaches laitières` },
  { code: "0142Z", label: `Élevage d'autres bovins et de buffles` },
  { code: "0143Z", label: `Élevage de chevaux et d'autres équidés` },
  { code: "0144Z", label: `Élevage de chameaux & d'autres camélidés` },
  { code: "0145Z", label: `Élevage d'ovins et de caprins` },
  { code: "0146Z", label: `Élevage de porcins` },
  { code: "0147Z", label: `Élevage de volailles` },
  { code: "0149Z", label: `Élevage d'autres animaux` },
  { code: "0150Z", label: `Culture et élevage associés` },
  { code: "0161Z", label: `Activités de soutien aux cultures` },
  { code: "0162Z", label: `Activités de soutien à la prod. animale` },
  { code: "0163Z", label: `Traitement primaire des récoltes` },
  { code: "0164Z", label: `Traitement des semences` },
  { code: "0170Z", label: `Chasse, piégeage et services annexes` },
  { code: "0210Z", label: `Sylviculture & autres act. forestières` },
  { code: "0220Z", label: `Exploitation forestière` },
  { code: "0230Z", label: `Récolte prodts forestiers non ligneux` },
  { code: "0240Z", label: `Services de soutien à l'expl. forestière` },
  { code: "0311Z", label: `Pêche en mer` },
  { code: "0312Z", label: `Pêche en eau douce` },
  { code: "0321Z", label: `Aquaculture en mer` },
  { code: "0322Z", label: `Aquaculture en eau douce` },
  { code: "0510Z", label: `Extraction de houille` },
  { code: "0520Z", label: `Extraction de lignite` },
  { code: "0610Z", label: `Extraction de pétrole brut` },
  { code: "0620Z", label: `Extraction de gaz naturel` },
  { code: "0710Z", label: `Extraction de minerais de fer` },
  { code: "0721Z", label: `Extr. de minerais d'uranium & de thorium` },
  { code: "0729Z", label: `Extr. aut. minerai de métaux non ferreux` },
  { code: "0811Z", label: `Extr. pierre ornement. & construct. etc.` },
  { code: "0812Z", label: `Exploit. gravière & sabl., extr. argile` },
  { code: "0891Z", label: `Extr. minéraux chimiq. & engrais min.` },
  { code: "0892Z", label: `Extraction de tourbe` },
  { code: "0893Z", label: `Production de sel` },
  { code: "0899Z", label: `Autres activités extractives n.c.a.` },
  { code: "0910Z", label: `Act. de soutien à l'extr. hydrocarbures` },
  { code: "0990Z", label: `Act. de soutien aut. indus. extractives` },
  { code: "1011Z", label: `Transf. & conserv. viande de boucherie` },
  { code: "1012Z", label: `Transf. & conserv. de viande de volaille` },
  { code: "1013A", label: `Prépa. indust. produits à base de viande` },
  { code: "1013B", label: `Charcuterie` },
  { code: "1020Z", label: `Transf. & conserv. poisson, crust., etc.` },
  { code: "1031Z", label: `Transf. et conserv. de pommes de terre` },
  { code: "1032Z", label: `Préparation de jus de fruits et légumes` },
  { code: "1039A", label: `Autre transf. et conserv. de légumes` },
  { code: "1039B", label: `Transformation et conservation de fruits` },
  { code: "1041A", label: `Fabrication d'huiles et graisses brutes` },
  { code: "1041B", label: `Fab. d'huiles et graisses raffinées` },
  { code: "1042Z", label: `Fab. de margarine & graisses similaires` },
  { code: "1051A", label: `Fab. de lait liquide & de produits frais` },
  { code: "1051B", label: `Fabrication de beurre` },
  { code: "1051C", label: `Fabrication de fromage` },
  { code: "1051D", label: `Fabrication d'autres produits laitiers` },
  { code: "1052Z", label: `Fabrication de glaces et sorbets` },
  { code: "1061A", label: `Meunerie` },
  { code: "1061B", label: `Autres activités du travail des grains` },
  { code: "1062Z", label: `Fabrication de produits amylacés` },
  { code: "1071A", label: `Fab. indus. de pain & pâtisserie fraîche` },
  { code: "1071B", label: `Cuisson de produits de boulangerie` },
  { code: "1071C", label: `Boulangerie et boulangerie-pâtisserie` },
  { code: "1071D", label: `Pâtisserie` },
  { code: "1072Z", label: `Fab. pain, biscuit & pâtiss. de conserv.` },
  { code: "1073Z", label: `Fabrication de pâtes alimentaires` },
  { code: "1081Z", label: `Fabrication de sucre` },
  { code: "1082Z", label: `Fabric. de cacao, chocolat & confiseries` },
  { code: "1083Z", label: `Transformation du thé et du café` },
  { code: "1084Z", label: `Fabric. de condiments et assaisonnements` },
  { code: "1085Z", label: `Fabrication de plats préparés` },
  { code: "1086Z", label: `Fab. d'aliment homogénéisé & diététique` },
  { code: "1089Z", label: `Fab. d'autres prod. alimentaires n.c.a.` },
  { code: "1091Z", label: `Fabric. d'aliments pour animaux de ferme` },
  { code: "1092Z", label: `Fab. aliments pour animaux de compagnie` },
  { code: "1101Z", label: `Prod. de boissons alcooliques distillées` },
  { code: "1102A", label: `Fabrication de vins effervescents` },
  { code: "1102B", label: `Vinification` },
  { code: "1103Z", label: `Fabrication de cidre & de vins de fruits` },
  { code: "1104Z", label: `Prod. aut. boisson fermentée non distil.` },
  { code: "1105Z", label: `Fabrication de bière` },
  { code: "1106Z", label: `Fabrication de malt` },
  { code: "1107A", label: `Industrie des eaux de table` },
  { code: "1107B", label: `Production de boissons rafraîchissantes` },
  { code: "1200Z", label: `Fabrication de produits à base de tabac` },
  { code: "1310Z", label: `Prépa. de fibres textiles et filature` },
  { code: "1320Z", label: `Tissage` },
  { code: "1330Z", label: `Ennoblissement textile` },
  { code: "1391Z", label: `Fabrication d'étoffes à mailles` },
  { code: "1392Z", label: `Fab. d'article textile, sauf habillement` },
  { code: "1393Z", label: `Fabrication de tapis et moquettes` },
  { code: "1394Z", label: `Fabric. de ficelles, cordes et filets` },
  { code: "1395Z", label: `Fabric. de non-tissés, sauf habillement` },
  { code: "1396Z", label: `Fab. autre textile techniq. & industriel` },
  { code: "1399Z", label: `Fabrication d'autres textiles n.c.a.` },
  { code: "1411Z", label: `Fabrication de vêtements en cuir` },
  { code: "1412Z", label: `Fabrication de vêtements de travail` },
  { code: "1413Z", label: `Fabrication de vêtements de dessus` },
  { code: "1414Z", label: `Fabrication de vêtements de dessous` },
  { code: "1419Z", label: `Fabric. autres vêtements et accessoires` },
  { code: "1420Z", label: `Fabrication d'articles en fourrure` },
  { code: "1431Z", label: `Fabric. d'articles chaussants à mailles` },
  { code: "1439Z", label: `Fabrication d'autres articles à mailles` },
  { code: "1511Z", label: `Prépa. cuirs; prép. & teinture fourrures` },
  { code: "1512Z", label: `Fab. art. voyage, maroquin., & sellerie` },
  { code: "1520Z", label: `Fabrication de chaussures` },
  { code: "1610A", label: `Sciage & rabotage bois, sf imprégnation` },
  { code: "1610B", label: `Imprégnation du bois` },
  { code: "1621Z", label: `Fabric. placage et panneaux de bois` },
  { code: "1622Z", label: `Fabrication de parquets assemblés` },
  { code: "1623Z", label: `Fab. charpentes et autres menuiseries` },
  { code: "1624Z", label: `Fabrication d'emballages en bois` },
  { code: "1629Z", label: `Fab. objet div. bois, liège, vann., etc.` },
  { code: "1711Z", label: `Fabrication de pâte à papier` },
  { code: "1712Z", label: `Fabrication de papier et de carton` },
  { code: "1721A", label: `Fabrication de carton ondulé` },
  { code: "1721B", label: `Fabrication de cartonnages` },
  { code: "1721C", label: `Fabrication d'emballages en papier` },
  { code: "1722Z", label: `Fab. article papier sanit. ou domestique` },
  { code: "1723Z", label: `Fabrication d'articles de papeterie` },
  { code: "1724Z", label: `Fabrication de papiers peints` },
  { code: "1729Z", label: `Fab. aut. article en papier ou en carton` },
  { code: "1811Z", label: `Imprimerie de journaux` },
  { code: "1812Z", label: `Autre imprimerie (labeur)` },
  { code: "1813Z", label: `Activités de pré-presse` },
  { code: "1814Z", label: `Reliure et activités connexes` },
  { code: "1820Z", label: `Reproduction d'enregistrements` },
  { code: "1910Z", label: `Cokéfaction` },
  { code: "1920Z", label: `Raffinage du pétrole` },
  { code: "2011Z", label: `Fabrication de gaz industriels` },
  { code: "2012Z", label: `Fabrication de colorants et de pigments` },
  { code: "2013A", label: `Enrichissment & retrait. mat. nucléaire` },
  { code: "2013B", label: `Fab. aut. prod. chim. inorg. base n.c.a.` },
  { code: "2014Z", label: `Fab. aut. prod. chimique org. de base` },
  { code: "2015Z", label: `Fabric. de produits azotés et d'engrais` },
  { code: "2016Z", label: `Fabric. de matières plastiques de base` },
  { code: "2017Z", label: `Fabrication de caoutchouc synthétique` },
  { code: "2020Z", label: `Fab. pesticide & aut. prod. agrochimique` },
  { code: "2030Z", label: `Fab. de peinture, vernis, encre & mastic` },
  { code: "2041Z", label: `Fab. savon, détergent & prod. entretien` },
  { code: "2042Z", label: `Fab. parfum & produit pour la toilette` },
  { code: "2051Z", label: `Fabrication de produits explosifs` },
  { code: "2052Z", label: `Fabrication de colles` },
  { code: "2053Z", label: `Fabrication d'huiles essentielles` },
  { code: "2059Z", label: `Fabric. autres produits chimiques n.c.a.` },
  { code: "2060Z", label: `Fab. fibre artificielle ou synthétique` },
  { code: "2110Z", label: `Fab. de produits pharmaceutiques de base` },
  { code: "2120Z", label: `Fabric. de préparations pharmaceutiques` },
  { code: "2211Z", label: `Fabrication et rechapage de pneumatiques` },
  { code: "2219Z", label: `Fabric. d'autres articles en caoutchouc` },
  { code: "2221Z", label: `Fab. plaque, feuille, tube, etc. plast.` },
  { code: "2222Z", label: `Fab. d'emballage en matière plastique` },
  { code: "2223Z", label: `Fab. élément mat. plastiq. pr construct.` },
  { code: "2229A", label: `Fab. pièce techniq. base mat. plastiq.` },
  { code: "2229B", label: `Fab. prod. conso. courante en plastique` },
  { code: "2311Z", label: `Fabrication de verre plat` },
  { code: "2312Z", label: `Façonnage & transformation du verre plat` },
  { code: "2313Z", label: `Fabrication de verre creux` },
  { code: "2314Z", label: `Fabrication de fibres de verre` },
  { code: "2319Z", label: `Fab. & façonnage aut. article en verre` },
  { code: "2320Z", label: `Fabrication de produits réfractaires` },
  { code: "2331Z", label: `Fabrication de carreaux en céramique` },
  { code: "2332Z", label: `Fab. produit construct. en terre cuite` },
  { code: "2341Z", label: `Fab. art. céramiq. usage domest. & déco.` },
  { code: "2342Z", label: `Fab. appareil sanitaire en céramique` },
  { code: "2343Z", label: `Fab. isolateur & pièce isolante céramiq.` },
  { code: "2344Z", label: `Fab. aut. prod. céram. à usage technique` },
  { code: "2349Z", label: `Fabrication d'autres produits céramiques` },
  { code: "2351Z", label: `Fabrication de ciment` },
  { code: "2352Z", label: `Fabrication de chaux et plâtre` },
  { code: "2361Z", label: `Fab. élément en béton pour la construct.` },
  { code: "2362Z", label: `Fab. élément en plâtre pour la construc.` },
  { code: "2363Z", label: `Fabrication de béton prêt à l'emploi` },
  { code: "2364Z", label: `Fabrication de mortiers et bétons secs` },
  { code: "2365Z", label: `Fabrication d'ouvrages en fibre-ciment` },
  { code: "2369Z", label: `Fab. aut. ouvrage béton, ciment, plâtre` },
  { code: "2370Z", label: `Taille, façonnage & finissage de pierres` },
  { code: "2391Z", label: `Fabrication de produits abrasifs` },
  { code: "2399Z", label: `Fab. aut. prod. minéraux non métal. nca.` },
  { code: "2410Z", label: `Sidérurgie` },
  { code: "2420Z", label: `Fab. tube, profilé creux etc. en acier` },
  { code: "2431Z", label: `Étirage à froid de barres` },
  { code: "2432Z", label: `Laminage à froid de feuillards` },
  { code: "2433Z", label: `Profilage à froid par formage ou pliage` },
  { code: "2434Z", label: `Tréfilage à froid` },
  { code: "2441Z", label: `Production de métaux précieux` },
  { code: "2442Z", label: `Métallurgie de l'aluminium` },
  { code: "2443Z", label: `Métallurgie du Pb, du Zn ou du Sn` },
  { code: "2444Z", label: `Métallurgie du cuivre` },
  { code: "2445Z", label: `Métallurgie autres métaux non ferreux` },
  { code: "2446Z", label: `Élaboration et transform. mat. nucléaire` },
  { code: "2451Z", label: `Fonderie de fonte` },
  { code: "2452Z", label: `Fonderie d'acier` },
  { code: "2453Z", label: `Fonderie de métaux légers` },
  { code: "2454Z", label: `Fonderie d'autres métaux non ferreux` },
  { code: "2511Z", label: `Fab. structure métal. & partie structure` },
  { code: "2512Z", label: `Fabric. de portes et fenêtres en métal` },
  { code: "2521Z", label: `Fab. radiat. & chaudière pr chauf. ctral` },
  { code: "2529Z", label: `Fab. aut. réservr, citerne, etc. métal.` },
  { code: "2530Z", label: `Fab. générat. vapeur sf pr chauff. ctral` },
  { code: "2540Z", label: `Fabrication d'armes et de munitions` },
  { code: "2550A", label: `Forge; métallurgie des poudres` },
  { code: "2550B", label: `Découpage, emboutissage` },
  { code: "2561Z", label: `Traitement et revêtement des métaux` },
  { code: "2562A", label: `Décolletage` },
  { code: "2562B", label: `Mécanique industrielle` },
  { code: "2571Z", label: `Fabrication de coutellerie` },
  { code: "2572Z", label: `Fabrication de serrures et de ferrures` },
  { code: "2573A", label: `Fabrication de moules et modèles` },
  { code: "2573B", label: `Fabrication d'autres outillages` },
  { code: "2591Z", label: `Fab. fût & emballage métalliq. similaire` },
  { code: "2592Z", label: `Fabric. d'emballages métalliques légers` },
  { code: "2593Z", label: `Fab. art. fil métal., chaîne & ressort` },
  { code: "2594Z", label: `Fabrication de vis et de boulons` },
  { code: "2599A", label: `Fabric. d'articles métalliques ménagers` },
  { code: "2599B", label: `Fabric. d'autres articles métalliques` },
  { code: "2611Z", label: `Fabrication de composants électroniques` },
  { code: "2612Z", label: `Fab. de cartes électroniques assemblées` },
  { code: "2620Z", label: `Fab. ordinateur & équipement périphériq.` },
  { code: "2630Z", label: `Fabric. d'équipements de communication` },
  { code: "2640Z", label: `Fab. produit électronique grand public` },
  { code: "2651A", label: `Fab. équipement d'aide à la navigation` },
  { code: "2651B", label: `Fab. instrumentation scientifiq. & tech.` },
  { code: "2652Z", label: `Horlogerie` },
  { code: "2660Z", label: `Fab. éqpt irrad. médic. & électromedic.` },
  { code: "2670Z", label: `Fab. matériel optique et photographique` },
  { code: "2680Z", label: `Fab. de supports magnétiques et optiques` },
  { code: "2711Z", label: `Fab. moteur génér. transfo. & mat. élec.` },
  { code: "2712Z", label: `Fab. mat. de distrib. & de cde électri.` },
  { code: "2720Z", label: `Fabric. pile & accumulateur électrique` },
  { code: "2731Z", label: `Fabrication de câbles de fibres optiques` },
  { code: "2732Z", label: `Fab. aut. fil & câble éltron. ou éltriq.` },
  { code: "2733Z", label: `Fabric. matériel installation électrique` },
  { code: "2740Z", label: `Fabric. appareils d'éclairage électrique` },
  { code: "2751Z", label: `Fabrication d'appareils électroménagers` },
  { code: "2752Z", label: `Fab. appareils ménagers non électriques` },
  { code: "2790Z", label: `Fabric. d'autres matériels électriques` },
  { code: "2811Z", label: `Fab. moteur & turb. sf pr avion & véhic.` },
  { code: "2812Z", label: `Fab. équipement hydraulique & pneumatiq.` },
  { code: "2813Z", label: `Fabric. d'autres pompes et compresseurs` },
  { code: "2814Z", label: `Fabric. autres articles de robinetterie` },
  { code: "2815Z", label: `Fab. engrenage & organe méca. transmis.` },
  { code: "2821Z", label: `Fabrication de fours et brûleurs` },
  { code: "2822Z", label: `Fab. matériel de levage & de manutention` },
  { code: "2823Z", label: `Fab. machine équipt bureau (sf ordinat.)` },
  { code: "2824Z", label: `Fab. outillage portatif à moteur incorp.` },
  { code: "2825Z", label: `Fab. équipt aérauliq. & frigorifiq. ind.` },
  { code: "2829A", label: `Fab. éqpt emballage condition. & pesage` },
  { code: "2829B", label: `Fab. d'autres machines d'usage général` },
  { code: "2830Z", label: `Fab. machines agricoles et forestières` },
  { code: "2841Z", label: `Fab. de machines de formage des métaux` },
  { code: "2849Z", label: `Fabrication d'autres machines-outils` },
  { code: "2891Z", label: `Fabric. de machines pour la métallurgie` },
  { code: "2892Z", label: `Fab. machine pour extraction ou constr.` },
  { code: "2893Z", label: `Fab. machine pour l'indus. agro-aliment.` },
  { code: "2894Z", label: `Fab. machine pour industries textiles` },
  { code: "2895Z", label: `Fab. machine pr indus. papier & carton` },
  { code: "2896Z", label: `Fab. machine pr trav. du caoutch, plast.` },
  { code: "2899A", label: `Fabrication de machines d'imprimerie` },
  { code: "2899B", label: `Fabric. d'autres machines spécialisées` },
  { code: "2910Z", label: `Construction de véhicules automobiles` },
  { code: "2920Z", label: `Fabrication de carrosseries et remorques` },
  { code: "2931Z", label: `Fab. équipt électriq. & électron. auto.` },
  { code: "2932Z", label: `Fabric. d'autres équipements automobiles` },
  { code: "3011Z", label: `Construct. navires & structure flottante` },
  { code: "3012Z", label: `Construction de bateaux de plaisance` },
  { code: "3020Z", label: `Const. loco. & autre mat. ferro. roulant` },
  { code: "3030Z", label: `Construction aéronautique et spatiale` },
  { code: "3040Z", label: `Constr. véhicules militaires de combat` },
  { code: "3091Z", label: `Fabrication de motocycles` },
  { code: "3092Z", label: `Fab. bicyclette & véhic. pour invalides` },
  { code: "3099Z", label: `Fab. aut. équipement de transport n.c.a.` },
  { code: "3101Z", label: `Fab. de meubles de bureau et de magasin` },
  { code: "3102Z", label: `Fabrication de meubles de cuisine` },
  { code: "3103Z", label: `Fabrication de matelas` },
  { code: "3109A", label: `Fabric. sièges d'ameublement d'intérieur` },
  { code: "3109B", label: `Fab. aut. meub. & ind. connexe ameublmnt` },
  { code: "3211Z", label: `Frappe de monnaie` },
  { code: "3212Z", label: `Fab. article de joaillerie et bijouterie` },
  { code: "3213Z", label: `Fab. art. bijout. fantaisie & similaire` },
  { code: "3220Z", label: `Fabrication d'instruments de musique` },
  { code: "3230Z", label: `Fabrication d'articles de sport` },
  { code: "3240Z", label: `Fabrication de jeux et jouets` },
  { code: "3250A", label: `Fab. matériel médico-chirurg. & dentaire` },
  { code: "3250B", label: `Fabrication de lunettes` },
  { code: "3291Z", label: `Fabrication d’articles de brosserie` },
  { code: "3299Z", label: `Autres activités manufacturières n.c.a.` },
  { code: "3311Z", label: `Réparation d'ouvrages en métaux` },
  { code: "3312Z", label: `Répar. machine & équipement mécaniques` },
  { code: "3313Z", label: `Répar. matériel électronique & optique` },
  { code: "3314Z", label: `Réparation d'équipements électriques` },
  { code: "3315Z", label: `Réparation et maintenance navale` },
  { code: "3316Z", label: `Répar. & maint. aéronef & eng. spatiaux` },
  { code: "3317Z", label: `Répar. & maint. d'aut. équipt transport` },
  { code: "3319Z", label: `Réparation d'autres équipements` },
  { code: "3320A", label: `Instal. struct. métal., chaudr. & tuyau.` },
  { code: "3320B", label: `Instal. machines & équipement mécanique` },
  { code: "3320C", label: `Instal. éqpts ctrle des processus indus.` },
  { code: "3320D", label: `Inst. éqpt élec. électro. optiq. ou aut.` },
  { code: "3511Z", label: `Production d'électricité` },
  { code: "3512Z", label: `Transport d'électricité` },
  { code: "3513Z", label: `Distribution d'électricité` },
  { code: "3514Z", label: `Commerce d'électricité` },
  { code: "3521Z", label: `Production de combustibles gazeux` },
  { code: "3522Z", label: `Distrib. combustible gazeux pr conduites` },
  { code: "3523Z", label: `Commerce combustible gazeux par conduite` },
  { code: "3530Z", label: `Prod. & distrib. vapeur et air condit.` },
  { code: "3600Z", label: `Captage, traitement & distribution d'eau` },
  { code: "3700Z", label: `Collecte et traitement des eaux usées` },
  { code: "3811Z", label: `Collecte des déchets non dangereux` },
  { code: "3812Z", label: `Collecte des déchets dangereux` },
  { code: "3821Z", label: `Traitmnt & élimin. déchets non dangereux` },
  { code: "3822Z", label: `Traitmnt & élimination déchets dangereux` },
  { code: "3831Z", label: `Démantèlement d'épaves` },
  { code: "3832Z", label: `Récupération de déchets triés` },
  { code: "3900Z", label: `Dépollution & autre sces gestion déchets` },
  { code: "4110A", label: `Promotion immobilière de logements` },
  { code: "4110B", label: `Promotion immobilière de bureaux` },
  { code: "4110C", label: `Promotion immobilière d'autres bâtiments` },
  { code: "4110D", label: `Supports juridiques de programmes` },
  { code: "4120A", label: `Construction de maisons individuelles` },
  { code: "4120B", label: `Construction d'autres bâtiments` },
  { code: "4211Z", label: `Construction de routes et autoroutes` },
  { code: "4212Z", label: `Const. voie ferrée surface & souterraine` },
  { code: "4213A", label: `Construction d'ouvrages d'art` },
  { code: "4213B", label: `Construction et entretien de tunnels` },
  { code: "4221Z", label: `Construction de réseaux pour fluides` },
  { code: "4222Z", label: `Const. réseaux électriq. & de télécom.` },
  { code: "4291Z", label: `Construc. ouvrages maritimes et fluviaux` },
  { code: "4299Z", label: `Constr. aut. ouvrage de génie civil nca.` },
  { code: "4311Z", label: `Travaux de démolition` },
  { code: "4312A", label: `Travaux de terrassement courants` },
  { code: "4312B", label: `Travaux de terrassement spécialisés` },
  { code: "4313Z", label: `Forages et sondages` },
  { code: "4321A", label: `Travaux instal. électriq. ds tous locaux` },
  { code: "4321B", label: `Travaux instal. électriq. sr voie publi.` },
  { code: "4322A", label: `Travaux instal. eau & gaz en tous locaux` },
  { code: "4322B", label: `Travaux instal. équipt thermique & clim.` },
  { code: "4329A", label: `Travaux d'isolation` },
  { code: "4329B", label: `Autres travaux d'installation n.c.a.` },
  { code: "4331Z", label: `Travaux de plâtrerie` },
  { code: "4332A", label: `Travaux de menuiserie bois et PVC` },
  { code: "4332B", label: `Travaux menuiserie métal. & serrurerie` },
  { code: "4332C", label: `Agencement de lieux de vente` },
  { code: "4333Z", label: `Travaux revêtement des sols et des murs` },
  { code: "4334Z", label: `Travaux de peinture et vitrerie` },
  { code: "4339Z", label: `Autres travaux de finition` },
  { code: "4391A", label: `Travaux de charpente` },
  { code: "4391B", label: `Travaux de couverture par éléments` },
  { code: "4399A", label: `Travaux d'étanchéification` },
  { code: "4399B", label: `Travaux montage de structure métallique` },
  { code: "4399C", label: `Trav. maçon. gle & gros oeuvre bâtiment` },
  { code: "4399D", label: `Aut. travaux spécialisés de construction` },
  { code: "4399E", label: `Location avec opérateur mat. de constr.` },
  { code: "4511Z", label: `Comm. de voiture & véhicule auto. léger` },
  { code: "4519Z", label: `Commerce d'autres véhicules automobiles` },
  { code: "4520A", label: `Entretien & répar. véhicule auto. léger` },
  { code: "4520B", label: `Entretien & répar. autre véhicule auto.` },
  { code: "4531Z", label: `Commerce de gros d'équipement automobile` },
  { code: "4532Z", label: `Commerce de détail équipement automobile` },
  { code: "4540Z", label: `Commerce et réparation de motocycles` },
  { code: "4611Z", label: `Interm. du comm. en produits agricoles` },
  { code: "4612A", label: `Centrales d'achat de carburant` },
  { code: "4612B", label: `Aut. ic comb. mét. minér. & prod. chim.` },
  { code: "4613Z", label: `Interm. comm. bois & matériaux construc.` },
  { code: "4614Z", label: `Int. comm. équipt indus., navire & avion` },
  { code: "4615Z", label: `Int. comm. meuble, art. ménage & quinc.` },
  { code: "4616Z", label: `Int. comm. textile, habillt & assimil.` },
  { code: "4617A", label: `Centrales d'achat alimentaires` },
  { code: "4617B", label: `Autre ic en denrées, boissons et tabac` },
  { code: "4618Z", label: `Int. spécialis. comm. aut. prod. spécif.` },
  { code: "4619A", label: `Centrales d'achat non alimentaires` },
  { code: "4619B", label: `Autre interm. commerce en prodts divers` },
  { code: "4621Z", label: `Com gros céréal. tab. brt & alim. bétail` },
  { code: "4622Z", label: `Commerce de gros de fleurs et plantes` },
  { code: "4623Z", label: `Commerce de gros d'animaux vivants` },
  { code: "4624Z", label: `Commerce de gros de cuirs et peaux` },
  { code: "4631Z", label: `Commerce de gros de fruits et légumes` },
  { code: "4632A", label: `Commerce de gros de viandes de boucherie` },
  { code: "4632B", label: `Comm. gros de produits à base de viande` },
  { code: "4632C", label: `Commerce de gros de volailles et gibier` },
  { code: "4633Z", label: `Com. gros prod. laitier oeuf & mat. grse` },
  { code: "4634Z", label: `Commerce de gros de boissons` },
  { code: "4635Z", label: `Comm. gros de produits à base de tabac` },
  { code: "4636Z", label: `Com. gros de sucre chocolat & confiserie` },
  { code: "4637Z", label: `Comm. gros de café, thé, cacao et épices` },
  { code: "4638A", label: `Com. gros aut. alim. yc poisson crustacé` },
  { code: "4638B", label: `Comm. gros alimentaire spécialisé divers` },
  { code: "4639A", label: `Commerce de gros de produits surgelés` },
  { code: "4639B", label: `Comm de gros alimentaire non spécialisé` },
  { code: "4641Z", label: `Commerce de gros de textiles` },
  { code: "4642Z", label: `Commerce gros d'habillement & chaussures` },
  { code: "4643Z", label: `Commerce de gros appareil électroménager` },
  { code: "4644Z", label: `Com. gros vaisselle verrerie prod. entr.` },
  { code: "4645Z", label: `Com. gros parfumerie & produit de beauté` },
  { code: "4646Z", label: `Comm. gros de produits pharmaceutiques` },
  { code: "4647Z", label: `Com. gros meuble tapis appareil éclaira.` },
  { code: "4648Z", label: `Com. gros artic. horlogerie & bijouterie` },
  { code: "4649Z", label: `Commerce gros d'autres biens domestiques` },
  { code: "4651Z", label: `Comm. gros ordi. éqpt périph. & logiciel` },
  { code: "4652Z", label: `Cg éqpt & composant électron. & télécom.` },
  { code: "4661Z", label: `Commerce de gros de matériel agricole` },
  { code: "4662Z", label: `Commerce de gros de machines-outils` },
  { code: "4663Z", label: `Com. gros machine pr extrac., constr. GC` },
  { code: "4664Z", label: `Com. gros machine pr ind. text. & habil.` },
  { code: "4665Z", label: `Commerce de gros de mobilier de bureau` },
  { code: "4666Z", label: `Com. gros autre machine & équipt bureau` },
  { code: "4669A", label: `Commerce de gros de matériel électrique` },
  { code: "4669B", label: `Com. gros fourniture & équipt ind. div.` },
  { code: "4669C", label: `Cg fournit. & équipt div. pr com. & sces` },
  { code: "4671Z", label: `Com. gros combustible & produits annexes` },
  { code: "4672Z", label: `Commerce de gros de minerais et métaux` },
  { code: "4673A", label: `Com. gros bois & matériaux construction` },
  { code: "4673B", label: `Cg appareil sanitaire & prod. décoration` },
  { code: "4674A", label: `Commerce de gros de quincaillerie` },
  { code: "4674B", label: `Cg fourniture pour plomberie & chauffage` },
  { code: "4675Z", label: `Commerce de gros de produits chimiques` },
  { code: "4676Z", label: `Commerce gros d'aut. prod. intermédiaire` },
  { code: "4677Z", label: `Commerce de gros de déchets et débris` },
  { code: "4690Z", label: `Commerce de gros non spécialisé` },
  { code: "4711A", label: `Commerce de détail de produits surgelés` },
  { code: "4711B", label: `Commerce d'alimentation générale` },
  { code: "4711C", label: `Supérettes` },
  { code: "4711D", label: `Supermarchés` },
  { code: "4711E", label: `Magasins multi-commerces` },
  { code: "4711F", label: `Hypermarchés` },
  { code: "4719A", label: `Grands magasins` },
  { code: "4719B", label: `Autres comm. détail en magasin non spéc.` },
  { code: "4721Z", label: `Com. détail fruit & légume en mag. spéc.` },
  { code: "4722Z", label: `Com. dét. viande & prdt avec viande (ms)` },
  { code: "4723Z", label: `Comm. détail poisson crustacé etc. (ms)` },
  { code: "4724Z", label: `Comm. dét. pain pâtiss. & confiser. (ms)` },
  { code: "4725Z", label: `Com. détail boisson en magasin spéciali.` },
  { code: "4726Z", label: `Comm. dét. produit à base de tabac (ms)` },
  { code: "4729Z", label: `Aut. com. détail alim. en mag. spéciali.` },
  { code: "4730Z", label: `Comm. détail carburant en mag. spéciali.` },
  { code: "4741Z", label: `Com. dét ordi. un. périph. & logicl (ms)` },
  { code: "4742Z", label: `Comm. dét. matériel télécom. (ms)` },
  { code: "4743Z", label: `Comm. dét. matériels audio/vidéo (ms)` },
  { code: "4751Z", label: `Com. dét. textiles en magasin spécialisé` },
  { code: "4752A", label: `Com. dét. quinc. pein. etc. (mag.<400m2)` },
  { code: "4752B", label: `Com. dét. quinc. pein. etc. (mag.>400m2)` },
  { code: "4753Z", label: `Cd tapis moquette & revêt. mur sol (ms)` },
  { code: "4754Z", label: `Comm. dét. appareil électroménager (ms)` },
  { code: "4759A", label: `Commerce de détail de meubles` },
  { code: "4759B", label: `Comm. détail autres équipements du foyer` },
  { code: "4761Z", label: `Comm. dét. livres en magasin spécialisé` },
  { code: "4762Z", label: `Comm. détail journaux & papeterie (ms)` },
  { code: "4763Z", label: `Com. dét. enreg. musicaux & vidéo (ms)` },
  { code: "4764Z", label: `Com. dét. articles de sport en mag. spé.` },
  { code: "4765Z", label: `Com. dét. jeux & jouets en mag. spécial.` },
  { code: "4771Z", label: `Com. dét. habillement en mag. spécialisé` },
  { code: "4772A", label: `Commerce de détail de la chaussure` },
  { code: "4772B", label: `Com. dét. maroquinerie & article voyage` },
  { code: "4773Z", label: `Comm. dét. produits pharmaceutiques (ms)` },
  { code: "4774Z", label: `Com. dét. art. médicaux & orthopéd. (ms)` },
  { code: "4775Z", label: `Com. dét. parfumerie & prodt beauté (ms)` },
  { code: "4776Z", label: `Com. dét. fleur plante anim. cie + alim.` },
  { code: "4777Z", label: `Com. dét. art. horlogerie & bijout. (ms)` },
  { code: "4778A", label: `Commerces de détail d'optique` },
  { code: "4778B", label: `Comm. détail de charbons & combustibles` },
  { code: "4778C", label: `Autre commerce détail spécialisé divers` },
  { code: "4779Z", label: `Comm. détail biens d'occasion en magasin` },
  { code: "4781Z", label: `Cd alimentaire sur éventaire & marché` },
  { code: "4782Z", label: `Cd textiles habillt & chauss. s/marchés` },
  { code: "4789Z", label: `Aut. com. dét. sur éventaires & marchés` },
  { code: "4791A", label: `Vente à distance sur catalogue général` },
  { code: "4791B", label: `Vente à distance sur catalogue spécialis` },
  { code: "4799A", label: `Vente à domicile` },
  { code: "4799B", label: `Vente par automate, aut. cd hors magasin` },
  { code: "4910Z", label: `Transport ferrov. interurbain voyageur` },
  { code: "4920Z", label: `Transports ferroviaires de fret` },
  { code: "4931Z", label: `Transport urbain & suburbain de voyageur` },
  { code: "4932Z", label: `Transports de voyageurs par taxis` },
  { code: "4939A", label: `Transport routier régulier de voyageurs` },
  { code: "4939B", label: `Autres transports routiers de voyageurs` },
  { code: "4939C", label: `Téléphériques et remontées mécaniques` },
  { code: "4941A", label: `Transports routiers de fret interurbains` },
  { code: "4941B", label: `Transports routiers de fret de proximité` },
  { code: "4941C", label: `Location de camions avec chauffeur` },
  { code: "4942Z", label: `Services de déménagement` },
  { code: "4950Z", label: `Transports par conduites` },
  { code: "5010Z", label: `Transport maritime & côtier de passagers` },
  { code: "5020Z", label: `Transports maritimes et côtiers de fret` },
  { code: "5030Z", label: `Transports fluviaux de passagers` },
  { code: "5040Z", label: `Transports fluviaux de fret` },
  { code: "5110Z", label: `Transports aériens de passagers` },
  { code: "5121Z", label: `Transports aériens de fret` },
  { code: "5122Z", label: `Transports spatiaux` },
  { code: "5210A", label: `Entreposage et stockage frigorifique` },
  { code: "5210B", label: `Entreposage et stockage non frigorifique` },
  { code: "5221Z", label: `Sces auxiliaires de transport terrestre` },
  { code: "5222Z", label: `Sces auxiliaires des transports par eau` },
  { code: "5223Z", label: `Sces auxiliaires des transports aériens` },
  { code: "5224A", label: `Manutention portuaire` },
  { code: "5224B", label: `Manutention non portuaire` },
  { code: "5229A", label: `Messagerie, fret express` },
  { code: "5229B", label: `Affrètement & organisation des transp.` },
  { code: "5310Z", label: `Activ. poste (obligation sce universel)` },
  { code: "5320Z", label: `Autres activités de poste et de courrier` },
  { code: "5510Z", label: `Hôtels et hébergement similaire` },
  { code: "5520Z", label: `Hébergt tourist. & aut. hbt courte durée` },
  { code: "5530Z", label: `Terrain camping & parc pr caravane etc.` },
  { code: "5590Z", label: `Autres hébergements` },
  { code: "5610A", label: `Restauration traditionnelle` },
  { code: "5610B", label: `Cafétérias et autres libres-services` },
  { code: "5610C", label: `Restauration de type rapide` },
  { code: "5621Z", label: `Services des traiteurs` },
  { code: "5629A", label: `Restauration collective sous contrat` },
  { code: "5629B", label: `Autres services de restauration n.c.a.` },
  { code: "5630Z", label: `Débits de boissons` },
  { code: "5811Z", label: `Édition de livres` },
  { code: "5812Z", label: `Édition répertoires & fichiers d'adresse` },
  { code: "5813Z", label: `Édition de journaux` },
  { code: "5814Z", label: `Édition de revues et périodiques` },
  { code: "5819Z", label: `Autres activités d'édition` },
  { code: "5821Z", label: `Édition de jeux électroniques` },
  { code: "5829A", label: `Édition de logiciel système et de réseau` },
  { code: "5829B", label: `Edit. logiciel outil dévelop. & langage` },
  { code: "5829C", label: `Edition de logiciels applicatifs` },
  { code: "5911A", label: `Prod. film & progm. pour la télévision` },
  { code: "5911B", label: `Prod. film institutionnel & publicitaire` },
  { code: "5911C", label: `Production de films pour le cinéma` },
  { code: "5912Z", label: `Post-production film & prog. télévision` },
  { code: "5913A", label: `Distribution de films cinématographiques` },
  { code: "5913B", label: `Edition et distribution vidéo` },
  { code: "5914Z", label: `Projection de films cinématographiques` },
  { code: "5920Z", label: `Enregistrement sonore & édition musicale` },
  { code: "6010Z", label: `Édition et diffusion de programmes radio` },
  { code: "6020A", label: `Edition de chaînes généralistes` },
  { code: "6020B", label: `Edition de chaînes thématiques` },
  { code: "6110Z", label: `Télécommunications filaires` },
  { code: "6120Z", label: `Télécommunications sans fil` },
  { code: "6130Z", label: `Télécommunications par satellite` },
  { code: "6190Z", label: `Autres activités de télécommunication` },
  { code: "6201Z", label: `Programmation informatique` },
  { code: "6202A", label: `Conseil en système & logiciel informati.` },
  { code: "6202B", label: `Tierce mainten. syst. & appli. nformati.` },
  { code: "6203Z", label: `Gestion d'installations informatiques` },
  { code: "6209Z", label: `Autres activités informatiques` },
  { code: "6311Z", label: `Traitt donnée, hébergt & activ. connexe` },
  { code: "6312Z", label: `Portails Internet` },
  { code: "6391Z", label: `Activités des agences de presse` },
  { code: "6399Z", label: `Autres services d'information n.c.a.` },
  { code: "6411Z", label: `Activités de banque centrale` },
  { code: "6419Z", label: `Autres intermédiations monétaires` },
  { code: "6420Z", label: `Activités des sociétés holding` },
  { code: "6430Z", label: `Fonds placement & entité financ. simil.` },
  { code: "6491Z", label: `Crédit-bail` },
  { code: "6492Z", label: `Autre distribution de crédit` },
  { code: "6499Z", label: `Aut. act. finan. hs as. & c. retra. nca.` },
  { code: "6511Z", label: `Assurance vie` },
  { code: "6512Z", label: `Autres assurances` },
  { code: "6520Z", label: `Réassurance` },
  { code: "6530Z", label: `Caisses de retraite` },
  { code: "6611Z", label: `Administration de marchés financiers` },
  { code: "6612Z", label: `Courtage valeur mobilière & marchandise` },
  { code: "6619A", label: `Support juridiq. gest. patrimoine mobil.` },
  { code: "6619B", label: `Aut. aux. sce financ. hs ass. retr. nca.` },
  { code: "6621Z", label: `Évaluation des risques et dommages` },
  { code: "6622Z", label: `Act. des agents & courtiers d'assurances` },
  { code: "6629Z", label: `Aut. act. aux. assur. & caisse retraite` },
  { code: "6630Z", label: `Gestion de fonds` },
  { code: "6810Z", label: `Activité marchands de biens immobiliers` },
  { code: "6820A", label: `Location de logements` },
  { code: "6820B", label: `Location terrain & autre bien immobilier` },
  { code: "6831Z", label: `Agences immobilières` },
  { code: "6832A", label: `Administrat. immeuble & autre bien immo.` },
  { code: "6832B", label: `Support juridi. gestion patrimoine immo.` },
  { code: "6910Z", label: `Activités juridiques` },
  { code: "6920Z", label: `Activités comptables` },
  { code: "7010Z", label: `Activités des sièges sociaux` },
  { code: "7021Z", label: `Conseil en relation publique & communic.` },
  { code: "7022Z", label: `Conseil pr affaire & aut. cons. gestion` },
  { code: "7111Z", label: `Activités d'architecture` },
  { code: "7112A", label: `Activité des géomètres` },
  { code: "7112B", label: `Ingénierie, études techniques` },
  { code: "7120A", label: `Contrôle technique automobile` },
  { code: "7120B", label: `Analyses, essais & inspection technique` },
  { code: "7211Z", label: `Recherche-développemnt en biotechnologie` },
  { code: "7219Z", label: `R&D : aut. sciences physique & naturelle` },
  { code: "7220Z", label: `R&D en sciences humaines et sociales` },
  { code: "7311Z", label: `Activités des agences de publicité` },
  { code: "7312Z", label: `Régie publicitaire de médias` },
  { code: "7320Z", label: `Études de marché et sondages` },
  { code: "7410Z", label: `Activités spécialisées de design` },
  { code: "7420Z", label: `Activités photographiques` },
  { code: "7430Z", label: `Traduction et interprétation` },
  { code: "7490A", label: `Activ des économistes de la construction` },
  { code: "7490B", label: `Act. spéc. scientif. & techniq. diverses` },
  { code: "7500Z", label: `Activités vétérinaires` },
  { code: "7711A", label: `Loc. courte durée voit. & v. auto. léger` },
  { code: "7711B", label: `Loc. longue durée voit. & v. auto. léger` },
  { code: "7712Z", label: `Location et location-bail de camions` },
  { code: "7721Z", label: `Loc. & loc.-bail article loisir & sport` },
  { code: "7722Z", label: `Location de vidéocassette & disque vidéo` },
  { code: "7729Z", label: `Loc. & loc.-bail aut. bien perso. & dom.` },
  { code: "7731Z", label: `Loc. & loc.-bail machine & éqpt agricole` },
  { code: "7732Z", label: `Loc. & loc.-bail mach. & éqpt pr constr.` },
  { code: "7733Z", label: `Loc. & loc.-bail mach. bur. & mat. info.` },
  { code: "7734Z", label: `Loc. & loc.-bail mat. transport par eau` },
  { code: "7735Z", label: `Loc. & loc.-bail mat. transport aérien` },
  { code: "7739Z", label: `Loc. & loc.-bail mach., éqpt & bien div.` },
  { code: "7740Z", label: `Loc-bail propr. intel., sf oeuvre avec ©` },
  { code: "7810Z", label: `Activ. agence placement de main-d'oeuvre` },
  { code: "7820Z", label: `Activ. des agences de travail temporaire` },
  { code: "7830Z", label: `Aut. mise à dispo. de ressource humaine` },
  { code: "7911Z", label: `Activités des agences de voyage` },
  { code: "7912Z", label: `Activités des voyagistes` },
  { code: "7990Z", label: `Autre serv. réservation & activ. connexe` },
  { code: "8010Z", label: `Activités de sécurité privée` },
  { code: "8020Z", label: `Activités liées aux systèmes de sécurité` },
  { code: "8030Z", label: `Activités d'enquête` },
  { code: "8110Z", label: `Act. combinée soutien lié aux bâtiments` },
  { code: "8121Z", label: `Nettoyage courant des bâtiments` },
  { code: "8122Z", label: `Aut. act. nettoyage bâtim. & nett. ind.` },
  { code: "8129A", label: `Désinfection désinsectisatn dératisation` },
  { code: "8129B", label: `Autres activités de nettoyage n.c.a.` },
  { code: "8130Z", label: `Services d'aménagement paysager` },
  { code: "8211Z", label: `Services admin. combinés de bureau` },
  { code: "8219Z", label: `Photocopie & aut. act. spé. sout. bureau` },
  { code: "8220Z", label: `Activités de centres d'appels` },
  { code: "8230Z", label: `Organisation salon profession. & congrès` },
  { code: "8291Z", label: `Act. recouv. fac. & info. fin. s/client.` },
  { code: "8292Z", label: `Activités de conditionnement` },
  { code: "8299Z", label: `Autre activité de soutien aux entr. nca.` },
  { code: "8411Z", label: `Administration publique générale` },
  { code: "8412Z", label: `A. p. santé form. cult. & soc. (sf sécu)` },
  { code: "8413Z", label: `Adm. publique des activités économiques` },
  { code: "8421Z", label: `Affaires étrangères` },
  { code: "8422Z", label: `Défense` },
  { code: "8423Z", label: `Justice` },
  { code: "8424Z", label: `Activités d’ordre public et de sécurité` },
  { code: "8425Z", label: `Services du feu et de secours` },
  { code: "8430A", label: `Activités générales de sécurité sociale` },
  { code: "8430B", label: `Gestion des retraites complémentaires` },
  { code: "8430C", label: `Distribution sociale de revenus` },
  { code: "8510Z", label: `Enseignement pré-primaire` },
  { code: "8520Z", label: `Enseignement primaire` },
  { code: "8531Z", label: `Enseignement secondaire général` },
  { code: "8532Z", label: `Enseignemt secondaire techn. ou profess.` },
  { code: "8541Z", label: `Enseignement post-secondaire non sup.` },
  { code: "8542Z", label: `Enseignement supérieur` },
  { code: "8551Z", label: `Enseigmnt discipl. sport. & act. loisir.` },
  { code: "8552Z", label: `Enseignement culturel` },
  { code: "8553Z", label: `Enseignement de la conduite` },
  { code: "8559A", label: `Formation continue d'adultes` },
  { code: "8559B", label: `Autres enseignements` },
  { code: "8560Z", label: `Activités de soutien à l'enseignement` },
  { code: "8610Z", label: `Activités hospitalières` },
  { code: "8621Z", label: `Activité des médecins généralistes` },
  { code: "8622A", label: `Act. radiodiagnostic et de radiothérapie` },
  { code: "8622B", label: `Activités chirurgicales` },
  { code: "8622C", label: `Autre activité des médecins spécialistes` },
  { code: "8623Z", label: `Pratique dentaire` },
  { code: "8690A", label: `Ambulances` },
  { code: "8690B", label: `Laboratoires d'analyses médicales` },
  { code: "8690C", label: `Centres de collecte et banques d'organes` },
  { code: "8690D", label: `Act. des infirmiers et des sages-femmes` },
  { code: "8690E", label: `Act. rééduc. appareillag. & pédic.-podo.` },
  { code: "8690F", label: `Activités de santé humaine nca.` },
  { code: "8710A", label: `Hébergt médicalisé pour personnes âgées` },
  { code: "8710B", label: `Hébergt médicalisé pr enfants handicapés` },
  { code: "8710C", label: `Hébrgt médic. adul. hand. & aut. ht méd.` },
  { code: "8720A", label: `Hébrgt soc. hand. mental & malade mental` },
  { code: "8720B", label: `Hébergement social pour toxicomanes` },
  { code: "8730A", label: `Hébergement social pour personnes âgées` },
  { code: "8730B", label: `Hébergt social pour handicapés physiques` },
  { code: "8790A", label: `Hébergt social pr enfants en difficultés` },
  { code: "8790B", label: `Hébgt soc. adult., famille en difficulté` },
  { code: "8810A", label: `Aide à domicile` },
  { code: "8810B", label: `Accueil ss hbgt adult. hand., pers. âgée` },
  { code: "8810C", label: `Aide par le travail` },
  { code: "8891A", label: `Accueil de jeunes enfants` },
  { code: "8891B", label: `Accueil sans hébergt d'enfant handicap` },
  { code: "8899A", label: `Aut. accueil sans hébrgt enfants & ado."` },
  { code: "8899B", label: `Action sociale sans hébergement n.c.a.` },
  { code: "9001Z", label: `Arts du spectacle vivant` },
  { code: "9002Z", label: `Activités de soutien au spectacle vivant` },
  { code: "9003A", label: `Création artistique (arts plastiques)` },
  { code: "9003B", label: `Autre création artistique` },
  { code: "9004Z", label: `Gestion de salles de spectacles` },
  { code: "9101Z", label: `Gestion des bibliothèques & des archives` },
  { code: "9102Z", label: `Gestion des musées` },
  { code: "9103Z", label: `Gestion site histor. & attraction simil.` },
  { code: "9104Z", label: `Gest. jardin bota. & zoo. & réserv. nat.` },
  { code: "9200Z", label: `Organisation jeux de hasard & d'argent` },
  { code: "9311Z", label: `Gestion d'installations sportives` },
  { code: "9312Z", label: `Activités de clubs de sports` },
  { code: "9313Z", label: `Activité des centres de culture physique` },
  { code: "9319Z", label: `Autres activités liées au sport` },
  { code: "9321Z", label: `Act. parcs attractions & parcs à thèmes` },
  { code: "9329Z", label: `Autres activités récréative & de loisirs` },
  { code: "9411Z", label: `Act. organisations patronale & consul.` },
  { code: "9412Z", label: `Act. des organisations professionnelles` },
  { code: "9420Z", label: `Activités des syndicats de salariés` },
  { code: "9491Z", label: `Activités des organisations religieuses` },
  { code: "9492Z", label: `Activités des organisations politiques` },
  { code: "9499Z", label: `Aut. org. fonctionnant par adhé. volont.` },
  { code: "9511Z", label: `Répar. ordinateur & équipt périphérique` },
  { code: "9512Z", label: `Réparation équipements de communication` },
  { code: "9521Z", label: `Réparation prdts électroniq. grd public` },
  { code: "9522Z", label: `Répar. électromén. & éqpt maison & jard.` },
  { code: "9523Z", label: `Réparation chaussures & articles en cuir` },
  { code: "9524Z", label: `Réparation meubles & d'équipt du foyer` },
  { code: "9525Z", label: `Répar.articles horlogerie & bijouterie` },
  { code: "9529Z", label: `Répar. aut. biens personnel & domestique` },
  { code: "9601A", label: `Blanchisserie-teinturerie de gros` },
  { code: "9601B", label: `Blanchisserie-teinturerie de détail` },
  { code: "9602A", label: `Coiffure` },
  { code: "9602B", label: `Soins de beauté` },
  { code: "9603Z", label: `Services funéraires` },
  { code: "9604Z", label: `Entretien corporel` },
  { code: "9609Z", label: `Autres services personnels n.c.a.` },
  { code: "9700Z", label: `Act. ménage: empl. de person. domestique` },
  { code: "9810Z", label: `Act. ménage : prod. biens (usage propre)` },
  { code: "9820Z", label: `Act. ménage : prod. serv. (usage propre)` },
  { code: "9900Z", label: `Act. organisations extraterritoriales` },
];

export const findApeItem = (apeCode) => {
  return apeCodes.find((x) => x.code === apeCode);
};

export default apeCodes;
