import React from "react";
import TaxResidences from "./TaxResidences";

function TaxResidencesList({
  list,
  onChange,
  badField,
  error422,
}) {
  const onUpdate = (i, v) => {
    const newLst = list.map((x, j) => (i === j ? v : x));
    onChange(newLst);
  };
  const onRemove = (i) => {
    const newLst = list.filter((x, j) => i !== j);
    onChange(newLst);
  };
  const onAddNew = (tx) => {
    const newLst = [...list, tx];
    onChange(newLst);
  };

  const addTaxResidence = () => {
    const empty_tax_residences = {
      country: "FR",
    };
    onAddNew(empty_tax_residences);
  };

  return (
    <React.Fragment>
      <div style={{ fontSize: "1.25rem", lineHeight: "1.2rem" }}>
        Résidence(s) fiscale(s)
      </div>

      {list && list.length > 0 && (
        <div className={`txResidenceWrap ${list.length > 1 ? "several" : ""}`}>
          {list.map((x, i) => (
            <TaxResidences
              key={i}
              index={i}
              item={x}
              badField={
                badField && badField.name === "tax_residences"
                  ? badField.sub
                  : null
              }
              error422={error422}
              onChange={(v) => onUpdate(i, v)}
              onRemove={() => onRemove(i)}
            />
          ))}
        </div>
      )}
      {badField &&
        badField.name === "tax_residences" &&
        badField.type === "array.min" &&
        list.length === 0 && (
          <div className="errorTextOnForm">
            Au moins une résidence fiscale est requise
          </div>
        )}
      <div
        style={{ textDecorationLine: "underline", cursor: "pointer" }}
        onClick={addTaxResidence}
      >
        + Ajouter résidence fiscale
      </div>
    </React.Fragment>
  );
}

export default TaxResidencesList;
