import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";

const SirenInput = ({
  label,
  type,
  value,
  onChange,
  noUpLabel,
  disabled,
  errorMessage,
}) => {
  return (
    <NumberFormat
      customInput={TextField}
      format="### ### ###"
      label={noUpLabel ? "" : label}
      placeholder={noUpLabel ? label : ""}
      value={value}
      //type={type}
      onValueChange={(values) => {
        const { value } = values;
        onChange(value);
      }}
      disabled={disabled}
      //
      variant="outlined"
      size="small"
      fullWidth
      helperText={errorMessage}
      error={!!errorMessage}
    />
  );
};
export default SirenInput;
