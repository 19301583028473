import axios from "axios";
import { apiBaseURL } from "config";
import { apiCall } from "store/api";
import { apiCalled, logout } from "store/reducers/auth";
import { history } from "store";

function linkTo(link) {
  if (link === "previous") {
    history.goBack();
  } else {
    history.push(link);
  }
}

/**
 * //Should be called this way :
 *  const apiAction = {
 *  type: "apiCall",
 *  payload: {
 *  url: "/",
 *  method: "get",
 *  data: {},
 *  onSuccess: {},
 *  onError: {},
 *  ....
 * },
 * };
 */
const api =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCall.type) return next(action);
    //else
    const {
      url,
      method,
      data,
      onStart, //Specific action before api request started
      onEnd, //Specific action after api request ended
      onSuccess, //Specific action on api request success
      onError, //Specific action on api request error
      successLink, //where to redirect after success
      errorLink, //where to redirect after error
      localData, //data to use after api response/won't be sent to the api
    } = action.payload;

    const jwt = getState().auth.jwt;
    const token = jwt ? jwt.token : null;

    try {
      //Specific action before the request
      if (onStart) dispatch({ type: onStart });

      //The API request
      const response = await axios({
        baseURL: apiBaseURL,
        url,
        method,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      //Specific action on success
      if (onSuccess) {
        const calledMethod = method ?? "get";
        switch (calledMethod) {
          case "get":
          case "post":
            dispatch({
              type: onSuccess,
              payload: localData
                ? { ...localData, ...response.data }
                : response.data,
            });
            break;
          case "put": //put commande does return no thing :(
          case "delete":
          default:
            dispatch({
              type: onSuccess,
              payload: localData
                ? { ...localData, ...response.data }
                : response.data,
            }); //becose in those cases the server wont return relevent information
        }
      }
      if (successLink) linkTo(successLink); //Redirect to...
    } catch (error) {
      //Global action on request failed
      const errObj = { message: "Une erreur est survenue" };

      if (error.response && error.response.status) {
        const status = error.response.status;
        errObj.status = status;

        //needed even on 401 !'
        if (error.request && error.request.response) {
          const bdy = JSON.parse(error.request.response);
          if (bdy && bdy.messages) {
            try {
              const messages = bdy.messages;
              errObj.error422 = messages;

              if (error.response.status === 422) {
                errObj.message = messages.base ? messages.base[0] : null; // other errors will be destributed by errObj.error422
              } else {
                errObj.message = messages[Object.keys(messages)[0]][0]; //first error message
              }
            } catch (err) {
              //nothing to dohere: we can not admit exception in an error handling part
            }
          }
        }
      } else if (!window.navigator.onLine) {
        errObj.message = "Aucune connexion internet";
      }

      //REDIRECTION
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
      //Specific action on request failed
      if (onError) {
        dispatch({ type: onError, payload: errObj });
      }
      //Client asked for redirection if failed
      else if (errorLink) linkTo(errorLink); //Redirect to...
    } finally {
      if (onEnd) dispatch({ type: onEnd });

      //fait glisser le temps restant avant le logout : Je suppose que le glissement se fait pour toute les requetes {succeeded or failed}
      dispatch(apiCalled({ calledAt: new Date().getTime() }));
    }
  };

export default api;
