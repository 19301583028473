import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiDeleteBeneficiary,
  apiSaveNewBeneficiary,
  apiUpdateBeneficiary,
} from "store/reducers/dashboard/benificiaries";
import { acceptConfirmation, askForConfirmation, denyConfirmation, } from "store/reducers/ui/company/benificiaries";
import { ConfirmDialog } from "shared/components/Dialog";

import { history } from "store";
import { Button } from "shared/components";
import BeneficiaryEditBody from "./BeneficiaryEditBody";
import validateForm from "./validate";
import WrapPage, { WrapMainForm } from "Funnel/Components/WrapPage";

const EditExistingBeneficiary = ({ beneficiaryId }) => {
  const itemId = beneficiaryId;

  const dispatch = useDispatch();
  const storeList = useSelector((store) => store.dashboard.benificiaries.list);
  //Else

  const index = storeList.findIndex((x) => x.id === parseInt(itemId)); //TODO depending on the type of yythe Id used on server
  const elm = storeList[index];
  const [state, setState] = useState(elm);
  const [initState] = useState(elm);
  const [badField, setBadField] = useState(null);

  //

  const handleClose = () => {
    if (state === initState) {
      history.goBack();
    } else {
      //has been modified
      dispatch(
        askForConfirmation({
          title: "Attention",
          message: `Vous quitterez sans enregistrer vos modifications! Voulez-vous continuer?`,
          todo: () => {
            history.goBack();
          },
        })
      );
    }
  };

  const handleRemove = () => {
    dispatch(
      askForConfirmation({
        title: "Attention",
        message: "Vous allez supprimer un bénéficiaire! Voulez-vous continuer?",
        todo: () => {
          dispatch(
            apiDeleteBeneficiary({ id: state.id, successLink: "previous" })
          );
        },
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (state === initState) {
      history.goBack();
    } else {
      const error = await validateForm(state);
      setBadField(error);
      if (!error) {
        const state_body = { ...state, us_person: state.us_person === null ? false : state.us_person }
        dispatch(
          apiUpdateBeneficiary({ data: state_body, successLink: "previous" })
        );
      }
    }
  };

  return (
    <WrapPage>
      <div className="formW">
        <div className="formH">
          <div className="goBack" onClick={handleClose}>
            <i className="icon-arrow-back" />
          </div>
          <div className="formTitle">Bénéficiaire {index + 1}</div>
        </div>

        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <BeneficiaryEditBody
            badField={badField}
            beneficiary={state}
            onStateChange={(v) => setState(v)}
          />

          <div className="buttonsLst">
            <Button variant="outlined" onClick={handleRemove}>
              Supprimer
            </Button>
            <Button
              type="submit"
              variant="contained"
              primary
              disabled={state === initState}
            >
              Modifier
            </Button>
          </div>
        </form>
      </div>
    </WrapPage>
  );
};

const EditNewBeneficiary = () => {
  const dispatch = useDispatch();
  const initState = useSelector(
    (store) => store.ui.company.benificiaries.emptyItem
  );
  const [state, setState] = useState(initState);
  const [badField, setBadField] = useState(null);

  //

  const handleClose = () => {
    handleRemove();
  };

  const handleRemove = () => {
    if (state === initState) {
      history.goBack();
    } else {
      dispatch(
        askForConfirmation({
          title: "Attention",
          message: `Vous quitterez sans enregistrer vos modifications! Voulez-vous continuer?`,
          todo: () => {
            history.goBack();
          },
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = await validateForm(state);
    setBadField(error);
    if (!error) {
      const state_body = { ...state, us_person: state.us_person === null ? false : state.us_person }
      dispatch(
        apiSaveNewBeneficiary({
          data: state_body,
          successLink: "/beneficiaries-documents",
        })
      );
    }
  };

  return (
    <div className="funnelPgW">
      <div className="funnelPgContent">
        <div className="funnelPgL">
          <WrapMainForm>
            <div className="formW">
              <div className="formH">
                <div className="goBack" onClick={handleClose}>
                  <i className="icon-arrow-back" />
                </div>
                <div className="formTitle">Nouveau bénéficiaire</div>
              </div>

              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <BeneficiaryEditBody
                  badField={badField}
                  beneficiary={state}
                  onStateChange={(v) => setState(v)}
                />

                <div className="buttonsLst">
                  <Button variant="outlined" onClick={handleRemove}>
                    Annuler
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    primary
                    disabled={state === initState}
                  >
                    Ajouter
                  </Button>
                </div>
              </form>
            </div>
          </WrapMainForm>
        </div>
        <div className="funnelPgR"></div>
      </div>
    </div>
  );
};

const BeneficiaryEditPage = ({ match }) => {
  const itemId = match.params.id; //Edition demanded specificly (from setting board or validation form) to the beneficiary with a given id
  const ui = useSelector((store) => store.ui.company.benificiaries);
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      {ui.needConfirm && (
        <ConfirmDialog
          open={ui.needConfirm !== null}
          title={ui.needConfirm.title}
          message={ui.needConfirm.message}
          onOk={() => {
            dispatch(acceptConfirmation());
            ui.needConfirm.todo();
          }}
          onCancel={() => dispatch(denyConfirmation())}
        ></ConfirmDialog>
      )}

      {itemId === "add" ? (
        <EditNewBeneficiary />
      ) : (
        <EditExistingBeneficiary beneficiaryId={itemId} />
      )}
    </React.Fragment>
  );
};

export default BeneficiaryEditPage;
