import { createSlice, current } from "@reduxjs/toolkit";
import { beneficiaryAdded, beneficiaryRemoved, } from "store/reducers/dashboard/benificiaries";
import { ID_DOC_TYPE } from "shared/enums/DocumentsTypes";

const moreUploadNeeded = (docs) => {
  const beneficiaryWithEmptyDocs = Object.keys(docs).find(
    (k) => docs[k].length === 0
  );
  return beneficiaryWithEmptyDocs !== undefined;
};

const slice = createSlice({
  name: "beneficiaries",
  initialState: {
    docs: {
      //beneficiary_id: id_docs: [],
    },
    moreUploadNeeded: false,
  },
  reducers: {},
  extraReducers: {
    initDashboard: (state, { payload }) => {
      //create empty list for each beneficiary
      if (payload.ultimate_beneficiary_owners) {
        payload.ultimate_beneficiary_owners.forEach(
          (x) => (state.docs[x.id] = [])
        );
      }

      //fill beneficiary list of docs
      if (payload.documents) {
        payload.documents.forEach((doc) => {
          if (
            doc.owner_type.toLowerCase() === "ultimatebeneficiary" &&
            ID_DOC_TYPE.includes(doc.document_type) &&
            doc.owner_id
          ) {
            //TODO: ce n'est pas bon nous allons avoir les id des deux type d'interlocuteurs!
            if (!state.docs[doc.owner_id]) state.docs[doc.owner_id] = [];
            state.docs[doc.owner_id].push(doc);
          }
        });
      }
      state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
    },
    documentUploaded: (state, { payload }) => {
      if (payload.folder === "ultimate_beneficiary_owners") {
        state.docs[payload.owner_id].push(payload);
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    documentDeleted: (state, { payload }) => {
      if (payload.folder === "ultimate_beneficiary_owners") {
        state.docs[payload.owner_id] = state.docs[payload.owner_id].filter(
          (x) => x.id !== payload.id
        );
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    [beneficiaryAdded.type]: (state, { payload }) => {
      state.docs[payload.id] = [];
      state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
    },
    [beneficiaryRemoved.type]: (state, { payload }) => {
      delete state.docs[payload.id];
      state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
    },
  },
});

export const { documentUploaded } = slice.actions;
export default slice.reducer;
