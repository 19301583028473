import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { history } from "store";

import { Button, GlobalErrorMessage } from "shared/components";
import DocumentsList from "./DocumentsList";
import EditDocumentAdding from "./EditDocumentAdding";
import { DocumentFolder, DocumentSubFolder, enum_id_docs, } from "shared/enums/DocumentsTypes";
import { WrapMainForm } from "Funnel/Components/WrapPage";
import "./documents.scss";

const RepresentativeDocs = ({ match }) => {
  const isUpdate = match.params.action === "update";
  const { docs, moreUploadNeeded } = useSelector(
    (store) => store.dashboard.documents.representative
  );
  const dashboard = useSelector((store) => store.dashboard);
  const noAdministrator = dashboard.representants.administrator.neverUploaded;
  const beneficiaries = dashboard.benificiaries.list;
  const hasBeneficiaries = beneficiaries && beneficiaries.length > 0;

  const [edited, setEdited] = useState(null);
  const [notCompleted, setNotCompleted] = useState(false);

  useEffect(() => setNotCompleted(false), [moreUploadNeeded]);

  const handleValidate = async () => {
    if (!moreUploadNeeded) {
      if (isUpdate) {
        history.goBack();
      } else if (!noAdministrator) {
        history.push("/interlocutor-documents");
      } else if (hasBeneficiaries) {
        history.push("/beneficiaries-documents");
      } else {
        history.push("/accounts");
      }
    } else {
      setNotCompleted(true);
    }
  };

  return (
    <div className="funnelPgW">
      <div className={`funnelPgContent ${edited ? "rightFormEdited" : ""}`}>
        <div className="funnelPgL">
          <WrapMainForm>
            {!isUpdate && <div className="sectionName">4/4 — Documents</div>}
            <div className="formW">
              <div className="formH">
                <div className="goBack" onClick={() => history.goBack()}>
                  <i className="icon-arrow-back" />
                </div>
                <div className="formTitle">
                  {DocumentFolder.representative.label}
                </div>
              </div>
              <div>
                <div className="docZone">
                  <div className="docTitle">Pièce d’identité</div>
                  <DocumentsList
                    folder={DocumentFolder.representative.key}
                    subFolder={DocumentSubFolder.id_docs.key}
                    list={docs.id_docs}
                    onAddingClick={() => {
                      setEdited(DocumentSubFolder.id_docs.key);
                    }}
                    active={edited === DocumentSubFolder.id_docs.key}
                    errorClass={notCompleted && docs.id_docs.length === 0}
                  />
                </div>

                {notCompleted && (
                  <div className="docZone">
                    <GlobalErrorMessage>
                      Vous devez ajouter au moins un fichier par document
                      demandé.
                    </GlobalErrorMessage>
                  </div>
                )}

                <Button onClick={handleValidate} variant="contained" primary>
                  {isUpdate ? "Enregistrer" : "Suivant"}
                </Button>
              </div>
            </div>
          </WrapMainForm>
        </div>
        <div className="funnelPgR">
          {edited === DocumentSubFolder.id_docs.key && (
            <EditDocumentAdding
              title={DocumentSubFolder.id_docs.label}
              folder={DocumentFolder.representative.key}
              subFolder={DocumentSubFolder.id_docs.key}
              selectEnum={enum_id_docs}
              close={() => setEdited(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RepresentativeDocs;
