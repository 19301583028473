import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

const ggKey = "AIzaSyDcJvR9CJo-sMv_s0E3PVYDHFVhQRjlL9U";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GooglePlacesAutocomplete({
                                                   label,
                                                   name,
                                                   value,
                                                   onChange,
                                                   errorMessage,
                                                 }) {
  const classes = useStyles();
  const [state, setState] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const { street } = value;

  const handleChange = (place) => {

    const address = {
      street: '',
      postcode: '',
      territory: '',
      city: '',
      country: '',
    };

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address.street = `${component.long_name} ${address.street}`;
          break;
        }

        case "route": {
          address.street += component.long_name;
          break;
        }

        case "postal_code": {
          address.postcode = component.long_name;
          break;
        }

        case "locality": {
          address.city = component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          address.territory = component.long_name;
          break;
        }
        case "country":
          address.country = component.short_name;
          break;

        default:
          break;
      }
    }
    onChange(address);
  };

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${ggKey}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }
    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );
  React.useEffect(() => {
    setInputValue(street ?? "");
  }, [street]);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();

      autocompleteService.map = new window.google.maps.Map(
        document.createElement("div")
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === "") {
      setOptions(state ? [state] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        //ypes: ["address"],
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (state) {
            newOptions = [state];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [state, inputValue, fetch]);

  const assignState = (st) => {
    if (st) {
      const placeRequest = {
        placeId: st.place_id,
        language: "fr",
        fields: ["address_components", "adr_address", "formatted_address"],
      };
      const googlePlaces = new window.google.maps.places.PlacesService(
        autocompleteService.map
      );
      googlePlaces.getDetails(placeRequest, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          handleChange(place);
        }
      });
    }
    setState(st);
  };
  return (
    <Autocomplete
      autoComplete
      includeInputInList
      filterSelectedOptions
      freeSolo={true}
      options={options}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      value={street}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        assignState(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue !== street) {
          onChange({ street: newInputValue });
        }
      }}
      renderInput={(params) => (
        <TextField
          {...{ ...params, inputProps: { ...params.inputProps, autoComplete: 'places' } }}
          label={label}
          name={name}
          variant="outlined"
          fullWidth
          helperText={errorMessage}
          error={!!errorMessage}
        />
      )}
      renderOption={(props, option, _state) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center" {...props}>
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
