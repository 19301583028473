import React from "react";
import { useSelector } from "react-redux";
import CompanyIndividualDocs from "./CompanyIndividualDocs";
import CompanyMoraleDocs from "./CompanyMoraleDocs";

import "./documents.scss";

const CompanyDocs = ({ match }) => {
  const dashboard = useSelector((store) => store.dashboard);
  const isIndividualCompany =
    dashboard.company.identification.val.legal_form === "individual_enterprise";

  const isUpdate = match.params.action === "update";

  return isIndividualCompany ? (
    <CompanyIndividualDocs isUpdate={isUpdate} />
  ) : (
    <CompanyMoraleDocs isUpdate={isUpdate} />
  );
};

export default CompanyDocs;
