import React from "react";
import { Redirect, Route, Switch } from "react-router"; // react-router v4/v5
import { Forgotpwd, Login, PasswordValidation, Signup } from "Funnel";

function NotAuthentifiedRouter() {
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/signup" exact component={Signup} />
      <Route path="/forgotpwd" exact component={Forgotpwd} />
      <Route path="/new-password/:code" exact component={PasswordValidation} />

      <Route path="/:partner_slug/login" exact component={Login} />
      <Route path="/:partner_slug/signup" exact component={Signup} />
      <Route path="/:partner_slug/forgotpwd" exact component={Forgotpwd} />
      <Route path="/:partner_slug/new-password/:code" exact component={PasswordValidation} />

      <Redirect from="/" to="/login" />
    </Switch>
  );
}

export default NotAuthentifiedRouter;
