import React from "react";
import { Department } from "shared/services/enums";
import { SelectInput } from "shared/components";

function DepartmentInput({
  label,
  placeholder,
  value,
  onChange,
  noUpLabel,
  errorMessage,
}) {
  return (
    <SelectInput
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      lst={Department}
      noUpLabel={noUpLabel}
      errorMessage={errorMessage}
    />
  );
}

export default DepartmentInput;
