import { createSlice } from "@reduxjs/toolkit";
import { userLoggedOut } from "store/reducers/auth";

const init = {
  name: "", //Dénomination sociale
  legal_form: "", //Forme juridique
  legal_form_other: "",
  activity_description: "",

  category: "",
  category_other: "",
  share_capital: "", //capital Social
  ape_code: "", // Code APE
  siren: "", // siren
  registered_on: "", //Date de création
  holding: false,
  fr_company: false,
  us_company: false,
  active_company: true
};

const selectFields = (src) => {
  const dst = {
    name: src.name ?? "",
    legal_form: src.legal_form ?? "",
    legal_form_other: src.legal_form_other ?? "",
    category: src.category ?? "",
    category_other: src.category_other ?? "",
    share_capital: src.share_capital ?? "",
    ape_code: src.ape_code ?? "",
    siren: src.siren ?? "",
    activity_description: src.activity_description ?? "",
    registered_on: src.registered_on ?? "",
    holding: !!src.holding,
    fr_company: !!src.fr_company,
    us_company: !!src.us_company,
    active_company: !!src.active_company,
  };
  return dst;
};

const slice = createSlice({
  name: "identification",
  initialState: {
    val: { ...init },
    neverUploaded: false,
  },
  reducers: {
    identificationUpdated: (state, { payload }) => {
      state.val = selectFields(payload);
    },
    identificationUpdateFailed: (state, { payload }) => {
      state.error422 = payload.error422;
      state.apiUplaodError = payload.message;
    },
  },
  extraReducers: {
    initDashboard: (state, { payload }) => {
      state.neverUploaded = !payload.company;
      if (!state.neverUploaded) {
        state.val = selectFields(payload.company);
      }
    },
    updatedCompany: (state, { payload }) => {
      state.neverUploaded = false;
      state.val = selectFields(payload);
      state.neverUploaded = false;
    },
    [userLoggedOut.type]: (state, { payload }) => {
      state = null;
    },
  },
});
export const { identificationUpdated } = slice.actions;
export default slice.reducer;
