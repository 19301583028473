import React from "react";
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import "shared/styles/css/offer-card.scss";

const OfferCard = ({title, subtitle, bodyList, color, buttonCallback, gridLink, disabled, notice}) => {
  return (
    <div className={`offer-card ${disabled ? 'disabled' : color}`}>
      <div>
        <h3>
          {title}
        </h3>
        <div style={{ opacity: 0.6 }}>
          {subtitle}
        </div>
      </div>
      <div className='offer-card-body'>
        <ul>
          {bodyList.map((e, i) => (<li key={i}>{e}</li>))}
        </ul>
      </div>
      <div className="offer-card-footer">
        <Button
          disabled={disabled}
          className="btn40"
          variant="contained"
          size="large"
          fullWidth
          disableElevation
          color={color}
          onClick={buttonCallback}
        >
          Ouvrir un CAT
        </Button>
        <Link href={gridLink} target="_blank" color="inherit" underline="always">
          Voir la grille des taux
        </Link>
        {notice &&
          <div
            className={`alert alert-${notice.importance} alert-icon-${notice.importance === 'danger' ? 'danger' : 'i'}`}
            style={{color: '#222226', marginBottom: '0px'}}
          >
            {notice.text}
          </div>
        }
      </div>
    </div>
  );
};

export default OfferCard;

