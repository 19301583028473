import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi";
import { errorMessage, getCompanyData } from "shared/utilities";
import { apiCompanyPost, clearUi } from "store/reducers/dashboard/company/iban";
import { Button, GlobalErrorMessage, IbanInput, TextInput, } from "shared/components";
import { history } from "store";
import WrapPage from "Funnel/Components/WrapPage";
import { electronicFormatIBAN, validateIBAN } from "ibantools";

const isValidIban = (value, helpers) => {
  const formatedIban = electronicFormatIBAN(value);
  const validation = validateIBAN(formatedIban);
  if (!validation.valid) {
    return helpers.error("iban.invalid");
  }
  return value;
}

const Iban = ({ match }) => {
  const dispatch = useDispatch();
  const company = useSelector((store) => store.dashboard.company);
  const [ibanState, setIbanState] = useState(company.iban.payload);
  const [badField, setBadField] = useState(null);
  const isUpdate = match.params.action === "update";

  useEffect(() => {
    return () => {
      //distructor
      dispatch(clearUi());
    };
  }, [dispatch]);
  const { apiPostErrors, error422 } = company.iban;

  async function validateForm(obj) {
    const schema = Joi.object({
      iban: Joi.string().required().custom(isValidIban),
      bic: Joi.string().required(),
    });
    const { error } = schema.validate(obj);
    return error
      ? {
        name: error.details[0].path[0],
        type: error.details[0].type,
        message: error.details[0].type === "iban.invalid" ? "Cela ne ressemble pas à un IBAN valide." : null
      }
      : null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = await validateForm(ibanState);
    setBadField(error);

    if (!error) {
      dispatch(
        apiCompanyPost({
          data: { ...getCompanyData(company), ...ibanState },
          successLink: isUpdate ? "previous" : "/legal-representative",
          neverUploaded: isUpdate
            ? false
            : company.identification.neverUploaded,
        })
      );
    }
  };

  return (
    <WrapPage>
      {!isUpdate && <div className="sectionName">1/4 — Entreprise</div>}
      <div className="formW">
        <div className="formH">
          <div className="goBack" onClick={() => history.goBack()}>
            <i className="icon-arrow-back" />
          </div>
          <div className="formTitle">IBAN</div>
        </div>

        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="formDsc">
            C’est sur cet IBAN que nous enverrons les fonds en cas de clôture
            anticipée ou d’arrivée au terme d’un de vos comptes.
          </div>
          <IbanInput
            name="iban"
            value={ibanState.iban}
            onChange={(e) => setIbanState({ ...ibanState, iban: e.target.value })}
            errorMessage={errorMessage("iban", badField, error422, badField && badField.message ? badField.message : null)}
          />
          <TextInput
            label="BIC"
            name="bic"
            value={ibanState.bic}
            onChange={(e) =>
              setIbanState({ ...ibanState, bic: e.target.value })
            }
            // cut the bic prefix of the error message if exist
            errorMessage={errorMessage("bic", badField, error422) && errorMessage("bic", badField, error422).replace("Bic ", "")}
          />
          {apiPostErrors && (
            <GlobalErrorMessage>{apiPostErrors}</GlobalErrorMessage>
          )}

          <Button type="submit" variant="contained" primary>
            {isUpdate ? "Enregistrer" : "Suivant"}
          </Button>
        </form>
      </div>
    </WrapPage>
  );
};

export default Iban;
