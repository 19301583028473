import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

function CheckBx({ name, label, checked, onChange, errorMessage, helper }) {
  return (
    <div className="checkBoxW">
      <FormControl error={!!errorMessage}>
        <FormControlLabel
          className="LabelCheckBox"
          // name={name}
          control={
            <Checkbox
              // name={name}
              checked={checked}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              color="primary"
            />

          }
          label={label}
        />
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        {helper && <FormHelperText>{helper}</FormHelperText>}
      </FormControl>
    </div>
  );
}

export default CheckBx;
