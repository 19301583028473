import React from "react";

const DeleteErrorMessage = () => {
  return (
    <div style={{ lineHeight: "19.2px" }}>
      <div>
        Étes vous certain(e) de vouloir supprimer votre adresse de
        correspondance ? L’adresse du siège ci-dessous deviendra votre adresse
        principale.
      </div>

      <div style={{ fontWeight: "500", marginTop: "0.5rem" }}>
        128 rue la Boétie
        <br />
        75009 Paris
        <br />
        France
      </div>
    </div>
  );
};

export default DeleteErrorMessage;
