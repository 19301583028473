import React from "react";
import { useDispatch } from "react-redux";
import { showAccountInfo } from "store/reducers/ui/investments/accounts";
import {
  IS_ACTIVE_ACTIVE,
  IS_ACTIVE_CLOSING,
  IS_CLOSED_CLOSED,
  IS_CLOSED_CLOSING,
  IS_CLOSING_ACCEPTED,
  IS_FINISHED,
  IS_PENDING,
} from "shared/enums/AccountStatus";
import { CFCAL_SLUG, MMB_SLUG, WORMSER_SLUG } from "../../../../../config.json"
import { Maturity, Percentage, Price } from "shared/components";
import { maturityAsInt } from "shared/utilities";
import "./accountCards.scss";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";

const StyledChip = styled(Chip)((props) => ({
  ...(props.color === MMB_SLUG &&
    {
      backgroundColor: '#E0EDFF',
      color: '#003585',
    }
  ),
  ...(props.color === WORMSER_SLUG &&
    {
      backgroundColor: '#DFF5A3',
      color: '#3C5200',
    }
  ),
  ...(props.color === CFCAL_SLUG &&
    {
      backgroundColor: '#FCEACA',
      color: '#932D00',
    }
  ),
  fontWeight: 'bold',
  borderRadius: '4px',
  padding: '4px',
}))

const PendingCard = ({ account }) => {
  return (
    <div className={`procCardW disabled ${account.provider.slug} pending`}>
      <div className="procCardL">
        <StyledChip label={account.provider.short_name} color={account.provider.slug} size="small" />
        <div>
          <div className="priceW">
            <Price value={account.amount} />
          </div>
          <div>
            <div>
              Compte à <Maturity months={maturityAsInt(account.maturity)} />
            </div>
            <div>
              Rendement de <Percentage value={account.interest_rate} />
            </div>
          </div>
        </div>
        <div>
         En cours d'ouverture
        </div>
      </div>
      <div className="procCardR">
        <i className="icon-hourglass" />
      </div>
    </div>
  );
};
const ActiveCard = ({ account, onClick }) => {
  return (
    <div className={`procCardW ${account.provider.slug} active`} onClick={onClick}>
      <div className="procCardL">
        <StyledChip label={account.provider.short_name} color={account.provider.slug} size="small" />

        <div>
          <div className="priceW">
            <Price value={account.amount} />
          </div>
          <div>
            <div>
              Compte à <Maturity months={maturityAsInt(account.maturity)} />
            </div>
            <div>
              Rendement de <Percentage value={account.interest_rate} />
            </div>
          </div>
        </div>
      </div>
      <div className="procCardR">
        <i className="icon-chevron-right" />
      </div>
    </div>
  );
};
const ActiveClosingCard = ({ account, onClick }) => {
  return (
    <div className={`procCardW ${account.provider.slug} active`} onClick={onClick}>
      <div className="procCardL">
        <StyledChip label={account.provider.short_name} color={account.provider.slug} size="small" />

        <div>
          <div className="priceW">
            <Price value={account.amount} />
          </div>
          <div>
            <div>
              Compte à <Maturity months={maturityAsInt(account.maturity)} />
            </div>
            <div>
              Rendement de <Percentage value={account.interest_rate} />
            </div>
          </div>
        </div>
        <div>En cours de clôture : en attente de signature</div>
      </div>
      <div className="procCardR">
        <i className="icon-chevron-right" />
      </div>
    </div>);
};
const ClosedClosingCard = ({ account, onClick }) => {
  return (
    <div className={`procCardW ${account.provider.slug} active`} onClick={onClick}>
      <div className="procCardL">
        <StyledChip label={account.provider.short_name} color={account.provider.slug} size="small" />
        <div>
          <div className="priceW">
            <Price value={account.amount} />
          </div>
          <div>Demande de clôture reçue</div>
        </div>
      </div>
      <div className="procCardR">
        <i className="icon-chevron-right" />
      </div>
    </div>);
};
const ClosedCard = ({ account, onClick }) => {
  return (
    <div className={`procCardW ${account.provider.slug} status_closed_closed`} onClick={onClick}>
      <div className="procCardL">
        <StyledChip label={account.provider.short_name} color={account.provider.slug} size="small" />
        <div>
          <div className="priceW">
            <Price value={account.amount} />
          </div>
          <div>Compte clôturé</div>
        </div>
      </div>
      <div className="procCardR">
        <i className="icon-chevron-right" />
      </div>
    </div>);
};

function AccountCard({ account, onClick }) {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(showAccountInfo({ account }));

  if (IS_PENDING(account)) return <PendingCard account={account} />;
  if (IS_ACTIVE_ACTIVE(account))
    return <ActiveCard account={account} onClick={handleClick} />;
  if (IS_ACTIVE_CLOSING(account))
    return <ActiveClosingCard account={account} onClick={handleClick} />;
  if (IS_CLOSED_CLOSING(account))
    return <ClosedClosingCard account={account} onClick={handleClick} />;
  if (IS_CLOSED_CLOSED(account) || IS_CLOSING_ACCEPTED(account) || IS_FINISHED(account))
    return <ClosedCard account={account} onClick={handleClick} />;
  //else
  return null; //normally not reachalble
}

export default AccountCard;
