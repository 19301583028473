import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi";
import { history } from "store";
import { Button, CheckBox, GlobalErrorMessage } from "shared/components";
import * as Parts from "../Parts";
import { apiCompanyValidation, cleanApiErrors, } from "store/reducers/dashboard/procedure";

import "./confirmSettings.scss";

function SettingsConfirm({ match, location }) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(cleanApiErrors());
  }, [dispatch]);
  const { pathname } = location;

  const { containsEmptyFields } = useSelector(
    (s) => s.ui.investments.procedure
  );
  const { companyValidation } = useSelector((s) => s.dashboard.procedure);
  const dashboard = useSelector((store) => store.dashboard);
  const isIndividualCompany =
    dashboard.company.identification.val.legal_form === "individual_enterprise";
  const uboNotNeeded = isIndividualCompany || dashboard.company.identification.val.active_company

  const apiValidationFailed = companyValidation && !companyValidation.valid;

  const [badField, setBadField] = useState(null);
  const [state, setState] = useState({
    infoExact: false,
  });

  async function validateForm() {
    const schema = Joi.object({
      infoExact: Joi.boolean().valid(true),
    });
    const { error } = schema.validate(state);
    return error
      ? { name: error.details[0].path[0], type: error.details[0].type }
      : null;
  }

  const preFilled = match.params.prefilled;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = await validateForm();
    setBadField(error);

    if (!error) {
      dispatch(apiCompanyValidation({ successLink: "/recap-accounts-adding" }));
    }
  };

  return (
    <div className="bigImgWrapper">
      <div className="deskWrapper">
        <div className="pageContent">
          <div className="zoneW">
            <div className="zH">
              <div className="goBack" onClick={() => history.goBack()}>
                <i className="icon-arrow-back" />
              </div>
              <div className="zTitle">Récapitulatif</div>
            </div>
            {preFilled && (
              <div className="alert alert-warning alert-icon-i">
                Nous avons pré-rempli votre dossier avec les informations
                transmises par Qonto. Nous vous laissons les vérifier et les
                compléter si-besoin.
              </div>
            )}
            {!preFilled && !containsEmptyFields && (
              <div className="alert alert-success alert-icon-ok">
                Votre dossier est complet. Vérifiez vos informations
                soigneusement et validez-les.
              </div>
            )}

            <div className="zBdy ">
              <Parts.EditCompanyDescription pathName={pathname} />
              <Parts.EditCompanyRepresentants pathName={pathname} />
              {!uboNotNeeded && <Parts.EditCompanyBenificiaries />}
              <Parts.EditCompanyDocuments confirm={true}/>

              <form
                className="validateForm"
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <CheckBox
                  name="infoExact"
                  checked={state.infoExact}
                  onChange={(v) =>
                    setState({
                      ...state,
                      infoExact: v,
                    })
                  }
                  label="Je certifie que ces informations sont exactes."
                  errorMessage={
                    badField && badField.name === "infoExact"
                      ? "Vous devez certifier vos informations pour continuer"
                      : null
                  }
                />

                {apiValidationFailed && companyValidation.message && (
                  <GlobalErrorMessage>
                    {companyValidation.message}
                  </GlobalErrorMessage>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  primary
                  disabled={apiValidationFailed}
                >
                  Valider mon dossier Cashbee Pro
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsConfirm;
