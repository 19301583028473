import { combineReducers } from "redux";
import company from "./company";
import investments from "./investments";
import utils from "./utils";

const reducer = combineReducers({
  company,
  investments,
  utils
});
export default reducer;
