import { createSlice } from "@reduxjs/toolkit";
import { maturityAsInt } from "shared/utilities";
import { apiCall } from "store/api";

const slice = createSlice({
  name: "data",
  initialState: {
    RateGrids: [],
  },
  reducers: {
    setRateGrids: (state, action) => {
      state.RateGrids = action.rates;
    },
    initDataFromServer: (state, { payload }) => {
      state.RateGrids = payload.map((x) => {
        return {
          id: x.id,
          nominal_maturity: maturityAsInt(x.nominal_maturity),
          interest_rate: parseFloat(x.interest_rate),
          nominal_rate: parseFloat(x.nominal_rate),
        };
      });
    },
  },
});
export const loadData = () =>
  apiCall({
    url: "/rate_grids",
    onSuccess: slice.actions.initDataFromServer.type,
  });

export const { setRateGrids } = slice.actions;
export default slice.reducer;
