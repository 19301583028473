import React from "react";
import { Percentage, Price } from "shared/components";
import { maturityAsInt, rendementActuarial } from "shared/utilities";

export const Rendement = ({ maturity, rateGrids }) => {
  const rendement = (maturity) => {
    const r = rateGrids.find((x) => maturityAsInt(x.nominal_maturity) === maturity);
    return r ? r.interest_rate : undefined;
  };
  const v = rendement(parseInt(maturity));
  return <Percentage value={v} />;
};

export const NominalRate = ({ maturity, rateGrids }) => {
  const rendement = (maturity) => {
    const r = rateGrids.find((x) => maturityAsInt(x.nominal_maturity) === maturity);
    return r ? r.nominal_rate : undefined;
  };
  const v = rendement(parseInt(maturity));
  return <Percentage value={v} />;
};

export const RendementActuarial = ({ amount, maturity, number, rateGrids }) => {
  const v = rendementActuarial({rateGrids,  amount, maturity, number });

  return <Price value={v} />;
};
