import React from "react";
import { GenderArray } from "shared/services/enums";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

function PersonCivilityInput({ civility, onChecked, name, errorMessage }) {
  const [state, setState] = React.useState(civility);

  return (
    <FormControl component="fieldset" error={!!errorMessage}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        {Object.keys(GenderArray).map((k, i) => (
          <FormControlLabel
            key={k}
            value={k}
            label={GenderArray[k]}
            control={<Radio color="default" />}
            checked={state === k}
            onChange={(e) => {
              const newSt = e.target.value;
              if (state !== newSt) {
                setState(newSt);
                onChecked(newSt);
              }
            }}
            size="small"
          />
        ))}
      </div>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export default PersonCivilityInput;
