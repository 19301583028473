import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Joi from "joi";
import Forgotpwd from "./Forgotpwd";
import { Button, GlobalErrorMessage, TextInput } from "shared/components";
import WrapConnexionPage from "Funnel/Components/WrapConnexionPage";
import WrapForm from "./WrapForm";
import { apiLogin, setPartener } from "store/reducers/auth";
import { errorMessage } from "shared/utilities";

const Login = ({ match }) => {
  const dispatch = useDispatch();
  const ui = useSelector((store) => store.auth.ui);

  //partner_slug
  const partner_slug = match.params.partner_slug;
  if (partner_slug && partner_slug.length > 0) {
    dispatch(setPartener({ partner: partner_slug }));
  }
  const getLink = (path) => (partner_slug ? `/${partner_slug}${path}` : path);
  //

  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [badField, setBadField] = useState(null);

  async function validateForm() {
    const schema = Joi.object({
      password: Joi.string().required().min(8),
      email: Joi.string()
        .required()
        .email({
          tlds: { allow: false },
        }),
      okConditions: Joi.boolean().valid(true),
    });

    const { error } = schema.validate(user);
    return error
      ? { name: error.details[0].path[0], type: error.details[0].type }
      : null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = await validateForm();
    setBadField(error);

    if (!error) {
      dispatch(apiLogin(user));
    }
  };

  return (
    <WrapConnexionPage>
      <WrapForm title={ui.relogin ? "Reconnectez-vous" : "C’est bon de vous revoir !"} >
        {ui.relogin &&
        <div className="alert alert-success alert-icon-ok">
          Votre mot de passe est modifié, saisissez à nouveau vos identifiants pour vous connecter.
        </div>
        }

        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <TextInput
            name="email"
            label="Email"
            type="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            errorMessage={errorMessage(
              "email",
              badField,
              ui.loginError422,
              "Hmm, ça ne ressemble pas à un email valide"
            )}
          />
          <TextInput
            name="password"
            label="Mot de passe"
            type="password"
            autoComplete="current-password"
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            errorMessage={errorMessage(
              "password",
              badField,
              ui.loginError422,
              "8 caractères minimum"
            )}
          />

          {ui.loginApiError && (
            <GlobalErrorMessage>{ui.loginApiError}</GlobalErrorMessage>
          )}

          <Button type="submit" primary={true}>
            Se connecter
          </Button>
          <Link to={getLink("/forgotpwd")}>Mot de passe oublié ?</Link>
        </form>
      </WrapForm>

      <div className="ousideFormLink">
        <Link to={getLink("/signup")}>Je n’ai pas encore de compte</Link>
      </div>
    </WrapConnexionPage>
  );
};

export default Login;

export { Forgotpwd };
