import { createSlice, current } from "@reduxjs/toolkit";
import { userLoggedOut } from "store/reducers/auth";
import { DocumentFolder } from "shared/enums/DocumentsTypes";

const moreUploadNeeded = (docs) => {
  return docs.company_id.length === 0 || !docs.tax_return || !docs.status || !docs.rib;
};

const slice = createSlice({
  name: "company_moral",
  initialState: {
    docs: {
      company_id: [],
      tax_return: null,
      status: null,
      rib: null,
      funds_origin: null,
    },
    moreUploadNeeded: false,
  },
  reducers: {},
  extraReducers: {
    // TODO: Rework this reducers they should not update the state see: https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#reducers-and-immutable-updates
    initDashboard: (state, { payload }) => {
      if (
        !payload.company ||
        !payload.company.legal_form ||
        !payload.documents
      ) {
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
        return;
      }
      if (payload.company.legal_form !== "individual_enterprise") {
        payload.documents.forEach((doc) => {
          if (doc.owner_type.toLowerCase() === "company") {
            switch (doc.document_type) {
              case "company_registry":
              case "k_bis":
                state.docs.company_id = [...state.docs.company_id, doc];
                break;
              case "tax_return":
                state.docs.tax_return = doc;
                break;
              case "status":
                state.docs.status = doc;
                break;
              case "rib":
                state.docs.rib = doc;
                break;
              case "funds_origin":
                state.docs.funds_origin = doc;
                break;
              default:
                break;
            }
          }
        });
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    documentUploaded: (state, { payload }) => {
      if (payload.folder === DocumentFolder.company_moral.key) {
        switch (payload.document_type) {
          case "company_registry":
          case "k_bis":
            state.docs.company_id = [...state.docs.company_id, payload];
            break;
          case "tax_return":
            state.docs.tax_return = payload;
            break;
          case "status":
            state.docs.status = payload;
            break;
          case "rib":
            state.docs.rib = payload;
            break;
          case "funds_origin":
            state.docs.funds_origin = payload;
            break;
          default:
            break;
        }
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    documentDeleted: (state, { payload }) => {
      if (payload.folder === DocumentFolder.company_moral.key) {
        switch (payload.documentType) {
          case "company_registry":
          case "k_bis":
            state.docs.company_id = state.docs.company_id.filter(
              (x) => x.id !== payload.id
            );
            break;
          case "tax_return":
            if (state.docs.tax_return.id === payload.id) {
              state.docs.tax_return = null;
            }
            break;
          case "status":
            if (state.docs.status.id === payload.id) {
              state.docs.status = null;
            }
            break;
          case "rib":
            if (state.docs.rib.id === payload.id) {
              state.docs.rib = null;
            }
            break;
          case "funds_origin":
            if (state.docs.funds_origin.id === payload.id) {
              state.docs.funds_origin = null;
            }
            break;
          default:
            break;
        }
        state.moreUploadNeeded = moreUploadNeeded(current(state).docs);
      }
    },
    [userLoggedOut.type]: (state, { payload }) => {
      state = null;
    },
  },
});

export const { documentUploaded } = slice.actions;
export default slice.reducer;
