import { combineReducers } from "redux";
import company_individual from "./company_individual";
import company_moral from "./company_moral";
import representative from "./representative";
import interlocutor from "./interlocutor";
import beneficiaries from "./beneficiaries";

export default combineReducers({
  company_individual,
  company_moral,
  representative,
  interlocutor,
  beneficiaries,
});
