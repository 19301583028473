import React from "react";
import { Link } from "react-router-dom";
import * as Parts from "../Parts";
import { history } from "store";
import "./setting-board.scss";
import { useDispatch } from "react-redux";
import { logout } from "store/reducers/auth";

const ENTREPRISE = "entreprise";
const REPRESENTANTS = "representant";
const CONTROLE = "controle";
const DOCUMENTS = "documents";

function SettingBoard({ match, location }) {
  const dispatch = useDispatch();
  const tab = match.params.tab;
  const { pathname } = location;


  const activeClass = (x) => {
    return tab === x ? "active" : "";
  };
  return (
    <div className="bigImgWrapper">
      <div className="deskWrapper">
        <div className="pageContent">
          <div className="dshMain dPrmW">
            <div className="settingHeader">
              <div className="goBackHeader">
                <div
                  className="goBack"
                  onClick={() => history.push("/accounts")}
                >
                  <i className="icon-arrow-back" />
                </div>
                <div className="formTitle">Mon entreprise</div>
              </div>
              <div className="logoutBtn" onClick={() => dispatch(logout())}>
                <i className="icon icon-exit-to-app" />{" "}
                <span className="onLargeMedia" style={{ marginLeft: "13px" }}>
                  Me déconnecter
                </span>
              </div>
            </div>
            <div className="dMBdyW">
              <div className="dPrmTabW">
                <div className="dPrmTab">
                  <Link
                    to={`/settings/${DOCUMENTS}`}
                    className={`dPrmTabI ${activeClass(DOCUMENTS)}`}
                  >
                    Documents
                  </Link>
                  <Link
                    to={`/settings/${ENTREPRISE}`}
                    className={`dPrmTabI ${activeClass(ENTREPRISE)}`}
                  >
                    Entreprise
                  </Link>
                  <Link
                    to={`/settings/${REPRESENTANTS}`}
                    className={`dPrmTabI ${activeClass(REPRESENTANTS)}`}
                  >
                    Interlocuteurs
                  </Link>
                  <Link
                    to={`/settings/${CONTROLE}`}
                    className={`dPrmTabI ${activeClass(CONTROLE)}`}
                  >
                    Contrôle
                  </Link>
                </div>
              </div>
              {" "}
              <div className="dPrmBdy">
                {tab === ENTREPRISE ? (
                  <Parts.EditCompanyDescription pathName={pathname} />
                ) : tab === REPRESENTANTS ? (
                  <Parts.EditCompanyRepresentants pathName={pathname} />
                ) : tab === CONTROLE ? (
                  <Parts.EditCompanyBenificiaries />
                ) : tab === DOCUMENTS ? (
                  <Parts.EditCompanyDocuments />
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          <div className="dshSub"></div>
        </div>
      </div>
    </div>
  );
}

export default SettingBoard;
