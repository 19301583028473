import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "documents",
  initialState: {
    company_individual: {
      fieldNames: {
        info_greffe: { label: "Extrait KBIS", type: "document", isArray: true },
        tax_returns: {
          label: "Liasse fiscale",
          type: "document",
          isArray: true,
        },
        rib: { label: "RIB", type: "document" },
        funds_origin: { label: "Origine des fonds", type: "document", optional: true },
      },
    },
    company_moral: {
      fieldNames: {
        company_id: { label: "Immatriculation de l'entreprise", type: "document", isArray: true },
        tax_return: { label: "Liasse fiscale", type: "document" },
        status: { label: "Statuts de l’entreprise", type: "document" },
        rib: { label: "RIB", type: "document" },
        funds_origin: { label: "Origine des fonds", type: "document", optional: true },
      },
    },
    representative: {
      fieldNames: {
        id_docs: {
          label: "Pièce d’identité du représentant",
          type: "document",
          isArray: true,
        },
      },
    },
    interlocutor: {
      fieldNames: {
        id_docs: {
          label: "Pièce d’identité du représentant",
          type: "document",
          isArray: true,
        },
        power_copy: {
          label: "Mandat pour gestion Cashbee pro",
          type: "document",
        },
      },
    },
  },
  reducers: {},
});

// export const {  } = slice.actions;
export default slice.reducer;
