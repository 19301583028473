import React from "react";
import "../signature.scss";


function Signed({ delayInformation }) {
  return (
    <>
      <h3 className="signSecH">Votre demande d’ouverture de compte à terme à bien été prise en compte</h3>
      <div className="signSecBdy">
        Elle doit maintenant être validée par nos équipes ainsi que notre partenaire bancaire.
      </div>
      {!!delayInformation &&
        <div className="alert alert-warning alert-icon-i" style={{ marginTop: '24px' }}>
          {delayInformation}
        </div>}
    </>
  );
}

export default Signed;
