import React, { useState } from "react";
import { CountryInput, GooglePlacesAutocomplete, TextInput } from "shared/components";
import { errorMessage } from "shared/utilities";

const AddressBody = ({ badField, error422, address, onAddressChange }) => {
  const [state, setState] = useState(address);

  return (
    <React.Fragment>
      <GooglePlacesAutocomplete
        label="Adresse"
        type="json_address"
        name="address"
        value={state}
        onChange={(v) => {
          const newSt = { ...state, ...v };
          setState(newSt);
          onAddressChange(newSt);
        }}
        errorMessage={errorMessage("address", badField, error422)}
      />
      <TextInput
        label="Complément d’adresse"
        name="second_line"
        type="text"
        value={state.second_line}
        onChange={(e) => {
          const newSt = { ...state, second_line: e.target.value };
          setState(newSt);
          onAddressChange(newSt);
        }}
        errorMessage={errorMessage("second_line", badField, error422)}
      />
      <TextInput
        label="Code postal"
        name="postcode"
        type="text"
        value={state.postcode}
        onChange={(e) => {
          const newSt = { ...state, postcode: e.target.value };
          setState(newSt);
          onAddressChange(newSt);
        }}
        errorMessage={errorMessage("postcode", badField, error422)}
      />
      <TextInput
        label="Ville"
        name="city"
        variant="outlined"
        type="text"
        value={state.city}
        onChange={(e) => {
          const newSt = { ...state, city: e.target.value };
          setState(newSt);
          onAddressChange(newSt);
        }}
        errorMessage={errorMessage("city", badField, error422)}
      />
      <CountryInput
        label="Pays"
        name="country"
        value={state.country}
        onChange={(v) => {
          const newSt = { ...state, country: v };
          setState(newSt);
          onAddressChange(newSt);
        }}
        errorMessage={errorMessage("country", badField, error422)}
      />
      {/* <TextInput
        label="Téléphone fixe"
        type="phone"
        value={state.mobile_phone}
        onChange={(e) => {
          const newSt = { ...state, mobile_phone: e.target.value };
          setState(newSt);
          onAddressChange(newSt);
        }}
      /> */}
    </React.Fragment>
  );
};

export default AddressBody;
