import React, { useState } from "react";
import { history } from "store";
import { useDispatch, useSelector } from "react-redux";
import { MMB_SLUG } from "../../config.json"
import Joi from "joi";
import { Button, CheckBox, HalfLineField, Price, SelectInput, TextInput, } from "shared/components";
import { FundOrigin } from "shared/services/enums";
import { Accordion, AccordionDetails, AccordionSummary, } from "shared/components/Accordion";
import { setFormalities } from "store/reducers/dashboard/procedure";

const REQUIRED_INFO_ERR = "Information requise";

function ProcedureFormalities() {
  const dispatch = useDispatch();
  const {
    bankingSecret,
    onlyFrance,
    notFinancialCompany,
    fund_origin,
    fund_origin_other,
    totalAmount,
    providerSlug,
  } = useSelector((store) => store.dashboard.procedure);
  const provider = useSelector((s) => s.dashboard.providers.find((p) => p.slug === providerSlug));
  const isMMB = providerSlug === MMB_SLUG;

  const [state, setState] = useState({
    bankingSecret: isMMB ? bankingSecret : true,
    onlyFrance: isMMB ? onlyFrance : true,
    notFinancialCompany: isMMB ? notFinancialCompany : true,
    fund_origin,
    fund_origin_other,
  });
  const [badField, setBadField] = useState(null);

  const defaultPanel = isMMB ? "bankingSecretPanel" : "fundOriginsPanel";
  const [expanded, setExpanded] = useState(defaultPanel);
  const handlePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const bankingSecretChanged = ({ value }) => {
    setState({ ...state, bankingSecret: value });
    if (value) {
      if (!state.notFinancialCompany || !state.onlyFrance)
        setExpanded("autoCertificationPanel");
      else setExpanded("fundOriginsPanel");
    }
  };

  const onlyFranceChanged = ({ value }) => {
    setState({ ...state, onlyFrance: value });
    if (value && state.notFinancialCompany) {
      if (!state.bankingSecret) setExpanded("bankingSecretPanel");
      else setExpanded("fundOriginsPanel");
    }
  };
  const notFinancialCompanyChanged = ({ value }) => {
    setState({ ...state, notFinancialCompany: value });
    if (value && state.onlyFrance) {
      if (!state.bankingSecret) setExpanded("bankingSecretPanel");
      else setExpanded("fundOriginsPanel");
    }
  };

  async function validateForm(obj) {
    const schema = Joi.object({
      bankingSecret: Joi.boolean().invalid(false),
      notFinancialCompany: Joi.boolean().invalid(false),
      onlyFrance: Joi.boolean().invalid(false),
      fund_origin: Joi.string().valid("asset_disposal", "cash_excess", "other"),
      fund_origin_other: Joi.when("fund_origin", {
        is: "other",
        then: Joi.string().required(),
      }),
    });
    const { error } = schema.validate(obj);
    return error
      ? { name: error.details[0].path[0], type: error.details[0].type }
      : null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = await validateForm(state);
    setBadField(error);
    if (error) {
      if (!state.bankingSecret) setExpanded("bankingSecretPanel");
      else if (!state.onlyFrance || !state.notFinancialCompany)
        setExpanded("autoCertificationPanel");
      else setExpanded("fundOriginsPanel");
    }
    if (!error) {
      dispatch(setFormalities(state));
      if (provider.procedure_validation.ubo_needed) {
        history.push(`${providerSlug}/benificiaries/`);
      } else {
        history.push("/settings-confirm");
      }
    }
  };

  return (
    <div className="bigImgWrapper">
      <div className="deskWrapper">
        <div className="pageContent">
          <div className="dshMain minHeight grey">
            <div className="goBackHeader">
              <div className="goBack" onClick={() => history.goBack()}>
                <i className="icon-arrow-back" />
              </div>
              <div className="formTitle">Formalités</div>
            </div>
            <div className="dMBdyW justifyTop">
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <div className="dMBdy">
                  {isMMB &&
                    (<>
                      <Accordion
                        expanded={expanded === "bankingSecretPanel"}
                        onChange={handlePanel("bankingSecretPanel")}
                      >
                        <AccordionSummary>Secret bancaire</AccordionSummary>
                        <AccordionDetails>
                          <div>
                            Vous (Le Client) autorisez la levée du secret bancaire
                            auquel est soumis My Money Bank à l’égard de Cashbee et
                            autorise la communication, auprès de lui, des
                            informations suivantes :
                            <ul>
                              <li>Dénomination ou raison sociale,</li>
                              <li>Nom,</li>
                              <li>Prénom,</li>
                              <li>Adresse,</li>
                              <li>Numéro et type de compte,</li>
                              <li>Date d’ouverture,</li>
                              <li>Date de clôture,</li>
                              <li>Solde journalier.</li>
                            </ul>
                            Certaines de ces informations sont déjà connues de
                            Cashbee. D’une manière générale, vous acceptez que
                            Cashbee ait accès aux opérations sur votre compte via
                            l’extranet de My Money Bank. Vous pouvez à tout moment
                            révoquer cette levée du secret bancaire en notifiant
                            cette révocation au département Consommateurs de My
                            Money Bank - API 24D2 - 1 rue du Château de l’Eraudière
                            - 44311 Nantes Cedex 3, accompagné d’une copie de sa
                            pièce d’identité.
                          </div>

                          <CheckBox
                            checked={state.bankingSecret}
                            name="bankingSecret"
                            onChange={(value) => {
                              bankingSecretChanged({
                                value,
                              });
                            }}
                            label="J’ai lu et j’accepte les conditions ci-dessus"
                            errorMessage={
                              badField && badField.name === "bankingSecret"
                                ? "Vous devez accepter les conditions pour continuer"
                                : null
                            }
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "autoCertificationPanel"}
                        onChange={handlePanel("autoCertificationPanel")}
                      >
                        <AccordionSummary>Auto certification</AccordionSummary>
                        <AccordionDetails>
                          <CheckBox
                            checked={state.onlyFrance}
                            onChange={(value) =>
                              onlyFranceChanged({
                                value,
                              })
                            }
                            name="onlyFrance"
                            label="La France est la seule résidence fiscale de votre entreprise"
                            errorMessage={
                              badField && badField.name === "onlyFrance"
                                ? "Vous devez confirmer pour continuer"
                                : null
                            }
                          />
                          <CheckBox
                            checked={state.notFinancialCompany}
                            onChange={(value) =>
                              notFinancialCompanyChanged({
                                value,
                              })
                            }
                            name="notFinancialCompany"
                            label="Votre entreprise n’est pas une société financière"
                            errorMessage={
                              badField && badField.name === "notFinancialCompany"
                                ? "Vous devez confirmer pour continuer"
                                : null
                            }
                          />
                        </AccordionDetails>
                      </Accordion>
                    </>)}
                  <Accordion
                    expanded={expanded === "fundOriginsPanel"}
                    onChange={handlePanel("fundOriginsPanel")}
                  >
                    <AccordionSummary>Origine des fonds</AccordionSummary>
                    <AccordionDetails>
                      <div className="accordionDetailContent">
                        <div style={{ marginBottom: "1rem" }}>
                          Vous êtes sur le point de transférer{" "}
                          <b>
                            <Price value={totalAmount} />
                          </b>
                          . Par mesure de sécurité, nous devons vous en demander
                          la provenance.
                        </div>

                        <SelectInput
                          label="Origine des fonds"
                          name="fund_origin"
                          value={state.fund_origin}
                          onChange={(v) =>
                            setState({ ...state, fund_origin: v })
                          }
                          lst={FundOrigin}
                          errorMessage={
                            badField && badField.name === "fund_origin"
                              ? REQUIRED_INFO_ERR
                              : null
                          }
                        />

                        {state.fund_origin === "other" && (
                          <HalfLineField label="Préciser">
                            <TextInput
                              label="Origine des fonds"
                              name="fund_origin_other"
                              type="text"
                              value={state.fund_origin_other}
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  fund_origin_other: e.target.value,
                                })
                              }
                              errorMessage={
                                badField &&
                                badField.name === "fund_origin_other"
                                  ? REQUIRED_INFO_ERR
                                  : null
                              }
                            />
                          </HalfLineField>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="dMSubmitBt">
                  <Button type="submit" primary>
                    Continuer
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div className="dshSub"></div>
        </div>
      </div>
    </div>
  );
}

export default ProcedureFormalities;
