import React from "react";
import { Country } from "shared/services/enums";
import { SelectInput } from "shared/components";

function CountryInput({ label, value, onChange, noUpLabel, errorMessage }) {
  return (
    <SelectInput
      label={label}
      value={value}
      onChange={onChange}
      lst={Country}
      noUpLabel={noUpLabel}
      errorMessage={errorMessage}
    />
  );
}

export default CountryInput;
