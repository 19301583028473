import React, { useEffect } from "react";
import { history } from "../../store";
import { useParams } from "react-router-dom";
import { procedureSignature, resetLink } from "../../store/reducers/dashboard/procedure";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

let interval;

function ProcedureEmbeddedSignature() {
  const { providerSlug } = useParams();
  const dispatch = useDispatch();
  const url = useSelector((store) => store.dashboard.procedure.url)

  useEffect(() => {
    if (url && interval) {
      clearInterval(interval);
      return;
    }
    interval = setInterval(() => {
      dispatch(procedureSignature({
        providerSlug,
      }));
    }, 500);

  }, [providerSlug, url, dispatch]);

  return (
    <div className="signWrapper">
      <div className="signDeskWrapper">
        <div className="signPageContent">
          <div className="dshMain minHeight grey signWrap">
            <div className="signHeaderW">
              <div className="signHeader">
                <div
                  className="closeBt"
                  onClick={() => {
                    dispatch(resetLink(null))
                    history.replace("/accounts")
                  }}
                >
                  <i className="icon-close" />
                </div>
                <div className="formTitle">Signature</div>
              </div>
            </div>

            <div className="dMBdyW">
              {url ?
                <iframe src={url} title="signature" style={{ height: '90%' }}></iframe>
                 : <Skeleton variant="rectangular" animation="wave" style={{ height: '90%' }} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcedureEmbeddedSignature;
