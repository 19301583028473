import apiClient from "./API/apiClient";

export const viewDocumentById = async (id, jwtToken) => {
  try {
    const { data } = await apiClient.post(`documents/${id}/file`, {}, { token: jwtToken });

    setTimeout(() => {
      window.open(data.url, "_blank").focus();
    })
  } catch (error) {
    //TODO
    console.log(error);
  }
};
