import React from "react";
import LogosZone from "shared/components/LogosZone";

function WrapConnexionPage({ children }) {
  return (
    <div className="funnelPgW">
      <div className="funnelPgContent">
        <div className="funnelPgL">
          <div className="funnelMainForm connexionPg">
            <div />
            <div className="formZone">{children}</div>
            <LogosZone connexionPg={true} />
            <div />
          </div>
        </div>
        <div className="funnelPgR"></div>
      </div>
    </div>
  );
}

export default WrapConnexionPage;
