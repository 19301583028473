import React from "react";
import Dialog from "@mui/material/Dialog";
import css from "./dialog.module.scss";

const CloseIcon = ({ onClick }) => {
  return (
    <div className={css.closeIcon} onClick={onClick}>
      <i className="icon-close" />
    </div>
  );
};

export const DialogTitle = ({ children }) => {
  return <div className={css.dialogTitle}>{children}</div>;
};

export const DialogContent = ({ children }) => {
  return <div className={css.dialogContent}>{children}</div>;
};

export const DialogActions = ({ children }) => {
  return <div className={css.dialogActions}>{children}</div>;
};

const DialogW = ({ children, open, onClose, closeIcon, className }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className={`${css.dialogW} ${className}`}>
        {closeIcon && <CloseIcon onClick={onClose} />}
        {children}
      </div>
    </Dialog>
  );
};

export default DialogW;
