export const GenderArray = {
  ms: "Madame",
  mr: "Monsieur",
};

export const RepresentativePositions = {
  president: "Président",
  director: "Directeur Général",
  manager: "Gérant",
  vice_president: "Vice Président",
  cfo: "Directeur Financier",
  liberal_profession: "Profession libérale",
  retailer: "Commerçant",
  artisan: "Artisan",
}

export const InterlocutorPositions = {
  finance: "Cadre Financier",
  treasurer: "Tresorier",
  accountant: "Comptable",
  ...RepresentativePositions
}

export const CompanyLegalForm = {
  SA: "Société anonyme",
  SAS: "Société par actions simplifiée",
  SARL: "Société à responsabilité limitée",
  SASU: "Société par actions simplifiée unipersonnelle",
  individual_enterprise: "Entreprise individuelle",
  other: "Autre",
};
export const FundOrigin = {
  asset_disposal: "Cession d'actifs",
  cash_excess: "Excédent de trésorerie",
  other: "Autre",
};
export const CompanyCategory = {
  public_company: "Société cotée en bourse",
  governmental_company: "Entreprise publique",
  private_company: "Entreprise privée",
  other: "Autre",
};
export const Country = {
  undefined: "",
  AF: "Afghanistan",
  ZA: "Afrique du Sud",
  AX: "Îles Åland",
  AL: "Albanie",
  DZ: "Algérie",
  DE: "Allemagne",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctique",
  AG: "Antigua-et-Barbuda",
  SA: "Arabie saoudite",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BY: "Biélorussie",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BO: "Bolivie",
  BQ: "Pays-Bas caribéens",
  BA: "Bosnie-Herzégovine",
  BW: "Botswana",
  BV: "Île Bouvet",
  BR: "Brésil",
  BN: "Brunei",
  BG: "Bulgarie",
  BF: "Burkina Faso",
  BI: "Burundi",
  KY: "Îles Caïmans",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  CV: "Cap-Vert",
  CF: "République centrafricaine",
  CL: "Chili",
  CN: "Chine",
  CX: "Île Christmas",
  CY: "Chypre",
  CC: "Îles Cocos",
  CO: "Colombie",
  KM: "Comores",
  CG: "République du Congo",
  CD: "République démocratique du Congo",
  CK: "Îles Cook",
  KR: "Corée du Sud",
  KP: "Corée du Nord",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  HR: "Croatie",
  CU: "Cuba",
  CW: "Curaçao",
  DK: "Danemark",
  DJ: "Djibouti",
  DO: "République dominicaine",
  DM: "Dominique",
  EG: "Égypte",
  SV: "Salvador",
  AE: "Émirats arabes unis",
  EC: "Équateur",
  ER: "Érythrée",
  ES: "Espagne",
  EE: "Estonie",
  US: "États-Unis",
  ET: "Éthiopie",
  FK: "Malouines",
  FO: "Îles Féroé",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  GS: "Géorgie du Sud-et-les îles Sandwich du Sud",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GD: "Grenade",
  GL: "Groenland",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernesey",
  GN: "Guinée",
  GW: "Guinée-Bissau",
  GQ: "Guinée équatoriale",
  GY: "Guyana",
  GF: "Guyane",
  HT: "Haïti",
  HM: "Îles Heard-et-MacDonald",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hongrie",
  IM: "Île de Man",
  UM: "Îles mineures éloignées des États-Unis",
  VG: "Îles Vierges britanniques",
  VI: "Îles Vierges des États-Unis",
  IN: "Inde",
  ID: "Indonésie",
  IR: "Iran",
  IQ: "Irak",
  IE: "Irlande",
  IS: "Islande",
  IL: "Israël",
  IT: "Italie",
  JM: "Jamaïque",
  JP: "Japon",
  JE: "Jersey",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kirghizistan",
  KI: "Kiribati",
  KW: "Koweït",
  LA: "Laos",
  LS: "Lesotho",
  LV: "Lettonie",
  LB: "Liban",
  LR: "Liberia",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macédoine du Nord",
  MG: "Madagascar",
  MY: "Malaisie",
  MW: "Malawi",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MP: "Îles Mariannes du Nord",
  MA: "Maroc",
  MH: "Îles Marshall",
  MQ: "Martinique",
  MU: "Maurice",
  MR: "Mauritanie",
  YT: "Mayotte",
  MX: "Mexique",
  FM: "États fédérés de Micronésie",
  MD: "Moldavie",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MZ: "Mozambique",
  MM: "Birmanie",
  NA: "Namibie",
  NR: "Nauru",
  NP: "Népal",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Île Norfolk",
  NO: "Norvège",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  IO: "Territoire britannique de l'océan Indien",
  OM: "Oman",
  UG: "Ouganda",
  UZ: "Ouzbékistan",
  PK: "Pakistan",
  PW: "Palaos",
  PS: "Palestine",
  PA: "Panama",
  PG: "Papouasie-Nouvelle-Guinée",
  PY: "Paraguay",
  NL: "Pays-Bas",
  PE: "Pérou",
  PH: "Philippines",
  PN: "Îles Pitcairn",
  PL: "Pologne",
  PF: "Polynésie française",
  PR: "Porto Rico",
  PT: "Portugal",
  QA: "Qatar",
  RE: "La Réunion",
  RO: "Roumanie",
  GB: "Royaume-Uni",
  RU: "Russie",
  RW: "Rwanda",
  EH: "République arabe sahraouie démocratique",
  BL: "Saint-Barthélemy",
  KN: "Saint-Christophe-et-Niévès",
  SM: "Saint-Marin",
  MF: "Saint-Martin",
  SX: "Saint-Martin",
  PM: "Saint-Pierre-et-Miquelon",
  VA: "Saint-Siège (État de la Cité du Vatican)",
  VC: "Saint-Vincent-et-les-Grenadines",
  SH: "Sainte-Hélène, Ascension et Tristan da Cunha",
  LC: "Sainte-Lucie",
  SB: "Îles Salomon",
  WS: "Samoa",
  AS: "Samoa américaines",
  ST: "Sao Tomé-et-Principe",
  SN: "Sénégal",
  RS: "Serbie",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapour",
  SK: "Slovaquie",
  SI: "Slovénie",
  SO: "Somalie",
  SD: "Soudan",
  SS: "Soudan du Sud",
  LK: "Sri Lanka",
  SE: "Suède",
  CH: "Suisse",
  SR: "Suriname",
  SJ: "Svalbard et ile Jan Mayen",
  SZ: "Eswatini",
  SY: "Syrie",
  TJ: "Tadjikistan",
  TW: "Taïwan",
  TZ: "Tanzanie",
  TD: "Tchad",
  CZ: "Tchéquie",
  TF: "Terres australes et antarctiques françaises",
  TH: "Thaïlande",
  TL: "Timor oriental",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinité-et-Tobago",
  TN: "Tunisie",
  TM: "Turkménistan",
  TC: "Îles Turques-et-Caïques",
  TR: "Turquie",
  TV: "Tuvalu",
  UA: "Ukraine",
  UY: "Uruguay",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viêt Nam",
  WF: "Wallis-et-Futuna",
  YE: "Yémen",
  ZM: "Zambie",
  ZW: "Zimbabwe",
};
export const EcheancesPlacement = {
  1: "1 mois",
  2: "2 mois",
  3: "3 mois",
  6: "6 mois",
  12: "1 an",
  24: "2 ans",
  60: "5 ans",
  120: "10 ans",
};
export const Nationality = {
  undefined: "",
  ddd: "sdfsdfsdf",
};
export const Department = {
  undefined: "",
  AIN: "Ain",
  AISNE: "Aisne",
  ALLIER: "Allier",
  ALPES_DE_HAUTE_PROVENCE: "Alpes-de-Haute-Provence",
  HAUTES_ALPES: "Hautes-Alpes",
  ALPES_MARITIMES: "Alpes-Maritimes",
  ARDECHE: "Ardèche",
  ARDENNES: "Ardennes",
  ARIEGE: "Ariège",
  AUBE: "Aube",
  AUDE: "Aude",
  AVEYRON: "Aveyron",
  BOUCHES_DU_RHONE: "Bouches-du-Rhône",
  CALVADOS: "Calvados",
  CANTAL: "Cantal",
  CHARENTE: "Charente",
  CHARENTE_MARITIME: "Charente-Maritime",
  CHER: "Cher",
  CORREZE: "Corrèze",
  CORSE: "Corse",
  COTE_OR: "Côte-d'Or",
  COTES_ARMOR: "Côtes-d'Armor",
  CREUSE: "Creuse",
  DORDOGNE: "Dordogne",
  DOUBS: "Doubs",
  DROME: "Drôme",
  EURE: "Eure",
  EURE_ET_LOIR: "Eure-et-Loir",
  FINISTERE: "Finistère",
  GARD: "Gard",
  HAUTE_GARONNE: "Haute-Garonne",
  GERS: "Gers",
  GIRONDE: "Gironde",
  HERAULT: "Hérault",
  ILLE_ET_VILAINE: "Ille-et-Vilaine",
  INDRE: "Indre",
  INDRE_ET_LOIRE: "Indre-et-Loire",
  ISERE: "Isère",
  JURA: "Jura",
  LANDES: "Landes",
  LOIR_ET_CHER: "Loir-et-Cher",
  LOIRE: "Loire",
  HAUTE_LOIRE: "Haute-Loire",
  LOIRE_ATLANTIQUE: "Loire-Atlantique",
  LOIRET: "Loiret",
  LOT: "Lot",
  LOT_ET_GARONNE: "Lot-et-Garonne",
  LOZERE: "Lozère",
  MAINE_ET_LOIRE: "Maine-et-Loire",
  MANCHE: "Manche",
  MARNE: "Marne",
  HAUTE_MARNE: "Haute-Marne",
  MAYENNE: "Mayenne",
  MEURTHE_ET_MOSELLE: "Meurthe-et-Moselle",
  MEUSE: "Meuse",
  MORBIHAN: "Morbihan",
  MOSELLE: "Moselle",
  NIEVRE: "Nièvre",
  NORD: "Nord",
  OISE: "Oise",
  ORNE: "Orne",
  PAS_DE_CALAIS: "Pas-de-Calais",
  PUY_DE_DOME: "Puy-de-Dôme",
  PYRENEES_ATLANTIQUES: "Pyrénées-Atlantiques",
  HAUTES_PYRENEES: "Hautes-Pyrénées",
  PYRENEES_ORIENTALES: "Pyrénées-Orientales",
  BAS_RHIN: "Bas-Rhin",
  HAUT_RHIN: "Haut-Rhin",
  RHONE: "Rhône",
  HAUTE_SAONE: "Haute-Saône",
  SAONE_ET_LOIRE: "Saône-et-Loire",
  SARTHE: "Sarthe",
  SAVOIE: "Savoie",
  HAUTE_SAVOIE: "Haute-Savoie",
  PARIS: "Paris",
  SEINE_MARITIME: "Seine-Maritime",
  SEINE_ET_MARNE: "Seine-et-Marne",
  YVELINES: "Yvelines",
  DEUX_SEVRES: "Deux-Sèvres",
  SOMME: "Somme",
  TARN: "Tarn",
  TARN_ET_GARONNE: "Tarn-et-Garonne",
  VAR: "Var",
  VAUCLUSE: "Vaucluse",
  VENDEE: "Vendée",
  VIENNE: "Vienne",
  HAUTE_VIENNE: "Haute-Vienne",
  VOSGES: "Vosges",
  YONNE: "Yonne",
  TERRITOIRE_BELFORT: "Territoire de Belfort",
  ESSONNE: "Essonne",
  HAUTS_DE_SEINE: "Hauts-de-Seine",
  SEINE_SAINT_DENIS: "Seine-Saint-Denis",
  VAL_DE_MARNE: "Val-de-Marne",
  VAL_OISE: "Val-d'Oise",
  GUADELOUPE: "Guadeloupe",
  GUYANE: "Guyane",
  REUNION: "Réunion",
  MARTINIQUE: "Martinique",
  MAYOTTE: "Mayotte",
  NOUVELLE_CALEDONIE: "Nouvelle Calédonie",
  POLYNESIE_FRANCAISE: "Polynésie Française",
  ST_PIERRE_ET_MIQUELON: "St Pierre et Miquelon",
  WALLIS_ET_FUTUNA: "Wallis et Futuna",
  AUTRE: "Autre",
};
