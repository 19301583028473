import { combineReducers } from "redux";
import identification from "./identification";
import addresses from "./addresses";
import iban from "./iban";

export default combineReducers({
  identification,
  addresses,
  iban,
});
