import React from "react";
import { AddressString, Birthday, Boolean, Iban, Percentage, Price, } from "shared/components";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import {
  CompanyCategory,
  CompanyLegalForm,
  Country,
  Department,
  RepresentativePositions,
} from "shared/services/enums";
import { emptyFieldFound } from "store/reducers/ui/investments/procedure";
import { LockIcon } from '../../../../icons'

import "./company-part.scss";
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";

const PrintField = ({ value, type, onItemClick }) => {
  switch (type) {
    case "percentage":
      return (
        <React.Fragment>
          <Percentage value={value} />
        </React.Fragment>
      );
    case "price":
      return (
        <React.Fragment>
          <Price value={value} />
        </React.Fragment>
      );
    case "boolean":
      return (
        <React.Fragment>
          <Boolean value={value} />
        </React.Fragment>
      );
    case "position":
      return <React.Fragment>{RepresentativePositions[value]}</React.Fragment>;
    case "address":
      return <AddressString value={value} />;
    case "country":
      return <React.Fragment>{Country[value]}</React.Fragment>;
    case "departmentSelect":
      return <React.Fragment>{Department[value] || value}</React.Fragment>;
    case "citizenship": //TODO French is not france !
      return <React.Fragment>{Country[value]}</React.Fragment>;
    case "legal_form":
      return <React.Fragment>{CompanyLegalForm[value]}</React.Fragment>;
    case "category":
      return <React.Fragment>{CompanyCategory[value]}</React.Fragment>;
    case "birthday":
      return <Birthday value={value} />;
    case "iban":
      return <Iban value={value} />;
    case "document":
      return (
        <div className="document-name" onClick={() => onItemClick(value)}>
          {value.name}
        </div>
      );
    case "siren": //TODO
    case "phone": //TODO
    case "email":
    case "text":
    default:
      return <React.Fragment>{value}</React.Fragment>; //text
  }
};

const PrintFieldList = ({ values, type, onItemClick }) => {
  if (!values) return null;
  return values.map((x, i) => (
    <PrintField key={i} value={x} type={type} onItemClick={onItemClick} />
  ));
};

function BeneficiaryDocumentsPart({
                                    title,
                                    docs,
                                    beneficiaries,
                                    onEdit,
                                    onItemClick,
                                  }) {
  const partLabel = (person) => {
    const civilityStr = person.civility === "mr" ? "M" : "Mme";
    return `${civilityStr}. ${person.last_name}`;
  };

  return (
    <div className="cPartW">
      <div className="cPartH">
        <div className="cPartTitle">{title}</div>

        <div className="cPartEdit">
          <i className="icon-edit" onClick={onEdit} />
        </div>
      </div>

      <div className="cPartBdy">
        {beneficiaries.map((beneficiary, i) => {
          const beneficiaryDocs = docs[beneficiary.id];
          const hasDocs = beneficiaryDocs && beneficiaryDocs.length > 0;

          return (
            <div className="cPartI" key={i}>
              <div className="cPartILabel">{partLabel(beneficiary)}</div>
              <div className="cPartIValue">
                {!hasDocs && <span className="toCompletFlag">À compléter</span>}
                {hasDocs && (
                  <PrintFieldList
                    values={beneficiaryDocs}
                    type="document"
                    onItemClick={onItemClick}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function UneditableDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <LockIcon onClick={handleClickOpen} style={{ cursor: 'pointer' }} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Ces informations ne sont pas modifiables
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si vous désirez modifier vos informations, contactez le service client à{" "}
            <a href="mailto:hello.pro@cashbee.fr">hello.pro@cashbee.fr</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>);
}

function Part({ title, state, fieldNames, editable = true, uboNotNeeded = false, onEdit, onItemClick }) {
  const dispatch = useDispatch();
  const isEmptyValue = (v, type) => {
    let empty = v === undefined || v === null || v === "" || v.length === 0;
    if (!empty) {
      switch (type) {
        case "address":
          empty = [undefined, ""].includes(v.street) || [undefined, ""].includes(v.city);
          break;
        default:
          break;
      }
    }
    if (empty) {
      dispatch(emptyFieldFound());
    }
    return empty;
  };

  const filledValues = Object.keys(state).filter((k, i) => {
    const fld = fieldNames[k];
    if (fld && !fld.hiddenOnSettingBoard) {
      let value = state[k];
      let type = fld.type;
      if (value === "other" && fld.other) {
        value = state[fld.other];
        type = "text";
      }
      return !isEmptyValue(value, type) || fld.optional;
    }
    return true;
  }).reduce((obj, key) => {
    obj[key] = state[key];
    return obj;
  }, {})

  const toCompleteValues = Object.keys(state).filter((k, i) => {
    const fld = fieldNames[k];
    if (fld && !fld.hiddenOnSettingBoard) {
      let value = state[k];
      let type = fld.type;
      if (value === "other" && fld.other) {
        value = state[fld.other];
        type = "text";
      }
      return isEmptyValue(value, type) && !fld.optional;
    }
    return true;
  }).reduce((obj, key) => {
    obj[key] = state[key];
    return obj;
  }, {})

  return (
    <div className="cPartW">
      <div className="cPartH">
        <div className="cPartTitle">{title}</div>

        <div className="cPartEdit">
          {!editable &&
            <UneditableDialog />
          }
          {editable &&
            <i className="icon-edit" onClick={onEdit} />
          }
        </div>
      </div>

      <div className="cPartBdy">
        {Object.keys(filledValues).map((k, i) => {
          const fld = fieldNames[k];
          if (fld && !fld.hiddenOnSettingBoard) {
            let value = state[k];
            let type = fld.type;
            if (value === "other" && fld.other) {
              value = state[fld.other];
              type = "text";
            }
            if (uboNotNeeded && fld.requireUbo) {
              return null;
            }
            if (!fld.optional || value) {
              return (
                <div className="cPartI" key={k}>
                  <div className="cPartILabel">{fld.label}</div>
                  <div className="cPartIValue">
                    {!fld.isArray && (
                      <PrintField
                        value={value}
                        type={type}
                        onItemClick={onItemClick}
                      />
                    )}
                    {fld.isArray && (
                      <PrintFieldList
                        values={value}
                        type={type}
                        onItemClick={onItemClick}
                      />
                    )}
                  </div>
                </div>
              );
            }
          }
          return null;
        })}

        {Object.keys(toCompleteValues).map((k, i) => {
          const fld = fieldNames[k];
          if (fld && !fld.hiddenOnSettingBoard) {
            let value = state[k];
            let type = fld.type;
            if (value === "other" && fld.other) {
              value = state[fld.other];
              type = "text";
            }
            const isEmpty = isEmptyValue(value, type);
            if (uboNotNeeded && fld.requireUbo) {
              return null;
            }
            if (!isEmpty) {
              return null;
            }
            return (
              <div className="cPartI" key={k}>
                <div className="cPartILabel">{fld.label}</div>
                <div className="cPartIValue">
                  <Button size="small" className="buttonToComplete" onClick={onEdit}>
                    Compléter
                  </Button>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default Part;
export { BeneficiaryDocumentsPart };
