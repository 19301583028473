import React from "react";
import Button from "@mui/material/Button";

function Btn({ children, className, onClick, disabled }) {
  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={disabled}
      variant="outlined"
      fullWidth
    >
      {children}
    </Button>
  );
}

export default Btn;
