import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TypedAccountsList from "./TypedAccountsList";
import * as AccountStatus from "shared/enums/AccountStatus";
import AccountInfo, { AccountConfirm } from "./AccountInfo";
import { loadAccounts } from "store/reducers/dashboard/accounts";
import StaticLink from "shared/services/StaticLink";
import { history } from "store";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import "../dashboard.scss";
import { AVAILABLE_PROVIDER_COUNT, CFCAL_SLUG, WORMSER_SLUG } from "../../config.json";
import OfferCard from "../../shared/components/OfferCard";
import { Price } from "../../shared/components";
import Cards from "../Components/Cards";
import { YellowButton } from "../Components/YellowButton";
import { Filters } from "../Components/Filters";
import useProvider from "../../hooks/useProvider";

const DashboardMenu = () => {
  const companyName =
    useSelector((store) => store.dashboard.company.identification.val.name) ??
    "Tableau de bord";

  return (
    <div className="dMH">
      <div className="dMHTitle">{companyName}</div>
      <div className="dMHOthers" style={{ color: '#222226' }}>
        <StaticLink to="dashboard_help">
          <HelpOutlineIcon size="small" />
          <span className="onLargeMedia" style={{ marginLeft: "4px" }}>
            Aide
          </span>
        </StaticLink>

        <Link to="/settings" className="dMHParam" style={{ textDecoration: 'none', color: '#222226' }}
              _hover={{ textDecoration: 'underline' }}>
          <AccountCircleIcon size="small" />
          <span className="onLargeMedia" style={{ marginLeft: "4px" }}>
            Mon entreprise
          </span>
        </Link>
      </div>
    </div>
  );
};
const InfoOnToolNeeded = () => {
  return (
    <div className="dMFooter">
      <div className="alert alert-warning alert-icon-i">
        <span style={{ fontWeight: 500 }}>
          Besoin de plus d’infos sur nos comptes à terme&nbsp;?
        </span>{" "}
        Voici{" "}
        <StaticLink to="centre_aide">
          une page
        </StaticLink>{" "}
        qui résume l’essentiel.
      </div>
    </div>
  );
};
const EmptyDashboard = ({ providers }) => {
  const providersWithOffer = providers.filter((p) => !!p.offer)
  const noOffer = providersWithOffer.length === 0

  return (
    <div>
      <h3>Ouvrir un ou plusieurs compte(s) à terme</h3>
      {noOffer &&
        <p>
          Malheureusement, Cashbee Pro ne propose pas de compte à terme pour les entreprises de type passives pour le moment.
        </p>
      }
      {providersWithOffer.length === 1 &&
        <p>
          Cashbee Pro a sélectionné pour vous une offre de comptes à terme pour répondre à vos besoins.
        </p>
      }
      {providersWithOffer.length > 1 &&
        <p>
          Cashbee Pro a sélectionné pour vous des offres de comptes à terme pour répondre à vos besoins.
        </p>
      }
      <div className="offers" style={{ paddingTop: '30px' }}>
        {providersWithOffer.map((p) => (
          <OfferCard
            key={p.slug}
            title={p.offer.title}
            subtitle={p.offer.subtitle}
            bodyList={p.offer.arguments}
            color={p.slug}
            buttonCallback={() => history.push(`/${p.slug}/accounts-adding`)}
            gridLink={p.offer.grid_link}
          />))
        }
        {noOffer && <img alt="Something went wrong" src={`${process.env.PUBLIC_URL}/SomethingWentWrong.png`} />}
      </div>
    </div>
  );
};

const AlertWaitingProcedureStatus = ({ providerSlug }) => {
  const provider = useSelector((s) => s.dashboard.providers.find((p) => p.slug === providerSlug));
  const procedure = provider?.pending_procedure;

  const CommonAlertWaitingProcedureStatus = ({ title, link_txt, txt, color }) => {
    return (
      <div className={`alert alert-${color || 'warning'} alert-icon-i alert-icon-i-${color}`}>
        {title && (
          <span style={{ fontWeight: "500" }}>
           {title}
          </span>
        )}
        <p>
          {txt}
        </p>
        <br />
        <Link to={`${procedure.provider.slug}/signature`}>{link_txt}</Link>
      </div>
    );
  };

  if (!procedure) return null;
  if (['ready', 'pending_signature'].includes(procedure.status)) {
    return (
      <CommonAlertWaitingProcedureStatus
        color={procedure.provider.slug}
        title={`Signez votre contrat pour terminer votre demande d’ouverture de compte à terme chez ${provider.long_name}.`}
        link_txt="En savoir plus"
      />
    );
  } else if (
    ['signed', 'submitted', 'rejected', 'invalid_documents', 'ready_to_send'].includes(procedure.status)
  ) {
    return (
      <CommonAlertWaitingProcedureStatus
        color={procedure.provider.slug}
        txt={`Votre demande d’ouverture de compte à terme chez ${provider.long_name} est en cours de validation.`}
        link_txt="Plus d'information"
      />
    );
  } else if (procedure.status === "accepted") {
    return (
      <CommonAlertWaitingProcedureStatus
        color={procedure.provider.slug}
        title={`Votre demande d’ouverture de compte à terme chez ${provider.long_name} a été acceptée.`}
        txt={`Procédez au virement depuis votre banque sur ${[WORMSER_SLUG, CFCAL_SLUG].includes(procedure.provider.slug)  ? 'l’IBAN associé' : 'les IBANs associés'}. L’opération apparaîtra sous 2 à 3 jours ouvrés.`}
        link_txt={[WORMSER_SLUG, CFCAL_SLUG].includes(procedure.provider.slug) ? "Afficher l’IBAN" : 'Afficher les IBANs'}
      />
    );
  } else return null;
};

const FilterCallout = ({ provider, amount }) => {
  return (
    <div className={`alert alert-${provider.slug} alert-icon-i alert-icon-i-${provider.slug}`}
         style={{ marginTop: '24px' }}>
      Total {provider.long_name} : <Price value={amount} />
    </div>
  );
}

const DashboardBody = () => {
  const {
    totalActiveAmount,
    pendingList,
    activeList,
    closedList,
    providerWithPending,
    filterableProviders,
    noAccount
  } = useSelector((s) => s.dashboard.accounts);
  const [filter, setFilter] = useState(null)

  const providers = useSelector((s) => s.dashboard.providers)
  const cfcal = providers.find((e) => (e.slug === CFCAL_SLUG))

  const filtering = (list, query) => {
    if (query === null) {
      return list
    }
    return list.filter((el) => el.provider.slug === query)
  }
  const filteredPending = filtering(pendingList, filter)
  const filteredActive = filtering(activeList, filter)
  const filteredClosed = filtering(closedList, filter)

  return (
    <div className="dMBdy">
      <Cards title="Total des placements" totalFunds={totalActiveAmount}></Cards>
      {providerWithPending.length < AVAILABLE_PROVIDER_COUNT && !noAccount &&
        (
          <div className="adding-div">
            <YellowButton
              title="Ouvrir un nouveau compte"
              onClick={() => history.push("/providers")}
            />
          </div>
        )}

      {/*TODO: Icon is invalid*/}
      {(cfcal && !filterableProviders.includes(CFCAL_SLUG)) &&
        <div className="alert alert-success alert-icon-ok">
          Nouveau type de compte à terme disponible<br />Cashbee vous propose désormais les comptes à terme CFCAL
          disponible à partir de{" "}
          <Price value={cfcal.procedure_validation.minimum_per_procedure} />
        </div>
      }
      {providers.length > 0 && providerWithPending.map((pendingProvider) => (
        <AlertWaitingProcedureStatus key={pendingProvider} providerSlug={pendingProvider} />
      ))
      }
      <div className="accountsSection">
        <Filters filter={filter} onClick={setFilter}></Filters>
        {filter &&
          <FilterCallout
            provider={providers.find((e) => e.slug === filter)}
            amount={filteredPending.concat(filteredActive).reduce((a, c) => a + +c.amount, 0)}
          >
          </FilterCallout>
        }
        {filteredPending.length > 0 && (
          <TypedAccountsList type={AccountStatus.PENDING} accounts={filteredPending} />
        )}
        {filteredActive.length > 0 && (
          <TypedAccountsList type={AccountStatus.ACTIVE} accounts={filteredActive} />
        )}
        {filteredClosed.length > 0 && (
          <TypedAccountsList type={AccountStatus.CLOSED} accounts={filteredClosed} />
        )}
      </div>
    </div>
  );
};

function AccountsDashboard() {
  const dashboard = useSelector((s) => s.dashboard);
  useProvider();

  const { totalNumber } = dashboard.accounts;

  const accountInfo = useSelector(
    (store) => store.ui.investments.accounts.showAccountInfo
  );

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(loadAccounts());
  }, [dispatch]);

  const hasRightMenu = accountInfo.account || accountInfo.confirm;
  const leftColomnClass = `dshMain minHeight ${
    hasRightMenu ? "rightFormEdited" : ""
  }`;

  return (
    <div className="bigImgWrapper">
      <div className="deskWrapper">
        <div className="pageContent">
          <div className={leftColomnClass}>
            <DashboardMenu />

            <div className={`dMBdyW ${totalNumber ? "" : "justifyTop"}`}>
              {dashboard.accounts.noAccount && <EmptyDashboard providers={dashboard.providers} />}
              {!dashboard.accounts.noAccount && <DashboardBody />}
            </div>

            {dashboard.accounts.noAccount && <InfoOnToolNeeded />}
          </div>
          <div className="dshSub">
            {(accountInfo.confirm && (
                <AccountConfirm
                  account={accountInfo.account}
                  confirm={accountInfo.confirm}
                />
              )) ||
              (accountInfo.account && (
                <AccountInfo account={accountInfo.account} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountsDashboard;
