import React from "react";
import AccountCard from "./AccountCard";


const AccountsList = ({ accounts }) => {
  return (
    <div className="accountsCardsBdy">
      {accounts.map((x, i) => (
        <AccountCard key={i} account={x} />
      ))}
    </div>
  );
};

export default AccountsList;
