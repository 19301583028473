import React from "react";
import { Button } from "shared/components";
import Dialog, { DialogActions, DialogContent, DialogTitle, } from "shared/components/Dialog";
import style from "shared/components/Dialog/dialog.module.scss";

export default function ConfirmFirst({ payload, close }) {
  if (payload) {
    const {
      title,
      message,
      onConfirm,
      okLabel, //optional
      cancelLabel, //optional
    } = payload;

    return (
      <Dialog open={true} onClose={close} className={style.confirmation}>
        <DialogTitle>{title ?? "Attention"}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button onClick={close}>{cancelLabel ?? "Annuler"}</Button>
          <Button
            onClick={() => {
              onConfirm();
              close();
            }}
            primary
          >
            {okLabel ?? "Continuer"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
}
