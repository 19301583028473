import React, { useState } from "react";
import Joi from "joi";
import { useDispatch, useSelector } from "react-redux";

import { Button, TextInput } from "shared/components";
import WrapConnexionPage from "Funnel/Components/WrapConnexionPage";
import WrapForm from "./WrapForm";
import { apiResetPassword, setPartener } from "store/reducers/auth";
import { errorMessage } from "../../shared/utilities";

const PasswordValidation = ({match}) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [badField, setBadField] = useState(null);
  const { ui } = useSelector((store) => store.auth);

  const { partner_slug, code } = match.params;
  if (partner_slug && partner_slug.length > 0) {
    dispatch(setPartener({ partner: partner_slug }));
  }


  async function validateForm() {
    const joiDefinition = {
      password: Joi.string().required().min(8),
    }
    const schema = Joi.object(joiDefinition);
    const { error } = schema.validate({ password });
    return error ? { name: error.details[0].path[0], type: error.details[0].type } : null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = await validateForm();
    setBadField(error);

    if (!error) {
      dispatch(apiResetPassword({ password, code }));
    }
  };

  return (
    <WrapConnexionPage>
      <WrapForm title="Choisissez un nouveau mot de passe">
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="mailVerifCode">
            <TextInput
              id="password"
              name="password"
              label="Mot de passe"
              type="password"
              value={password}
              onChange={(e) => {
                setBadField(null)
                setPassword(e.target.value)
              }}
              errorMessage={errorMessage(
                "password",
                badField,
                ui.resetInitError422,
                "8 caractères minimum"
              )}
            />
          </div>
          <Button type="submit" primary>
            Changer de mot de passe
          </Button>
        </form>
      </WrapForm>
    </WrapConnexionPage>
  );
};

export default PasswordValidation;
