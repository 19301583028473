import TextInput from "./TextInput";
import InputMask from "react-input-mask";
import React from "react";

// import NumberFormat from "react-number-format";
///BAD : IBAN can contain characters !!!!! Not to use

export const iban2format = (iban) => {
  const result = iban
    ? iban
      .replace(/[^\da-zA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim()
    : "";
  return result;
};

const Iban = ({ value }) => {
  const formated = iban2format(value);
  return <React.Fragment>{formated}</React.Fragment>;
};

const IbanInput = ({ value, name, onChange, disabled, errorMessage }) => {

  function beforeMaskedStateChange(newState, oldState, userInput) {
    const { value } = newState;
    return {
      ...newState,
      value: value.trim()
    };
  }

  return (
    <InputMask
      mask="FR** **** **** **** **** **** ***"
      value={value}
      onChange={onChange}
      disabled={disabled}
      beforeMaskedValueChange={beforeMaskedStateChange}
      maskChar={null}
    >
      {(inputProps) => <TextInput
        {...inputProps}
        label="IBAN"
        name={name}
        disabled={disabled}
        errorMessage={errorMessage && errorMessage.replace("Iban ", "")}
      />}
    </InputMask>
  );
};

export { Iban, IbanInput };
