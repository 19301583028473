import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, GlobalErrorMessage, Percentage, Price, } from "shared/components";
import { date_iso2ui, datetime2ui } from "shared/utilities";
import {
  IS_ACTIVE_ACTIVE,
  IS_ACTIVE_CLOSING,
  IS_CLOSED_CLOSED,
  IS_CLOSED_CLOSING,
  IS_CLOSING_ACCEPTED,
  IS_FINISHED,
} from "shared/enums/AccountStatus";
import apiClient from "shared/API/apiClient";
import { hideAccountInfo, hideConfirm, showConfirmClosure, } from "store/reducers/ui/investments/accounts";
import { apiAsk4ClosingAccount, apiStopClosingAccount, } from "store/reducers/dashboard/accounts";
import { logout } from "../../../store/reducers/auth";

const openClosure = async (accountId, jwt) => {
  const { data } = await apiClient.get(`documents/${accountId}/closure_certificate`, { token: jwt.token });
  setTimeout(() => {
    window.open(data.url, '_blank').focus();
  })
}
export const AccountConfirm = ({ account, confirm }) => {
  const dispatch = useDispatch();
  const accountInfo = useSelector((store) => store.ui.investments.accounts);
  return (
    <div className="editFW">
      <div className="eFH">
        <div className="eFHTitle">Clôture</div>
        <div className="eFHCmd" onClick={() => dispatch(hideConfirm())}>
          <i className="icon-close" />
        </div>
      </div>
      <div className="eFBdy">
        <div>
          <p>
            Attention : la clôture de ce compte avant son terme vous prive d’une
            partie de son rendement.
          </p>
          <p>
            Après {account.age_at_notice} mois de détention,
            vous ne profiterez que de{" "}
            <Percentage value={account.anticipated_interest_rate} /> de
            rendement. Nous estimons ce montant à <Price value={account.anticipated_interest} />{" "}
            (contre <Price value={account.interest} /> au terme).
          </p>
          <p>
            Vos fonds seront transférés sur l’IBAN de votre entreprise dans les{' '}
            {account.notice_period} jours qui suivront l’acceptation de notre partenaire.
          </p>
        </div>

        {accountInfo.closingFailedMessage && (
          <GlobalErrorMessage>
            {accountInfo.closingFailedMessage}
          </GlobalErrorMessage>
        )}

        <div className="eFBtsGp">
          <Button variant="outlined" onClick={() => dispatch(hideConfirm())}>
            Annuler
          </Button>
          <Button
            className="danger"
            variant="contained"
            onClick={() => dispatch(apiAsk4ClosingAccount({ id: account.id }))}
          >
            Clôturer
          </Button>
        </div>
      </div>
    </div>
  );
};

const ActiveAccountInfo = ({ account }) => {
  const dispatch = useDispatch();

  return (
    <div className="editFW">
      <div className="eFH">
        <div className="eFHTitle">Informations</div>
        <div className="eFHCmd" onClick={() => dispatch(hideAccountInfo())}>
          <i className="icon-close" />
        </div>
      </div>
      <div className="eFBdy">
        <div>
          <p>
            <span>Partenaire bancaire : </span>
            <b>{account.provider.long_name}</b>
          </p>
          <p>
            <span>Date d’ouverture : </span>
            <b>{datetime2ui(account.opened_at)}</b>
          </p>
          <p>
            <span>Échéance : </span>
            <b> {date_iso2ui(account.maturity_on)}</b>
          </p>
          <p>
            <span>Montant déposé : </span>
            <b>
              <Price value={account.amount} />
            </b>
          </p>
          <p>
            <span>Rendement actuariel brut : </span>
            <b>
              <Percentage value={account.interest_rate} />
            </b>
          </p>
        </div>

        <Button
          className="danger"
          variant="outlined"
          onClick={() => dispatch(showConfirmClosure())}
        >
          Demander la clôture du compte
        </Button>
      </div>
    </div>
  );
};

const ClosingAccountInfo = ({ account }) => {
  const dispatch = useDispatch();
  const accountInfo = useSelector((store) => store.ui.investments.accounts);
  const { receiver } = useSelector((store) => store.dashboard);

  return (
    <div className="editFW">
      <div className="eFH">
        <div className="eFHTitle">Clôture en cours</div>
        <div className="eFHCmd" onClick={() => dispatch(hideAccountInfo())}>
          <i className="icon-close" />
        </div>
      </div>
      <div className="eFBdy">
        <div>
          <p>
            Nous venons de vous envoyer un document par email sur{" "}
            <b>{receiver.email}</b>. Il ne vous reste qu’à le signer
            électroniquement pour acter la clôture de votre compte.
          </p>
          <p>
            Vos fonds seront transférés sur l’IBAN de votre entreprise dans les{' '}
            {account.notice_period} jours qui suivront l’acceptation de notre partenaire.
          </p>
        </div>

        {accountInfo.stopClosingFailedMessage && (
          <GlobalErrorMessage>
            {accountInfo.stopClosingFailedMessage}
          </GlobalErrorMessage>
        )}

        <Button
          className="danger"
          variant="outlined"
          onClick={() => dispatch(apiStopClosingAccount({ id: account.id }))}
        >
          Annuler la clôture
        </Button>
      </div>
    </div>
  );
};

const ClosureRecievedAccountInfo = ({ account }) => {
  const dispatch = useDispatch();

  return (
    <div className="editFW">
      <div className="eFH">
        <div className="eFHTitle">Demande de clotûre reçue</div>
        <div className="eFHCmd" onClick={() => dispatch(hideAccountInfo())}>
          <i className="icon-close" />
        </div>
      </div>
      <div className="eFBdy">
        <div>
          <p>
            Notre partenaire traite votre demande de clôture. Les détails de l’opération seront bientôt disponibles.
          </p>
        </div>
      </div>
    </div>
  );
};

const ClosedAccountInfo = ({ account }) => {
  const dispatch = useDispatch();
  const jwt = useSelector((store) => store.auth.jwt);
  if (!jwt || !jwt.token) dispatch(logout());

  return (
    <div className="editFW">
      <div className="eFH">
        <div className="eFHTitle">Compte clôturé</div>
        <div className="eFHCmd" onClick={() => dispatch(hideAccountInfo())}>
          <i className="icon-close" />
        </div>
      </div>
      <div className="eFBdy">
        <div>
          <p>
            Vos fonds ainsi que les intérêts ont été reversés sur l’IBAN de votre entreprise le
            {" "}
            {account.closure.effective_on && (
              <span>
                <b>{date_iso2ui(account.closure.effective_on)}</b>
              </span>
            )}
            .
          </p>

          <p>
            Montant déposé :{" "}
            <b>
              <Price value={account.amount} />
            </b>
          </p>
          <p>
            Rendement acturiel:{" "}
            <span style={{ textDecoration: "line-through" }}>
                <Percentage value={account.interest_rate} />
              </span>{" "}
            <Percentage value={account.closure.interest_rate} />
            {" "}
            (clôture anticipée), soit{" "}
            <Price value={account.closure.interest} />
            {" "}
            d’intérêts.
          </p>
          <Button
            size="small"
            variant="contained"
            onClick={() => openClosure(account.id, jwt)}
          >
            Certificat de clôture
          </Button>
        </div>
      </div>
    </div>
  );
};

const FinishedAccountInfo = ({ account }) => {
  const dispatch = useDispatch();
  const jwt = useSelector((store) => store.auth.jwt);
  if (!jwt || !jwt.token) dispatch(logout());

  return (
    <div className="editFW">
      <div className="eFH">
        <div className="eFHTitle">Compte clôturé</div>
        <div className="eFHCmd" onClick={() => dispatch(hideAccountInfo())}>
          <i className="icon-close" />
        </div>
      </div>
      <div className="eFBdy">
        <div>
          <p>
            Vos fonds ainsi que les intérêts sont reversés sur l’IBAN de votre
            entreprise le <b>{date_iso2ui(account.maturity_on)}</b>.
          </p>

          <p>
            Montant déposé :{" "}
            <b>
              <Price value={account.amount} />
            </b>
          </p>

          <p>
            Rendement acturiel:&nbsp;
            <b>
              <Percentage value={account.interest_rate} />
            </b>
            , soit{" "}
            <b>
              <Price value={account.interest} />
            </b>
            &nbsp;d’intérêts.
          </p>
          <Button
            size="small"
            variant="contained"
            onClick={() => openClosure(account.id, jwt)}
          >
            Certificat de clôture
          </Button>
        </div>
      </div>
    </div>
  );
};

const ClosingAcceptedAccountInfo = ({ account }) => {
  const dispatch = useDispatch();

  return (
    <div className="editFW">
      <div className="eFH">
        <div className="eFHTitle">Compte à clôturer</div>
        <div className="eFHCmd" onClick={() => dispatch(hideAccountInfo())}>
          <i className="icon-close" />
        </div>
      </div>
      <div className="eFBdy">
        <div>
          <p>
            Vos fonds ainsi que les intérêts seront reversés sur l’IBAN de votre entreprise le{" "}
            <b>{date_iso2ui(account.maturity_on)}</b>.
          </p>

          <p>
            Montant déposé :{" "}
            <b>
              <Price value={account.amount} />
            </b>
          </p>

          <p>
            Rendement acturiel:{" "}
            <span style={{ textDecoration: "line-through" }}>
                <Percentage value={account.interest_rate} />
              </span>{" "}
            <Percentage value={account.closure.interest_rate} />
            {" "}
            (clôture anticipée), soit{" "}
            <Price value={account.closure.interest} />
            {" "}
            d’intérêts.
          </p>
        </div>
      </div>
    </div>
  );
};
const AccountInfo = ({ account }) => {
  return IS_ACTIVE_ACTIVE(account) ? (
    <ActiveAccountInfo account={account} />
  ) : IS_ACTIVE_CLOSING(account) ? (
    <ClosingAccountInfo account={account} />
  ) : IS_CLOSED_CLOSING(account) ? (
    <ClosureRecievedAccountInfo account={account} />
  ) : IS_CLOSING_ACCEPTED(account) ? (
    <ClosingAcceptedAccountInfo account={account} />
  ) : IS_CLOSED_CLOSED(account) ? (
    <ClosedAccountInfo account={account} />
  ) : IS_FINISHED(account) ? (
    <FinishedAccountInfo account={account} />
  ) : null;
};

export default AccountInfo;
