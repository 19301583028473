import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSaveRepresentant, clear, } from "store/reducers/dashboard/representants";
import { Button, CheckBox, DataCollapsible, GlobalErrorMessage } from "shared/components";
import InterlocutorBody from "./InterlocutorBody";
import { history } from "store";
import validateForm from "./validateForm";
import { LEGAL_REPRESENTATIVE } from "./interlocutorType";
import TaxResidencesList from "shared/components/TaxResidencesList/index";
import queryString from "query-string";
import WrapPage from "Funnel/Components/WrapPage";

function LegalRepresentative({ match, location }) {
  const dispatch = useDispatch();
  const dashboard = useSelector((store) => store.dashboard);
  const person = dashboard.representants;
  const legalInterlocutor = person.legal;

  const isIndividualCompany =
    dashboard.company.identification.val.legal_form === "individual_enterprise";

  const [state, setState] = useState(legalInterlocutor.body);
  const [badField, setBadField] = useState(null);
  const [adminExists, setAdminExists] = useState(
    !person.administrator.neverUploaded
  );
  useEffect(() => dispatch(clear()), [dispatch]);

  const isUpdate = match.params.action === "update";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = await validateForm(state, LEGAL_REPRESENTATIVE, isIndividualCompany);
    setBadField(error);
    if (!error) {
      let successLink = null;
      if (isUpdate) {
        const { h: pageBack } = queryString.parse(location.search);
        successLink =
          adminExists && person.administrator.neverUploaded
            ? `/admin-edit/update${location.search}`
            : pageBack;
      } else {
        successLink = adminExists ? "/admin-edit" : (isIndividualCompany ? "/company-documents" : "/benificiaries");
      }

      const state_body = { ...state, us_person: state.us_person === null ? false : state.us_person }
      dispatch(
        apiSaveRepresentant({
          data: state_body,
          successLink,
          neverUploaded: legalInterlocutor.neverUploaded,
        })
      );
    }
  };

  return (
    <WrapPage>
      {!isUpdate && <div className="sectionName">2/4 — Interlocuteurs</div>}
      <div className="formW">
        <div className="formH">
          <div className="goBack" onClick={() => history.goBack()}>
            <i className="icon-arrow-back" />
          </div>
          <div className="formTitle">Représentant légal</div>
        </div>

        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div>
            Si l’entreprise a plusieurs représentants légaux, renseigner celui
            qui sera référent sur Cashbee Pro.
          </div>

          <InterlocutorBody
            badField={badField}
            interlocutorType="representative"
            error422={legalInterlocutor.error422}
            body={state}
            onBodyChange={(body) => {
              const bodySv = { ...body, tax_residences: state.tax_residences }; //To not loose tax_residences
              setState({ ...state, ...bodySv });
            }}
          />

          {!isIndividualCompany && <>
            <TaxResidencesList
              list={state.tax_residences}
              onChange={(v) => {
                const newState = { ...state, tax_residences: v };
                setState(newState);
              }}
              badField={badField}
              error422={legalInterlocutor.error422}
            />

            <CheckBox
              checked={state.us_person}
              onChange={(v) => {
                const newState = { ...state, us_person: v };
                setState(newState);
              }}
              // name="us_person"
              label="Le représentant légal est une personne américaine au sens de la réglementation FATCA"
              name="us_person"
              errorMessage={
                badField && badField.name === "us_person"
                  ? `Nous n’acceptons pour l’instant pas les représentants “US Person”`
                  : null
              }
            />
          </>}

          {(!isUpdate || person.administrator.neverUploaded) && (
            <CheckBox
              label="Cochez cette case si vous n’êtes pas le représentant légal de l’entreprise"
              checked={adminExists}
              onChange={(v) => setAdminExists(v)}
            />
          )}

          {legalInterlocutor.apiPostErrors && (
            <GlobalErrorMessage>
              {legalInterlocutor.apiPostErrors}
            </GlobalErrorMessage>
          )}

          <Button
            type="submit"
            variant="contained"
            primary
            disabled={
              isUpdate &&
              state === legalInterlocutor.body &&
              !(adminExists && person.administrator.neverUploaded)
            }
          >
            {isUpdate ? "Enregistrer" : "Suivant"}
          </Button>
          {!isUpdate && <DataCollapsible />}
        </form>
      </div>
    </WrapPage>
  );
}

export default LegalRepresentative;
