import React from 'react';
import apiClient from "shared/API/apiClient";

const openContractFromID = async (contractId, jwt) => {
  try {
    const { data } = await apiClient.get(`contracts/${contractId}`, { token: jwt.token });
    setTimeout(() => {
      window.open(data.url, '_blank').focus();
    })
  } catch (error) {
    console.log(error);
  }
}

const ConsultProcedureDocuments = ({ proceduresContracts, jwt }) => {
  return (
    <div className="cPartW">
      <ul className="cPartBdy">
        {proceduresContracts.length === 0 ?
          (<div className="cPartI">Aucun contrat</div>)
          : (
            proceduresContracts.map((item) => (
              item.contracts.map((contract) => (
                <div className="cPartI" key={contract.id}>
                  <div className="cPartILabel">
                    {new Date(item.signed_at).toLocaleDateString()}
                  </div>
                  <div className="cPartIValue">
                    <div className="document-name"
                         onClick={() => openContractFromID(contract.id, jwt)}>{contract.filename}</div>
                  </div>
                </div>
              ))
            ))
          )
        }
      </ul>
    </div>
  );
};

export default ConsultProcedureDocuments;
