import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "receiver",
  initialState: {
    email: "",
    first_name: '',
    last_name: ''
  },
  extraReducers: {
    initDashboard: (state, { payload }) => {
      Object.assign(state, payload.receiver);
    },
  },
});

export default slice.reducer;
