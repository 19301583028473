import React, { useState } from "react";
import Joi from "joi";
import { useDispatch, useSelector } from "react-redux";

import { Button, GlobalErrorMessage, TextInput } from "shared/components";
import WrapConnexionPage from "Funnel/Components/WrapConnexionPage";
import WrapForm from "./WrapForm";
import { resendValidationEmail, validateEmail } from "store/reducers/auth";
import { errorMessage } from "../../shared/utilities";
import Link from "@mui/material/Link";

const EmailValidation = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);

  const { ui, jwt } = useSelector((store) => store.auth);

  async function validateForm() {
    const schema = Joi.object({
      code: Joi.string().required(),
    });
    const { error } = schema.validate({ code });
    return error;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = await validateForm();
    if (error) {
      setError(true);
    } else {
      setError(false);
      dispatch(validateEmail(code));
    }
  };
  const handleResendEmail = () => {
    dispatch(resendValidationEmail());
  };
  return (
    <WrapConnexionPage>
      <WrapForm title="Vérification de l’email">
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="formDsc">
            {`Entrez le code à 6 chiffres que vous avez reçu sur ${jwt.email}`}
          </div>
          <div className="mailVerifCode">
            <TextInput
              id="email"
              name="verification_code"
              label="_ _ _ _ _ _"
              type="email"
              noUpLabel
              value={code}
              onChange={(e) => setCode(e.target.value)}
              errorMessage={errorMessage(
                "verification_code",
                error,
                ui.apiEmailVerifError422,
                "Désolé, ce code ne nous dit rien."
              )}
            />
          </div>
          {ui.apiEmailVerifError && (
            <GlobalErrorMessage>{ui.apiEmailVerifError}</GlobalErrorMessage>
          )}
          <Button type="submit" primary>
            S’inscrire
          </Button>

          {ui.emailSent && (
            <div className="alert alert-success alert-icon-ok">
              Un nouveau code d'authentification vous a été envoyé par email
            </div>
          )}
          {ui.emailNotSentReason && (
            <GlobalErrorMessage>{ui.emailNotSentReason}</GlobalErrorMessage>
          )}

          <Link href="#" onClick={handleResendEmail}>
            Renvoyer l’email
          </Link>
        </form>
      </WrapForm>
    </WrapConnexionPage>
  );
};

export default EmailValidation;
