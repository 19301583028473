import React from "react";
import { Price } from "shared/components";
import './card.scss'

function Cards({ title, totalFunds }) {
  if (!totalFunds) return null; //we do not show header if no funds

  return (
    <div className="card">
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        <Price className="card-price" value={totalFunds} />
      </div>
    </div>
  );
}

export default Cards;
