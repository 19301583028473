import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "iban",
  initialState: {
    fieldNames: {
      iban: { label: "IBAN", type: "iban" },
      bic: { label: "BIC" },
    },
  },
  reducers: {},
});

// export const {} = slice.actions;
export default slice.reducer;
