import React from "react";
import { history } from "../../store";
import OfferCard from "../../shared/components/OfferCard";
import { useSelector } from "react-redux";

function ProvidersSelection() {
  const providers = useSelector((s) => s.dashboard.providers);
  const providersWithOffer = providers.filter((p) => !!p.offer);
  const noOffer = providersWithOffer.length === 0;

  return (
    <div className="bigImgWrapper">
      <div className="deskWrapper">
        <div className="pageContent">
          <div className="dshMain minHeight">
            <div className="goBackHeader">
              <div className="goBack" onClick={() => history.goBack()}>
                <i className="icon-arrow-back" />
              </div>
              <div className="formTitle">Ajout de compte(s)</div>
            </div>
            <div className="dMBdyW justifyTop">
              <div>
                <h3>Ouvrir un ou plusieurs compte(s) à terme</h3>
                {noOffer &&
                  <p>
                    Malheureusement, Cashbee Pro ne propose pas de compte à terme pour les entreprises de type passives pour le moment.
                  </p>
                }
                {providersWithOffer.length === 1 &&
                  <p>
                    Cashbee Pro a sélectionné pour vous une offre de comptes à terme pour répondre à vos besoins.
                  </p>
                }
                {providersWithOffer.length > 1 &&
                  <p>
                    Cashbee Pro a sélectionné pour vous des offres de comptes à terme pour répondre à vos besoins.
                  </p>
                }
              </div>
              <div style={{ paddingTop: '8px' }}>
                {providers.filter((p) => !!p.offer).map((p) => (
                  <OfferCard
                    disabled={p.offer.blocked}
                    notice={p.offer.notice}
                    key={p.slug}
                    title={p.offer.title}
                    subtitle={p.offer.subtitle}
                    bodyList={p.offer.arguments}
                    color={p.slug}
                    buttonCallback={() => history.push(`/${p.slug}/accounts-adding`)}
                    gridLink={p.offer.grid_link}
                  />))
                }
              </div>
              {noOffer && <img alt="Something went wrong" src={`${process.env.PUBLIC_URL}/SomethingWentWrong.png`} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProvidersSelection;
