import React from "react";
import BeneficiaryShortBtn from "./BeneficiaryShortBtn";

function BeneficiariesLst({ list, updatedIndex, onItemEdit }) {
  return (
    <React.Fragment>
      {list.length > 0 && (
        <React.Fragment>
          {list.map((x, i) => (
            <BeneficiaryShortBtn
              key={i}
              binificiary={x}
              active={i===updatedIndex}
              onEdit={(obj) => onItemEdit({ item: obj, index: i })}
            />
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default BeneficiariesLst;
