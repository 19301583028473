import React from "react";
import { Redirect, Route, Switch } from "react-router"; // react-router v4/v5
import { EmailValidation } from "Funnel";

const EmailToVerifyRouter = () => {
  return (
    <Switch>
      <Route path="/verify-email" exact component={EmailValidation} />
      <Redirect from="/" to="/verify-email" />
    </Switch>
  );
};

export default EmailToVerifyRouter;
