import css from "./number-ctrl.module.scss";

const NumberCtrl = ({ n, dec, inc, totalNumber, maxNbr }) => {

  const handleDecrement = () => {
    if (n > 0) dec();
  };
  const handleIncrement = () => {
    if (totalNumber < maxNbr) inc();
  };

  return (
    <div className={css.nbrCtlW}>
      <span className={css.bt} onClick={handleDecrement}>
        −
      </span>
      <span className={css.nbr}>{n}</span>
      <span className={css.bt} onClick={handleIncrement}>
        +
      </span>
    </div>
  );
};

export default NumberCtrl;
