import axios from "axios";
import { apiBaseURL } from "config";

const apiClient = axios.create({
    baseURL: apiBaseURL
});

apiClient.interceptors.request.use(
    (config)=> {
        if (config.token) {
            config.headers['Authorization'] = `Bearer ${config.token}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export default apiClient;