import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "store";
import SubPart, { BeneficiaryDocumentsPart } from "./SubPart";
import { logout } from "store/reducers/auth";
import { viewDocumentById } from "shared/apiUtilties";
import ConsultProcedureDocuments from "./ConsultProcedureDocuments";
import ContractsDocuments from "./ContractsDocuments";

function EditCompanyDocuments({ confirm = false }) {
  const dispatch = useDispatch();

  const dashboard = useSelector((store) => store.dashboard);
  const ui = useSelector((store) => store.ui.company.documents);
  const jwt = useSelector((store) => store.auth.jwt);
  if (!jwt || !jwt.token) dispatch(logout());

  if (
    !dashboard.company ||
    !dashboard.company.identification ||
    !dashboard.documents
  )
    return;
  //else
  const documents = dashboard.documents;
  const isIndividualCompany =
    dashboard.company.identification.val.legal_form === "individual_enterprise";
  const uboNotNeeded = isIndividualCompany

  const hasAdmin = !dashboard.representants.administrator.neverUploaded;

  const benificiaries = dashboard.benificiaries.list;
  const hasBenificiaries =
    !isIndividualCompany && benificiaries && benificiaries.length > 0;

  const handleDocumentItemClick = (item) => {
    viewDocumentById(item.id, jwt.token);
  };

  return (
    <React.Fragment>
      {!confirm && <ContractsDocuments jwt={jwt} />}
      <span className="CDocumentTitle">Documents liés à votre entreprise</span>
      {isIndividualCompany ? (
        <SubPart
          title="Entreprise"
          state={documents.company_individual.docs}
          fieldNames={ui.company_individual.fieldNames}
          onEdit={() => history.push("/company-documents/update")}
          onItemClick={(item) => handleDocumentItemClick(item)}
        />
      ) : (
        <SubPart
          title="Entreprise"
          state={documents.company_moral.docs}
          fieldNames={ui.company_moral.fieldNames}
          uboNotNeeded={!uboNotNeeded}
          onEdit={() => history.push("/company-documents/update")}
          onItemClick={(item) => handleDocumentItemClick(item)}
        />
      )}

      <SubPart
        title="Représentant légal"
        state={documents.representative.docs}
        fieldNames={ui.representative.fieldNames}
        onEdit={() => history.push("/representative-documents/update")}
        onItemClick={(item) => handleDocumentItemClick(item)}
      />

      {hasAdmin && (
        <SubPart
          title="Administrateur du compte"
          state={documents.interlocutor.docs}
          fieldNames={ui.interlocutor.fieldNames}
          onEdit={() => history.push("/interlocutor-documents/update")}
          onItemClick={(item) => handleDocumentItemClick(item)}
        />
      )}

      {(!uboNotNeeded && hasBenificiaries) && (
        <BeneficiaryDocumentsPart
          title="Bénéficiaires effectifs"
          docs={documents.beneficiaries.docs}
          beneficiaries={benificiaries}
          onEdit={() => history.push("/beneficiaries-documents/update")}
          onItemClick={(item) => handleDocumentItemClick(item)}
        />
      )}
      {!confirm && (
        <>
          <span className="CDocumentTitle">Documents liés à vos comptes</span>
          <ConsultProcedureDocuments
            proceduresContracts={dashboard.signed_procedures.proceduresContracts}
            jwt={jwt}
          />
        </>)
      }
    </React.Fragment>
  );
}

export default EditCompanyDocuments;
