import React from "react";
import { TextInput } from "shared/components";
import parsePhoneNumber, { parsePhoneNumberFromString } from "libphonenumber-js";


export const isValidPhone = (value, helpers) => {
  if (!value) { return helpers.error('any.invalid'); }

  const phoneNbr = parsePhoneNumber(value);
  if (!phoneNbr || !phoneNbr.isValid()) {
    return helpers.error('any.invalid');
  }
  return value;
};

function PhoneNumber({ label, name, value, onChange, isValid, errorMessage }) {
  const parsed = parsePhoneNumberFromString(value, "FR");
  const newValue = parsed ? parsed.formatInternational() : value;

  return (
    <TextInput
      label={label}
      name={name}
      type="text"
      value={newValue}
      onChange={(e) => {
        const phoneNbr = parsePhoneNumberFromString(e.target.value, "FR");

        if (phoneNbr) {
          onChange(phoneNbr.number);
          isValid(phoneNbr.isValid());
        } else {
          onChange(e.target.value);
          isValid(false);
        }
      }}
      errorMessage={errorMessage}
    />
  );
}

export default PhoneNumber;
