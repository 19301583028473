import React from "react";
import { InfoIcon } from "../../icons";
import { Collapsible } from "./Collapsible";

const DataCollapsible = () => {
  return (
    <Collapsible
      header={
        <>
          <InfoIcon style={{height: '16px', width: '16px'}} />
          À propos de vos données
        </>
      }
    >
      <p>
        Les informations demandées sont nécessaires pour répondre à votre demande d'ouverture de compte.
        En poursuivant le parcours de souscription, vous acceptez que les données personnelles saisies soient utilisées
        pour la gestion du compte et puissent être communiquées au partenaire bancaire sélectionné dans le cas où vous
        décideriez de procéder à la souscription d'un contrat.
      </p>
      <br />
      <p>
        <a href="https://www.cashbee.fr/legal/cgu-cashbee-pro" target="_blank" rel='noreferrer'>
          Voir nos conditions générales d’utilisation (article 5.4)
        </a>
      </p>
    </Collapsible>
  );
};

export default DataCollapsible;

