import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { apiBaseURL } from "config";
import { Loading } from "shared/components";
import { logout } from "store/reducers/auth";
import ConfirmFirst from "shared/components/Dialog/ConfirmFirst";
import { viewDocumentById } from "shared/apiUtilties";

const DocumentUploaded = ({
  folder,
  subFolder,
  owner_id,
  currentItem,
  onDeleted,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(null);

  const jwt = useSelector((store) => store.auth.jwt);
  if (!jwt || !jwt.token) dispatch(logout());

  useEffect(() => {
    return () => setLoading(false);
  });

  //else
  const deleteFile = async () => {
    const { id, document_type } = currentItem;
    try {
      setLoading(true);
      const result = await axios({
        baseURL: apiBaseURL,
        headers: { Authorization: `Bearer ${jwt.token}` },
        method: "DELETE",
        url: `/documents/${currentItem.id}`,
      });
      if (onDeleted) onDeleted(id);
      if (result.status < 300)
        dispatch({
          type: "documentDeleted",
          payload: { folder, subFolder, owner_id, documentType: document_type, id: currentItem.id },
        });
    } catch (error) {
      //TODO
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handlFileDelete = () => {
    setConfirm({
      title: "Attention",
      message: "Étes vous certain(e) de vouloir supprimer ce document ?",
      onConfirm: () => deleteFile(),
    });
  };

  const handleOpenView = () => {
    viewDocumentById(currentItem.id, jwt.token);
  };
  return (
    <div className="uploadedDocW">
      <div className="itemName" onClick={handleOpenView}>
        {currentItem.name}
      </div>
      <div className="closeBtn" onClick={handlFileDelete}>
        <i className="icon-delete-circle" />
      </div>
      {loading && <Loading />}
      <ConfirmFirst payload={confirm} close={() => setConfirm(null)} />
    </div>
  );
};

export default DocumentUploaded;
