import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";

function Percentage({ value }) {
  return (
    <NumberFormat
      value={parseFloat(value)}
      displayType={"text"}
      decimalSeparator={","}
      suffix={"%"}
    />
  );
}

function PercentageInput({
  label,
  value,
  onChange,
  noUpLabel,
  disabled,
  errorMessage,
}) {
  const MAX_VAL = 100;
  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
  };
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <NumberFormat
        customInput={TextField}
        decimalSeparator={","}
        suffix={"%"}
        label={noUpLabel ? "" : label}
        placeholder={noUpLabel ? label : ""}
        value={value}
        onValueChange={(values) => {
          const { value } = values;
          onChange(value);
        }}
        disabled={disabled}
        isAllowed={withValueLimit}
        variant="outlined"
        size="small"
        fullWidth
        helperText={errorMessage}
        error={!!errorMessage}
      />
    </span>
  );
}

export { Percentage, PercentageInput };
