import Joi from "joi";

export default async function validateForm(obj) {
  const schema = Joi.object({
    street: Joi.string().required(),
    second_line: Joi.string().allow(null, ""), //not required
    postcode: Joi.string().required(),
    city: Joi.string().required(),
    country: Joi.string().required(),
    territory: Joi.string().allow(null, ""), //not required
  });
  const { error } = schema.validate(obj);
  return error
    ? { name: error.details[0].path[0], type: error.details[0].type }
    : null;
}
