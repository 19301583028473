import React from "react";
import "./logo-zone.scss";

export default function LogosZone({ connexionPg }) {
  return (
    <div className={`logosZone ${connexionPg ? "connexionPg" : ""}`}>
      <div className="logo logoCashBee" />
      <div className="logo logoPartner" />
    </div>
  );
}
