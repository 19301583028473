import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { decrementInstance, incrementInstance, startEditing, } from "store/reducers/dashboard/procedure";
import { Maturity, Price, Rendement, RendementActuarial, } from "shared/components";
import NumberCtrl from "./NumberCtrl";
import css from "./accountCards.module.scss";
import { maturityAsInt } from "../../../../../shared/utilities";

function AccountCard({ account, totalNumber, maxNbr, rateGrids }) {
  const dispatch = useDispatch();
  const procAccounts = useSelector((store) => store.dashboard.procedure);
  const active =
    procAccounts.edited && procAccounts.edited.currValue.id === account.id;

  const handleEditClick = () => {
    if (!active) dispatch(startEditing({ account }));
  };
  const handleDecNumber = () => {
    if (!active) dispatch(decrementInstance({ id: account.id }));
  };
  const handleIncNumber = () => {
    if (!active) dispatch(incrementInstance({ id: account.id }));
  };
  return (
    <div className={classNames(css.procCardW, { [css.active]: active })}>
      <div className={css.priceW}>
        <div className={css.price}>
          <Price value={account.amount} />
        </div>
        <i
          className={`icon-${active ? "chevron-right" : "edit"}`}
          onClick={handleEditClick}
        />
      </div>

      <div className={css.dscW}>
        <div className={css.dsc}>
          <div>
            Compte à <Maturity months={maturityAsInt(account.maturity)} />
          </div>
          <div>
            Rendement de <Rendement maturity={account.maturity} rateGrids={rateGrids} />, soit{" "}
            <RendementActuarial
              rateGrids={rateGrids}
              amount={account.amount}
              maturity={account.maturity}
              number={account.number}
            />{" "}
            au terme
          </div>
        </div>
        <NumberCtrl
          n={account.number}
          dec={handleDecNumber}
          inc={handleIncNumber}
          totalNumber={totalNumber}
          maxNbr={maxNbr}
        />
      </div>
    </div>
  );
}

export default AccountCard;
