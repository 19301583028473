import React, { useState } from "react";
import { history } from "store";
import { useDispatch, useSelector } from "react-redux";
import { Button, GlobalErrorMessage, Maturity, Price, Rendement, } from "shared/components";
import { apiProcedurePost, cleanApiErrors, } from "store/reducers/dashboard/procedure";
import { computeTotalFunds, maturityAsInt } from "shared/utilities";
import { SIGNATURE_KINDS } from "../../config.json";

import "./recap.scss";

//At this location, we post procedure to the server
function ProcedureRecap() {
  const dispatch = useDispatch();
  const dashboard = useSelector((store) => store.dashboard);
  const procedure = dashboard.procedure;
  const { fund_origin, fund_origin_other, list, apiPostErrors, providerSlug } = procedure;
  React.useEffect(() => {
    dispatch(cleanApiErrors());
  }, [dispatch]);
  const [submitted, setSubmitted] = useState(false);

  const provider = dashboard.providers.find((e) => e.slug === providerSlug)
  const rateGrids = provider.rate_grids;
  const totalFunds = computeTotalFunds(list);
  const signatureKind = SIGNATURE_KINDS[provider.slug];

  //the email
  const { legal, administrator } = dashboard.representants;
  const interlocutor = administrator.neverUploaded ? legal : administrator;
  const email = interlocutor.body.email;

  const handleSubmit = async (e) => {
    const instancesOf = (x) => {
      const lst = [];
      for (let i = 0; i < x.number; ++i) {
        lst.push({
          fund_origin,
          fund_origin_other,
          amount: x.amount,
          maturity: x.maturity,
        });
      }
      return lst;
    };

    const data = {
      banking_secrecy: true,
      provider_slug: providerSlug,
      kind: signatureKind,
      accounts: list.reduce((a, b) => [...a, ...instancesOf(b)], []),
    };
    setSubmitted(true);
    dispatch(apiProcedurePost({ data, successLink: `${provider.slug}/signature` }));
  };

  return (
    <div className="bigImgWrapper">
      <div className="deskWrapper">
        <div className="pageContent">
          <div className="dshMain minHeight grey">
            <div className="goBackHeader">
              <div className="goBack" onClick={() => history.goBack()}>
                <i className="icon-arrow-back" />
              </div>
              <div className="formTitle">Récapitulatif</div>
            </div>
            <div className="dMBdyW">
              <div className="dMBdy">
                <h3 style={{ marginBottom: "6px" }}>
                  Votre dossier est complet !
                </h3>
                {signatureKind === 'email' && <div>
                  Nous allons maintenant vous envoyer votre contrat par email
                  sur
                  <b> {email}</b>. Il sera pré-rempli avec toutes vos
                  informations. Il vous suffira de le signer électroniquement !
                </div>}
                {signatureKind === 'embedded' &&
                  <div>
                    Superbe ! Il ne vous reste plus qu’à signer votre contrat et faire votre virement pour mettre votre trésorerie au travail.
                  </div>
                }
                <div className="pSummaryW">
                  <div className="pSummaryH">Vos placements chez {provider.long_name}</div>
                  <div className="pSummaryLst">
                    {list.map((x, i) => (
                      <div key={i} className={`pSummaryI ${providerSlug}`}>
                        <div className="pSummaryIDsc">
                          <p>Montant</p>
                          <h3 className="pAccAmount">
                            <Price value={x.amount} />
                          </h3>
                          <div>
                            <Maturity months={maturityAsInt(x.maturity)} /> —{" "}
                            <Rendement maturity={x.maturity} rateGrids={rateGrids} />
                          </div>
                        </div>
                        <div className="pSummaryINbr">{`x${x.number}`}</div>
                      </div>
                    ))}
                  </div>
                  <div className="pTotalW">
                    <h2 className="total">
                      Total : <Price value={totalFunds} />
                    </h2>
                  </div>
                </div>

                <div className="alert alert-warning alert-icon-i">
                  Attention : assurez-vous que ces montants sont disponibles sur
                  le compte courant de votre entreprise.
                </div>
              </div>

              <div className="dMSubmitBt">
                {apiPostErrors && (
                  <div style={{ marginBottom: "1rem" }}>
                    <GlobalErrorMessage>{apiPostErrors}</GlobalErrorMessage>
                  </div>
                )}
                <Button onClick={handleSubmit} primary disabled={submitted}>
                  Recevoir mon contrat
                </Button>
              </div>
            </div>
          </div>
          <div className="dshSub"></div>
        </div>
      </div>
    </div>
  );
}

export default ProcedureRecap;
