import React, { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const Collapsible = ({ header, children }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div>
      <div style={{
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
        ...(collapse && {marginBottom: '16px'})
      }}>
        {header}
        <div style={{ marginLeft: 'auto' }}>
          <IconButton
            aria-label="expend"
            onClick={() => setCollapse(!collapse)}
          >
            <KeyboardArrowUpIcon
              size="small"
              sx={{
                rotate: `${!collapse ? '0' : '180deg'}`,
                transition: 'rotate 500ms'
              }} />
          </IconButton>
        </div>
      </div>
      <Collapse
        in={collapse}
      >
        <div className="collapseContent">
          {children}
        </div>
      </Collapse>
    </div>
  )
}
