import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FinalRouter from "./FinalRouter";
import { Loading } from "shared/components";

import { loadDashboard } from "store/reducers/dashboard/index";
import useProvider from "../hooks/useProvider";

function AuthorizedRouters() {
  const dispatch = useDispatch();
  const dashboard = useSelector((store) => store.dashboard);
  const loadedUi = dashboard.accounts.loadedUi;
  useProvider();

  React.useEffect(() => {
    dispatch(loadDashboard());
  }, [dispatch]);

  if (!loadedUi) return <Loading />;

  return <FinalRouter />;
}

export default AuthorizedRouters;
