import React from "react";
import { Redirect, Route, Switch } from "react-router"; // react-router v4/v5
import { useSelector } from "react-redux";
//
import * as Funnel from "Funnel";
import * as AddingProcess from "Accounts/AddingProcedure";
import AccountsDashboard from "Accounts/AccountsDashboard";

function FinalRouter() {
  const dashboard = useSelector((store) => store.dashboard);
  const openedAccounts = (dashboard.accounts.activeList + dashboard.accounts.closedList).length > 0;

  return (
    <Switch>
      {/* COMPANY */}
      <Route
        path="/identification/:action?"
        component={Funnel.Identification}
      />
      <Route path="/main-address/:action?" component={Funnel.MainAddress} />
      <Route
        path="/correspondence-address/:action?"
        component={Funnel.CorrespondenceAdress}
      />
      <Route path="/iban/:action?" exact component={Funnel.Iban} />

      {/* REPRESENTANTS */}
      <Route
        path="/legal-representative/:action?"
        component={Funnel.LegalRepresentative}
      />
      <Route path="/admin-edit/:action?" component={Funnel.AdminEdit} />

      {/* BENEFICIAIRES */}
      <Route
        path={["/benificiaries", "/:providerSlug/benificiaries"]}
        exact
        component={Funnel.Benificiaries} />
      <Route
        path="/beneficiary-edit/:id?"
        exact //id could be "add" else a numeric number of existant beneficiary to edit
        component={Funnel.BeneficiaryEditPage}
      />

      {/* DOCUMENTS */}

      <Route
        path="/company-documents/:action?"
        component={Funnel.CompanyDocs}
      />
      <Route
        path="/interlocutor-documents/:action?"
        component={Funnel.InterlocutorDocs}
      />
      <Route
        path="/representative-documents/:action?"
        component={Funnel.RepresentativeDocs}
      />
      <Route
        path={["/beneficiaries-documents/:action?", "/:providerSlug/beneficiaries-documents"]}
        component={Funnel.BeneficiariesDocs}
      />

      {/* PROCEDURES / ADDING ACCOUNTS */}
      <Route
        path="/providers"
        exact
        component={AddingProcess.ProvidersSelection}
      />
      <Route
        path="/:providerSlug/accounts-adding"
        exact
        component={AddingProcess.AccountsAdding}
      />
      <Route
        path="/formalities"
        exact
        component={AddingProcess.ProcedureFormalities}
      />
      <Route
        path="/settings-confirm"
        component={Funnel.SettingsConfirm}
      />
      <Route
        path="/recap-accounts-adding"
        exact
        component={AddingProcess.ProcedureRecap}
      />
      <Route
        path="/:providerSlug/signature/embedded"
        exact
        component={AddingProcess.ProcedureEmbeddedSignature}
      />
      <Route
        path="/:providerSlug/signature"
        exact
        component={AddingProcess.ProcedureSignature}
      />

      <Route path="/accounts" exact component={AccountsDashboard} />

      <Redirect path="/settings" exact to="/settings/documents" />

      <Route path="/settings/:tab" component={Funnel.SettingBoard} />

      {/* ROOTING WHEN STILL EMPTY */}

      {(!openedAccounts && dashboard.company.identification.neverUploaded) && (
        <Redirect from="/" to="/identification" />
      )}

      {(!openedAccounts && dashboard.representants.legal.neverUploaded) && (
        <Redirect from="/" to="/legal-representative" />
      )}

      {/* DOCUMENTS */}
      {(!openedAccounts && dashboard.documents.representative.moreUploadNeeded) && (
        <Redirect from="/" to="/representative-documents" />
      )}
      {(!openedAccounts && !dashboard.representants.administrator.neverUploaded && dashboard.documents.interlocutor.moreUploadNeeded) && (
        <Redirect from="/" to="/interlocutor-documents" />
      )}
      {(!openedAccounts && dashboard.documents.beneficiaries.moreUploadNeeded) && (
        <Redirect from="/" to="/beneficiaries-documents" />
      )}

      {/* ROOTING END */}

      <Redirect from="/" to="/accounts" />
    </Switch>
  );
}

export default FinalRouter;
