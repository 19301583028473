import React from "react";
import "shared/styles/css/adding-button.scss";
import { Button } from "shared/components";

export default function DocAddButton({ onClick, errorClass }) {
  return (
    <>
      <Button
        className={`adding-btn ${errorClass ? "error" : ""}`}
        onClick={onClick}
        // disabled={!(maxNbr > totalNumber)}
      >
        <div className="adding-btn-content">
          <span>Ajouter un document</span>
          <i className="icon-add-circle" />
        </div>
      </Button>
        {errorClass && (
          <div className="global-error-message">Vous devez ajouter ce document</div>
        )}
    </>
  );
}
