import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

function TextInput({
  label,
  type,
  value,
  onChange,
  noUpLabel,
  disabled,
  error,
  helperText,
  endAdornment,
  errorMessage,
}) {
  return (
    <React.Fragment>
      <TextField
        label={noUpLabel ? "" : label}
        placeholder={noUpLabel ? label : ""}
        value={value}
        type={type}
        onChange={onChange}
        disabled={disabled}
        variant="outlined"
        size="small"
        fullWidth
        InputProps={
          endAdornment && {
            endAdornment: (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }
        }
        helperText={errorMessage}
        error={!!errorMessage}
      />
    </React.Fragment>
  );
}

export default TextInput;
