import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, GlobalErrorMessage, TextInput } from "shared/components";
import WrapConnexionPage from "Funnel/Components/WrapConnexionPage";
import { apiForgotPassword, forgotPasswordClear, setPartener } from "store/reducers/auth";
import WrapForm from "./WrapForm";
import Joi from "joi";
import { errorMessage } from "../../shared/utilities";

const INITIAL_COUNTER = 15

const Forgotpwd = ({ match }) => {
  const dispatch = useDispatch();
  const ui = useSelector((store) => store.auth.ui);
  const password = useSelector((store) => store.auth.password);
  const [email, setEmail] = useState("");
  const [counter, setCounter] = useState(INITIAL_COUNTER)
  const [badField, setBadField] = useState(null);

  useEffect(() => {
    if (!password.reset_sent) return;

    setTimeout(() => {
      if (counter <= 1) {
        setCounter(INITIAL_COUNTER)
        dispatch(forgotPasswordClear())
      }
      setCounter(counter - 1)
    }, 1000);
  }, [dispatch, password.reset_sent, counter]);


  //partner_slug
  const partner_slug = match.params.partner_slug;
  if (partner_slug && partner_slug.length > 0) {
    dispatch(setPartener({ partner: partner_slug }));
  }

  async function validateForm() {
    const schema = Joi.object({
      email: Joi.string().required().email({ tlds: { allow: false } }),
    });
    const { error } = schema.validate({ email });
    return error ? { name: error.details[0].path[0], type: error.details[0].type } : null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = await validateForm();
    setBadField(error);

    if (!error) {
      dispatch(apiForgotPassword({ email }));
    }
  };

  return (
    <WrapConnexionPage>
      <WrapForm title="Mot de passe oublié">
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="formDsc">Entrez l'adresse mail avec laquelle vous vous connectez à Cashbee</div>

          <TextInput
            id="email"
            label="Email"
            type="email"
            value={email}
            disabled={password.reset_sent}
            errorMessage={errorMessage(
              "email",
              badField,
              ui.forgotInitError422,
              "Hmm, ça ne ressemble pas à un email valide"
            )}
            onChange={(e) => {
              setBadField(null)
              dispatch(forgotPasswordClear())
              setEmail(e.target.value)
            }}
          />

          {password.reset_sent && (
            <div className="alert alert-success alert-icon-ok">
              Un email de réinitialisation arrive
            </div>
          )}

          {ui.forgotInitApiError && (
            <GlobalErrorMessage>{ui.forgotInitApiError}</GlobalErrorMessage>
          )}

          <Button type="submit" primary={true} disabled={password.reset_sent || !!ui.forgotInitApiError}>
            Réinitialiser {password.reset_sent ? `(${counter})` : null}
          </Button>
        </form>
      </WrapForm>
    </WrapConnexionPage>
  );
};

export default Forgotpwd;
