import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "signed_procedures",
    initialState: { proceduresContracts: [] },
    reducers: { },
    extraReducers: {
        initDashboard: (state, { payload }) => {
            state.proceduresContracts = payload.signed_procedures;
        }
    }
});

export default slice.reducer;