import React from "react";
import { useSelector } from "react-redux";
import AccountCard from "./AccountCard";

const AccountsList = ({ accounts, rateGrids, providerSlug, maxAccountsNumber }) => {
  const maxNbr = maxAccountsNumber ?? 10; //If not given in config so 10
  const dashboard = useSelector((store) => store.dashboard);
  const activeAccountsNumber = dashboard.accounts.totalNumbers[providerSlug];
  const totalNumber = activeAccountsNumber + dashboard.procedure.totalNumber;

  return (
    <React.Fragment>
      {accounts.map((x, i) => (
        <AccountCard
          key={i}
          account={x}
          totalNumber={totalNumber}
          maxNbr={maxNbr}
          rateGrids={rateGrids}
        />
      ))}
    </React.Fragment>
  );
};

export default AccountsList;
