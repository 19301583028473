import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "editable",
  initialState: true,
  extraReducers: {
    initDashboard: (state, { payload }) => {
      return payload.editable
    },
  },
});

export default slice.reducer;
