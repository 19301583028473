import React from "react";
import NotAuthentifiedRouter from "./NotAuthentifiedRouter";
import EmailToVerifyRouter from "./EmailToVerifyRouter";
import AuthorizedRouters from "./AuthorizedRouters";

const ConditionalRouter = ({ authentified, emailVerified }) => {
  if (!authentified) return <NotAuthentifiedRouter />;
  if (!emailVerified) return <EmailToVerifyRouter />;
  //else
  return <AuthorizedRouters />;
};

export default ConditionalRouter;
