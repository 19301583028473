import { createSlice } from "@reduxjs/toolkit";
import { benificiariesMaxNumber } from "store/config";
import {
  apiCallFailed,
  beneficiaryAdded,
  beneficiaryRemoved,
  beneficiaryUpdated,
} from "store/reducers/dashboard/benificiaries";

const maxNumber = benificiariesMaxNumber ? benificiariesMaxNumber : 10; //if not defined in json

const emptyItem = {
  //id:null, //  <== NOT NULL AFTER POSTING
  civility: "",
  last_name: "",
  first_name: "",
  citizenship: "FR",
  birth_date: "",
  birth_place: "",
  birth_department: "",
  birth_country: "FR",
  share_percent: "",
  us_person: false,

  // head_office_address: "", //  <=== SUR FIGMA UNIQUEMENT
  // residence_address: "", // <=== SUR FIGMA UNIQUEMENT
  address: {
    // <== sur l'API uniquement
    city: "",
    street: "",
    second_line: "",
    postcode: "",
    territory: "",
    country: "",
  },
  tax_residences: [
    {
      country: "FR",
    },
  ],
};

const slice = createSlice({
  name: "benificiaries",
  initialState: {
    maxNumber: maxNumber, //TODO: l'extraire dans un fichier json
    //
    initValue: null, //initial value
    currValue: null, //current value of what is edited
    updatedIndex: null, //if updating existing item, this is its index in the list
    addingNewOne: false, //if we are adding a new one
    //
    needConfirm: null, //={message, todo} : message: message to show for confirmation / todo: function to execute if confirmation
    // {
    //   message: null,
    //   todo: action to execute when confirm
    // },

    error422: null,
    apiErrorMessage: null,

    // Needed for BeneficiaryEditPage (for adding new beneficiary)
    emptyItem: { ...emptyItem },

    fieldNames: {
      civility: { label: "", hiddenOnSettingBoard: "true" },
      first_name: { label: "Prénom" },
      last_name: { label: "Nom" },
      citizenship: { label: "Nationalité", type: "citizenship" },
      address: { label: "Adresse de Résidence", type: "address" },
      birth_date: { label: "Date de naissance", type: "birthday" },
      birth_place: { label: "Ville de naissance" },
      birth_country: { label: "Pays de naissance", type: "country" },
      birth_department: { label: "Département de naissance", type: "departmentSelect" },
      share_percent: {
        label: "Pourcentage de détention",
        type: "percentage",
      },
      voting_percent: {
        label: "Pourcentage de droits de vote",
        type: "percentage",
      },
      us_person: { label: "Personne américaine (FATCA)", type: "boolean" },
    },
  },
  reducers: {
    startAdding: (state, action) => {
      state.currValue = { ...emptyItem };
      state.initValue = state.currValue;
      state.updatedIndex = null;
      state.addingNewOne = true;
      state.error422 = null;
      state.apiErrorMessage = null;
    },
    stateChanging: (state, action) => {
      state.currValue = action.payload.item;
    },
    startEditing: (state, action) => {
      state.currValue = { ...emptyItem, ...action.payload.item }; //{ ...emptyItem} Suffira lorsque le serveur aura le meme format !!
      state.initValue = state.currValue;
      state.updatedIndex = action.payload.index;
      state.addingNewOne = false;
    },
    //action:{force:boolean, currState:object}
    stopEditing: (state, action) => {
      state.initValue = null;
      state.currValue = null;
      state.updatedIndex = null;
      state.addingNewOne = false;
      state.error422 = null;
      state.apiErrorMessage = null;
    },
    askForConfirmation: (state, action) => {
      const { title, message, todo } = action.payload;
      state.needConfirm = {
        title,
        message,
        todo,
      };
    },
    denyConfirmation: (state, action) => {
      if (state.needConfirm) {
        state.needConfirm = null;
      }
    },
    acceptConfirmation: (state, action) => {
      if (state.needConfirm) {
        // state.needConfirm.todo(); //execute action
        state.needConfirm = null; //ferme popup
      }
    },
    apiCallFailedOnUi: (state, { payload }) => {
      state.error422 = payload.error422;
      state.apiErrorMessage = payload.message;
    },
  },
  extraReducers: {
    [beneficiaryAdded.type]: (state, action) => {
      slice.caseReducers.stopEditing(state, action);
    },
    [beneficiaryRemoved.type]: (state, action) => {
      slice.caseReducers.stopEditing(state, action);
    },
    [beneficiaryUpdated.type]: (state, action) => {
      slice.caseReducers.stopEditing(state, action);
    },
    [apiCallFailed.type]: (state, action) => {
      slice.caseReducers.apiCallFailedOnUi(state, action);
    },
  },
});
export const {
  startAdding,
  startEditing,
  stopEditing,
  stateChanging,
  askForConfirmation,
  acceptConfirmation,
  denyConfirmation,
} = slice.actions;
export default slice.reducer;
